import { apiUrl } from './apiconfig';
import axios from "axios";

export const getAllPosition = async () => {
    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: apiUrl + '/position/getall',
        headers: {
            'Authorization': 'Bearer ' + token()
        }
    };
    try {
        const response = await axios.request(config);
        if (response.data.status) {
            //console.log(response)
            return response.data.data;
        } else {
            return [];
        }
    } catch (error) {
        console.error(error);
        return [];
    }
}
function token() {
    return localStorage.getItem("token")
};