import React from 'react';
import { FaCircle } from 'react-icons/fa';
import char from '../../assets/pie-chart-683.svg'
import { Pie } from 'react-chartjs-2';
function DashboardScore(props) {
  var myPv = 0;
  var totalPv = 0;
  var childrenPv = 0;
  var bonusPv = 0;
  if (!props.loading) {
    if (props.data) {
      totalPv = props.data.totalPv;
      myPv = props.data.myPv;
      childrenPv =props.data.childrenPv;
      bonusPv = props.data.bonusPv;
    }
  }

  return (
    <div className="w-full lg:w-[50%]">
      <div className="w-full flex justify-center my-4 text-xl font-bold">
        <span>ບອດຄະແນນສະມາຊິກ</span>
      </div>
      <div className='grid grid-cols-2'>
        <div className='flex justify-center items-center'>
          <Pie data={{
            labels: ['ຄະແນນຂອງທ່ານ', 'ຄະແນນລວມສະມາຊິກ', 'ຄະແນນໂບນັດ'],
            datasets: [
              {
                label: 'Dataset 1',
                data: [myPv, childrenPv, bonusPv],
                backgroundColor: ['orange', 'Green', 'Purple'],
              }
            ]
          }} />
        </div>
        <div className="w-full flex justify-center items-center flex-col gap-2">
          <ScoreItem title="ຄະແນນລວມທັງໝົດ" value={totalPv} color="" />
          <ScoreItem title="ຄະແນນຂອງທ່ານ" value={myPv} color="text-orange-300" />
          <ScoreItem title="ຄະແນນລວມສະມາຊິກ" value={childrenPv} color="text-green-600" />
          <ScoreItem title="ຄະແນນໂບນັດ" value={bonusPv} color="text-purple-500" />
        </div>
      </div>
    </div>
  );
}

function ScoreItem({ title, value, color }) {
  return (
    <div className="w-[85%]">
      <div className="flex gap-4 items-center w-full justify-between">
        <div className={`flex items-center gap-3 ${color && color}`}>
          <FaCircle />
          <h1 className="lg:font-medium">{title}</h1>
        </div>
        <h1 className={`lg:text-2xl font-bold ${color && color}`}>{value}</h1>
      </div>
    </div>
  );
}

export default DashboardScore;
