import React, { useState, useEffect } from "react";
import { IoIosArrowBack } from "react-icons/io";
//import rectangle from "../../assets/Rectangle 240 (1).png";
import { FaRegTrashAlt } from "react-icons/fa";
import { NavLink, useNavigate } from "react-router-dom";
import { getCart, updateQTY, deleteItem } from "../../api/product";
import Swal from "sweetalert2";
import verify from '../../assets/verify.png';
import { PulseLoader } from "react-spinners";

const AnimationLoading = () => {
  return (
    <PulseLoader
      color="#36d7b7"
      size={10}
      aria-label="Loading Spinner"
      data-testid="loader" />
  )
}
function ProductOrder() {
  const navigate = useNavigate();
  const [cart, setCart] = useState([]);
  const [loading, setLoading] = useState(false);
  const [changQty, setChangQty] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const [SumPrice, setSumPrice] = useState(0);
  const [SumPoint, setSumPoint] = useState(0);
  const [isVerify, setIsVerify] = useState(false);

  useEffect(() => {
    fetchData();
    const handleScroll = () => {
      if (window.pageYOffset > 50) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };

  }, []);
  useEffect(() => {
    var price = 0;
    var point = 0;
    for (const i of cart) {
      price += i.qty * i.product_id.price;
      point += i.qty * i.product_id.point;
    }
    setSumPrice(price)
    setSumPoint(point);
  }, [cart])
  useEffect(() => {
    fetchData();
  }, [changQty])

  async function fetchData() {
    const check = JSON.parse(localStorage.getItem("user")).isVerify
    if (check) {
      setIsVerify(true);
    }
    setLoading(true);
    const data = await getCart();
    if (data) {
      //ໄລ່ລາຄາເຄື່ອງໃນກະຕ່າ
      var price = 0;
      var point = 0;
      for (const i of data) {
        price += i.qty * i.product_id.price;
        point += i.qty * i.product_id.point;
      }
      setCart(data);
      setSumPrice(price)
      setSumPoint(point);
    }

    setLoading(false);
    setChangQty(false);
  }

  async function fetchAgian() {
    const data = await getCart();
    //ໄລ່ລາຄາເຄື່ອງໃນກະຕ່າ
    var price = 0;
    for (const i of data) {
      price += i.qty * i.product_id.price;
    }
    setCart(data);
    setSumPrice(price);

  }
  const Alert = () => {
    return (
      Swal.fire({
        html: '<div class="flex flex-col items-center">' +
          '<img src="' + verify + '" alt="verify" class="w-16 h-16 mb-4" />' +
          '<h2 class="text-lg font-semibold mb-2">ທ່ານຍັງບໍ່ທັນຢືນຢັນຕົວຕົນ</h2>' +
          '<p>ທ່ານຕ້ອງຢືນຢັນຕົວຕົນກ່ອນ ຈຶ່ງຈະສາມາດນຳໃຊ້ຟັງຊັ້ນຕ່າງໆຂອງພວກເຮົາໄດ້</p>' +
          '</div>',
        showCancelButton: true,
        confirmButtonColor: "#00AEEF",
        confirmButtonText: "ຢືນຢັນ",
        cancelButtonText: "ພາຍຫຼັງ",
        cancelButtonColor: "#ffffff",
        cancelButtonText: '<span style="color: blue;">ພາຍຫຼັງ</span>',
        reverseButtons: true,
        width:"340px"
      }).then((result) => {
        if (result.isConfirmed) {
          navigate("/verifyswall")
        } else {
        }
      })
    )
  }
  const handlePay = () => {
    const verify = JSON.parse(localStorage.getItem("user")).isVerify
    if (verify) {
      navigate("/paychoice")
    } else {
      Alert();
    }

  }

  const ListProduct = (item) => {
    const [qty, setQTY] = useState(item.qty);
    const [totalPoint, setTotalPoint] = useState(0);
    const [totalPrice, setTotalPrice] = useState(0);

    useEffect(() => {
      setTotalPoint(qty * item.product_id.point);
      setTotalPrice(qty * item.product_id.price);
    }, [qty])

    const handleIncrease = () => {
      setQTY(Math.max(1, qty + 1));
      updateQTY(item._id, qty + 1);
      setChangQty(true);
    }
    const handleDecrease = () => {
      setQTY(Math.max(1, qty - 1))
      updateQTY(item._id, qty - 1);
      setChangQty(true);

    }
    const handleDelete = async () => {
      Swal.fire({
        title: "ລົບສິນຄ້າ",
        text: `ລົບ ${item.product_id.name} ອອກຈາກກະຕ່າ`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ລົບ",
        cancelButtonText: "ປິດ",
        width:"340px"
      }).then(async (result) => {
        if (result.isConfirmed) {
          setLoading(true);
          const x = await deleteItem(item._id);
          setCart((prevState) => prevState.filter((dropitem) => dropitem._id !== item._id));
          //ໄລ່ລາຄາເຄື່ອງໃນກະຕ່າ
          setLoading(false);
        }
      });
    }
    return (
      <tr className="border border-slate-300">
        <td className="text-center ">
          <span className="">#{item.product_id._id.slice(-8)}</span>
        </td>
        <td className="flex justify-center items-center py-2">
          <img
            src={process.env.REACT_APP_IMGURL+item.product_id.images[0]}
            alt=""
            className=" rounded-lg h-16 w-16 "
          />
        </td>
        <td className=" text-center">
          <h1>{item.product_id.name}</h1>
        </td>
        <td className=" text-center">
          <h1>{item.product_id.price.toLocaleString()}</h1>
        </td>
        <td className="text-center">
          {item.product_id.point}
        </td>
        <td className="text-center">
          <div className="flex justify-center items-center gap-3">
            <button
              className="w-6 rounded  bg-black text-white"
              onClick={handleDecrease}
            >
              -
            </button>{" "}
            <h1> {qty}</h1>{" "}
            <button
              className="w-6 bg-black text-white rounded"
              onClick={handleIncrease}
            >
              +
            </button>
          </div>
        </td>
        <td className=" text-center">
          {totalPrice.toLocaleString()}
        </td>
        <td className="text-center">
          {totalPoint}
        </td>
        <td className=" text-center">
          <button onClick={handleDelete}><FaRegTrashAlt color="red" /></button>
        </td>
      </tr>
    )

  }

  return (
    <div className=" w-full ">
      <div className="w-full mt-20 py-5 shadow-lg border p-2 bg-white rounded-xl lg:mt-16 md:py-8 lg:py-10 lg:px-10">
        <div>
          <div>
            <button onClick={() => { navigate(-1) }} className="flex items-center">
              <IoIosArrowBack className=" h-12 w-12" />
              <h1 className=" text-lg">ກັບໄປໜ້າກ່ອນ</h1>
            </button>
            <div className="w-full text-center font-medium text-xl md:text-2xl lg:text-4xl text-blue-dark ">
              <h1>ສີນຄ້າໃນກະຕ່າ</h1>
            </div>
          </div>
          <div className='w-full mt-3 max-h-[500px] overflow-auto rounded-lg'>
            <table className="w-[800px] lg:w-full table-auto ">
              <thead className="w-full rounded-t-xl bg-gradient-to-t from-blue-dark to-blue-light text-white sticky top-0">
                <tr className="">
                  <th className="p-3">
                    ລະຫັດສີນຄ້າ
                  </th>

                  <th className="">
                    ຮູບສິນຄ້າ
                  </th>
                  <th className=" ">
                    ຊື່ສິນຄ້າ
                  </th>
                  <th className="">
                    ລາຄາສິນຄ້າ
                  </th>
                  <th className="">
                    Point
                  </th>
                  <th className="">
                    ຈຳນວນ
                  </th>
                  <th className="">
                    ລາຄາລວມ
                  </th>
                  <th className="">
                    ລວມPoint
                  </th>
                  <th className="">
                    ລົບອອກ
                  </th>
                </tr>
              </thead>
              <tbody>
                {loading ?
                  <AnimationLoading /> :
                  cart.map((item) => (
                    <ListProduct {...item} />
                  ))
                }
              </tbody>
            </table>
          </div>
          <div className=" w-full  p-2 mt-4 border  rounded-b-xl bg-white">
            <div className="w-full flex justify-end">
              <table className=" table-auto">
                <tr>
                  <th>
                    Point ທີ່ທ່ານຈະໄດ້ຮັບ
                  </th>
                  <td className="ps-4 text-xl text-green-500">{SumPoint} point</td>
                </tr>
                <tr>
                  <th>ເງິນທີ່ທ່ານຕ້ອງຊຳລະ</th>
                  <td className="ps-4 text-2xl font-medium">{SumPrice.toLocaleString() + " ກີບ"}</td>
                </tr>
              </table>
            </div>
          </div>

          {cart && cart.length > 0 ?
            <div className=" w-full flex flex-col ">
              <button onClick={handlePay} className=" mt-6 my-10 bg-blue-dark w-60 h-16 flex self-end justify-center items-center rounded-lg text-xl font-bold text-white  "> ຢືນຢັນ ແລະ ຊຳລະ</button>
            </div>
            :
            <div className=" w-full flex flex-col ">
            <button disabled className=" mt-6 my-10 bg-blue-dark w-60 h-16 flex self-end justify-center items-center rounded-lg text-xl font-bold text-white  "> ຢືນຢັນ ແລະ ຊຳລະ</button>
          </div>
          }
        </div>
      </div>
    </div>
  );
}



export default ProductOrder;
