import { apiUrl } from './apiconfig';
import axios from "axios";
import qs from "qs"

export const changeWalletPass = async (oldPassword, newPassword) => {
    let data = qs.stringify({
        'oldPassword': oldPassword,
        'newPassword': newPassword,
    });
    let config = {
        method: 'put',
        maxBodyLength: Infinity,
        url: apiUrl + '/wallet/edit/password',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': 'Bearer ' + token()
        },
        data: data
    };
    try {
        const response = await axios.request(config);
        //console.log(response)
        if (response.data.status) {
            return response.data
        } else {
            return false;
        }
    } catch (error) {
        return {status:false,message:error.response.data.message};
    }
}

export const getMyWallet = async () => {
    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: apiUrl + '/wallet/me',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token()
        }
    };
    try {
        const response = await axios.request(config);
        if (response.data.status) {
            //console.log(response.data.data)
            return response.data.data
        } else {
            return false;
        }
    } catch (error) {
        console.error(error);
        return false;
    }
}

export const getTransaction = async () => {
    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: apiUrl + '/transaction/me',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token()
        }
    };
    try {
        const response = await axios.request(config);
        if (response.data.status) {
            //console.log(response.data.data)
            return response.data.data
        } else {
            return false;
        }
    } catch (error) {
        console.error(error);
        return false;
    }

}
export const getWithdraw = async (amount, password) => {
    let data = qs.stringify({
        'amount': amount,
        'description': 'withdraw ' + amount,
        'walletPassword': password
    });
    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: apiUrl + '/wallet/withdraw',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': 'Bearer ' + token()
        },
        data: data
    };

    try {
        const response = await axios.request(config);
        if (response.data.status) {
            //console.log(response.data.data)
            return response.data;
        } else {
            return response.data;
        }
    } catch (error) {
        console.error(error);
        return error.response.data;
    }
}
export const getDeposit = async (amount, slip) => {
    var data = new FormData();
    data.append('amount', amount)
    data.append('description', "deposit " + amount)
    data.append('slip', slip);

    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: apiUrl + '/wallet/deposit',
        headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + token()
        },
        data: data
    };
    try {
        const response = await axios.request(config);
        //console.log(response)
        if (response.data.status) {
            //console.log(response.data.data)
            return response.data
        } else {
            return response.data;
        }
    } catch (error) {
        console.error(error);
        return error;
    }
}
export const getCCLbonus = async () => {
    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: apiUrl + '/wallet/getcalculatebonus',
        maxBodyLength: Infinity,
        headers: {
            'Authorization': 'Bearer ' + token()
        }
    };
    try {
        const response = await axios.request(config);
        if (response.data.status) {
            console.log(response.data.data)
            return response.data.data
        } else {
            return false;
        }
    } catch (error) {
        console.error(error);
        return false;
    }
}
function token() {
    return localStorage.getItem("token")
};