import React, { useEffect, useState } from "react";
import DashboardAreachart1 from "../components/dashboardAreachart/DashboardAreachart1.jsx";
import DashboardAreachart2 from "../components/dashboardAreachart/DashboardAreachart2.jsx";
import DashbordAreachart3 from "../components/dashboardAreachart/DashbordAreachart3.jsx";
import DashboardQR from "../components/dashboardAreachart/DashboardQR.jsx";
import DashboardCardcomponent from "../components/dashboardAreachart/DashboardCardcomponent.jsx";
import DashboardInorder from "../components/dashboardAreachart/DashboardInorder.jsx";
import DashboardPosition from "../components/dashboardAreachart/DashboardPosition.jsx";
import DashboardScore from "../components/dashboardAreachart/DashboardScore.jsx";
import { getMyHistorySale } from "../api/order.js";
import { getMyProfile } from "../api/auth.js";
import { getMyWallet } from "../api/ewallet.js";
import { getMyPV } from "../api/linework.js";
import { PulseLoader } from "react-spinners";
import SaleHistory from "./order/SaleHistory.jsx";
import DashboardSaleHistory from '../components/dashboardAreachart/DashboardSaleHistory.jsx'
import Swal from "sweetalert2";
const AnimationLoading = () => {
  return (
    <PulseLoader
      color="#36d7b7"
      size={10}
      aria-label="Loading Spinner"
      data-testid="loader" />
  )
}
function Dashboard() {
  const [MyProfile,setMyProfile] = useState([]);
  const [commission, setCommission] = useState('');
  const [amount, setAmount] = useState('');
  const [totalSale, setTotalSales] = useState('');
  const [myPV, setMyPV] = useState([]);
  const [loading, setLaoding] = useState(false);
  useEffect(() => {
    fetchdata();
  }, [])

  const fetchdata = async () => {
    setLaoding(true);
    const wallet = await getMyWallet();
    const sale = await getMyHistorySale();
    const pv = await getMyPV();
    if (wallet) {
      setAmount(wallet.balance.toLocaleString());
      setCommission(wallet.totalPV.toLocaleString());
    }
    var prices = 0;
    for (const i of sale) {
      prices += i.totalPrice
    }
    setTotalSales(prices.toLocaleString());
    setMyPV(pv);
    setLaoding(false);
  }
  return (
    <div className="w-full my-4">
      <div className="w-full">
        <DashboardCardcomponent />
      </div>
      {/* <div className="mt-5 lg:px-5 py-5 w-full shadow-lg border p-2 bg-white rounded-xl flex justify-center md:py-8 lg:py-10">
        <div className="w-full grid grid-cols-2 lg:grid-cols-3 gap-3 ">
          <div className="border rounded-lg">
            <DashboardAreachart1 data={commission} loading={loading} />
          </div>
          <div className="border rounded-lg">
            <DashboardAreachart2 data={totalSale} loading={loading} />
          </div>
          <div className="border rounded-lg">
            <DashbordAreachart3 data={amount} loading={loading} />
          </div>
        </div>
      </div> */}
      <DashboardSaleHistory />
      {/* ເລືອນຂັ້ນ ========================================================================= */}
      {/* <div className="mt-5 py-5 w-full shadow-lg border p-2 bg-white rounded-xl flex justify-center md:py-8 lg:py-10">
        <div className="grid grid-cols-1 md:grid-cols-1 lg:flex justify-between gap-3">
          <div className="w-[70%] m-auto p-3 border flex justify-center items-center ">
            <DashboardInorder data={myPV} loading={loading} />
          </div>
          <div className="p-3 lg:border flex justify-center items-center ">
            <DashboardPosition />
          </div>
        </div>
      </div> */}
      {/* ======================================================== chart area ================================== */}
      {/* 
      <div className="mt-5 py-5 w-full  shadow-lg border p-2 bg-white rounded-xl flex justify-center md:py-8 lg:py-10">
        <DashboardScore data={myPV} loading={loading} />
      </div> */}
    </div>
  );
}

export default Dashboard;