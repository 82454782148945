import React, { useState, useEffect } from 'react';
import { NavLink, useLocation,useNavigate } from 'react-router-dom';
import { AiOutlineClose } from 'react-icons/ai';
import { HiHome } from 'react-icons/hi';
import hong from '../assets/hong.svg';
import { BiSolidCart } from 'react-icons/bi';
import { IoWalletSharp } from 'react-icons/io5';
import HongfahSidebarFeather from './HongfahSidebarFeather';
import bonus from '../assets/bonus.png';
import { SiMoneygram } from "react-icons/si";
import setting from '../assets/setting.png';
import { BsDiagram3Fill } from "react-icons/bs";
import { BsFillHandbagFill } from "react-icons/bs";
import { IoStatsChartSharp } from "react-icons/io5";
import { BiSolidPlaneAlt } from "react-icons/bi";
import { FaRankingStar } from "react-icons/fa6";
import { FcPositiveDynamic } from "react-icons/fc";
import Swal from 'sweetalert2';
import verify from '../assets/verify.png';
const navlinks1 = [
  { title: 'Dashboard', icon: <HiHome size={26} />, path: '/', check: false },
  { title: 'ສີນຄ້າ', icon: <BsFillHandbagFill size={26} />, path: '/product', check: false },
  { title: 'ອໍເດິສັ່ງຊື້', icon: <BiSolidCart size={26} />, path: '/order', check: false },
  { title: 'ແຜນພັງສາຍງານ', icon: <BsDiagram3Fill size={26} />, path: '/member', check: true },
  { title: 'ປະຫວັດໂບນັດ', icon: <SiMoneygram size={26} />, path: '/bonus', check: false },
  { title: 'ກະເປົາ E-wallet', icon: <IoWalletSharp size={26} />, path: '/ewalletwithdraw ', check: true },
  { title: 'ທິບທ່ອງທຽ່ວ', icon: <BiSolidPlaneAlt size={26} />, path: '/travel', check: false },
];

const Sidebar = ({ click, setClick }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeLink, setActiveLink] = useState(location.pathname);
  const [user, setUser] = useState([]);

  useEffect(() => {
    try {
      const getuser = JSON.parse(localStorage.getItem("user"));
      setUser(getuser);
    } catch (err) {
      throw err
    }
  }, [])
  const handleLinkClick = (path) => {
    setActiveLink(path);
    setClick(false);
  };
  const Alert = () => {
    Swal.fire({
      html: '<div class="flex flex-col items-center">' +
        '<img src="' + verify + '" alt="verify" class="w-16 h-16 mb-4" />' +
        '<h2 class="text-lg font-semibold mb-2">ທ່ານຍັງບໍ່ທັນຢືນຢັນຕົວຕົນ</h2>' +
        '<p>ທ່ານຕ້ອງຢືນຢັນຕົວຕົນກ່ອນ ຈຶ່ງຈະສາມາດນຳໃຊ້ຟັງຊັ້ນຕ່າງໆຂອງພວກເຮົາໄດ້</p>' +
        '</div>',
      showCancelButton: true,
      confirmButtonColor: "#00AEEF",
      confirmButtonText: "ຢືນຢັນ",
      cancelButtonText: "ພາຍຫຼັງ",
      cancelButtonColor: "#ffffff",
      cancelButtonText: '<span style="color: blue;">ພາຍຫຼັງ</span>',
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        navigate("/verifyswall");
      } else {

      }
    });
  }
  return (
    <div
      onClick={() => setClick(false)}
      className={`bg-gradient-to-b from-blue-dark to-blue-light z-[100] duration-500 min-h-screen shadow p-2 drop-shadow-md `}
    >
      <div className="absolute mt-[15rem]">
        <HongfahSidebarFeather />
      </div>
      <div className="logo w-full flex align-middle relative">
        <span
          onClick={() => setClick(false)}
          className="text-base p-2 rounded-full bg-main-color absolute top-2 -right-[1.5rem] z-40 cursor-pointer md:hidden block"
        >
          <AiOutlineClose className="text-white" size={20} />
        </span>
      </div>
      <nav className='absolute w-[93%]'>

        <ul className="flex gap-2 flex-col relative">
          <li className="w-full  flex justify-center my-4">
            <img src={hong} alt="" className="w-[100px] cursor-pointer" />
          </li>
          {navlinks1.map((link, index) => (
            <li key={index}>
              {link.check ?
                <button
                  onClick={() => {
                    handleLinkClick(link.path);
                    if (user.isVerify) {
                      navigate(link.path);
                    } else {
                      Alert();
                    }
                  }}
                  className={activeLink === link.path ? 'w-full  flex gap-2 items-center p-2 rounded-full bg-white text-blue-dark' : 'w-full flex text-white gap-3 items-center p-2 bg-transparent rounded-full hover:bg-white hover:text-blue-dark'}
                >
                  <span className="icon-container lg:ms-5">{link.icon}</span>
                  <span className=' text-md'>{link.title}</span>
                </button>
                :
                <NavLink
                  to={link.path}
                  onClick={() => handleLinkClick(link.path)}
                  className={activeLink === link.path ? '  flex gap-2 items-center p-2 rounded-full bg-white text-blue-dark' : 'flex text-white gap-3 items-center p-2 bg-transparent rounded-full hover:bg-white hover:text-blue-dark'}
                >
                  <span className="icon-container lg:ms-5">{link.icon}</span>
                  <span className=' text-md'>{link.title}</span>
                </NavLink>
              }
            </li>
          ))}
        </ul>
      </nav>
    </div>
  );
};

export default Sidebar;
