import { BiChevronLeft, BiChevronRight } from "react-icons/bi";
import ReactPaginate from "react-paginate";
import Rectangle from "../../assets/Rectangle 240 (1).png";
import { IoSearch } from "react-icons/io5";
import { PulseLoader } from "react-spinners";
import moment from "moment";
import { useState, useEffect } from "react";
import { getMyHistorySale } from "../../api/order";
const AnimationLoading = () => {
  return (
    <PulseLoader
      color="#36d7b7"
      size={10}
      aria-label="Loading Spinner"
      data-testid="loader" />
  )
}
function dateFormat(dateString) {
  const dateObject = moment(dateString);
  return dateObject.format("DD/MM/YYYY");
}

const ShowProduct = (props) => {
  return (
    <>
      <td className=" w-[10rem]  flex items-center justify-center ">
        {" "}
        <img
          src={props.image}
          alt=""
          className="rounded-lg "
          width={70}
        />
      </td>
      <td className="w-[10rem] flex items-center justify-center ">
        {dateFormat(props.createAt)}
      </td>
      <td className=" w-[18rem] flex items-center justify-center ">
        <p>{props.name}</p>
      </td>
      <td className=" w-[10rem] flex items-center justify-center text-xl font-bold">
        {props.qty}
      </td>
      <td className=" w-[18rem] flex items-center justify-center ">
        {props.price.toLocaleString() + " ກີບ"}
      </td>
      <td className=" w-[18rem] flex items-center justify-center ">
        {props.point}
      </td>
    </>
  )
}
function SaleHistory() {
  const [orders, setOrders] = useState([]);
  const [products, setProduct] = useState([])
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchData();
  }, [])
  const fetchData = async () => {
    setLoading(true)
    const data = await getMyHistorySale();
    if (data || data.length > 0) {
      setOrders(data);
    }
    setLoading(false)
  }
  const handlePageClick = (selectedPage) => {
    console.log(`Selected page: ${selectedPage}`);
  };

  return (
    <div className='w-full'>
      <div className="mt-10 py-5 w-full shadow-lg border bg-white rounded-xl lg:py-10 ">
        <div className="flex justify-between items-center">
          <h1 className="px-5 text-xl md:text-2xl lg:text-2xl font-bold ">ປະຫັດການຊື້-ຂາຍ</h1>
        </div>
        {!loading && orders.length < 1 && <div className='text-center text-red-500'>ບໍ່ມີລາຍການຂາຍ</div>}
        <div className="w-full mt-3 h-[500px] overflow-auto px-5">
          <table class="w-[800px] lg:w-full table-auto">
            <thead className="w-full rounded-t-xl bg-gradient-to-t from-blue-dark to-blue-light text-white sticky top-0 overflow-hidden">
              <tr >
                <th className="p-3" >
                  ສີນຄ້າ
                </th>
                <th>
                  ວັນທີ່ສັ່ງຊື້
                </th>
                <th >
                  ຈຳນວນ
                </th>
                <th>
                  ລາຄາ
                </th>
                <th >
                  ຄະແນນ Point
                </th>
              </tr>
            </thead>
            <tbody>

              {loading ?
                <AnimationLoading />
                :
                orders.length > 0 ?
                  orders.map((order) =>
                  (
                    order.products.map((product) => {
                      return (
                        <tr className="border border-slate-200">
                          <td className="p-2">
                            <div className="flex justify-center h-[70px] overflow-hidden rounded-lg">
                              <img alt="" className="overflow-hidden rounded-lg" src={process.env.REACT_APP_IMGURL+product.image} width={70} />
                            </div>
                            <p className="text-center">{product.name}</p>
                          </td>
                          <td className="text-center">
                            {dateFormat(product.createAt)}
                          </td>
                          <td className="text-center">
                            {product.qty} {product.product_id.unit}
                          </td>
                          <td className="text-center">
                            {product.price.toLocaleString() + " ກີບ"}
                          </td>
                          <td className="text-center">
                            {product.point} ຄະແນນ
                          </td>
                        </tr>
                        // <tr className="text-center border border-slate-300">
                        //   <td>{product._id}</td>
                        //   <td className="flex justify-center p-3 ">
                        //     
                        //   </td>
                        //   <td className="">{dateFormat(product.createAt)}</td>
                        //   <td className="">{product.name}</td>
                        //   <td className="">{product.qty}</td>
                        //   <td className="">{product.price.toLocaleString() + " ກີບ"}</td>
                        //   <td className="">{product.point}</td>
                        // </tr>
                      )
                    })))
                  : ''
              }
            </tbody>
          </table>
        </div>
      </div>
    </div>

  );
}

export default SaleHistory;
