import React from "react";
import { BiDownArrowAlt } from "react-icons/bi";
import { FaKipSign } from "react-icons/fa6";
import { FiSearch } from "react-icons/fi";
import ReactPaginate from "react-paginate";
import { BiSolidCart } from "react-icons/bi";
import slidersetting from "../../assets/slidersetting.svg";
import ProductCont from './ProductCont';
import { IoFileTraySharp } from "react-icons/io5";
import emptyIcon from "../../assets/icon/empty.svg"
import { FaCartPlus } from "react-icons/fa";
import { useEffect, useState } from "react";
import { getAllCategory, getAllProduct, addToCart, getCart } from "../../api/product";
import { Link, NavLink } from "react-router-dom";
import { PulseLoader } from "react-spinners";
import Swal from "sweetalert2";
import AlertVerify from "../../components/AlertVerify";

const AnimationLoading = () => {
  return (
    <PulseLoader
      color="#36d7b7"
      size={10}
      aria-label="Loading Spinner"
      data-testid="loader" />
  )
}


function Product() {
  const [cart, setCart] = useState([]);
  const [numCart, setNumCart] = useState(0);
  const [listProduct, setListProduct] = useState([]);// ລາຍການສິນຄ້າທັງໝົດ
  const [tempProduct, setTempProduct] = useState([]);
  const [listCategory, setListCategory] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [isVerify, setIsVerify] = useState(false);
  useEffect(() => { //ໂຫຼດຂໍ້ມູນ ສິນຄ້າ
    fetchdata();
  }, [])

  const fetchdata = async () => {
    // const check = JSON.parse(localStorage.getItem("user")).isVerify
    // console.log(check)
    // if (check) {
    //   setIsVerify(true);
    // }
    setIsLoading(true);
    const pd_data = await getAllProduct();
    const temp = await getAllProduct()
    const cgr_data = await getAllCategory();
    const cart = await getCart();
    setListProduct(pd_data);
    setTempProduct(temp);
    setListCategory(cgr_data);

    if (cart) {
      setCart(cart);
      var num = 0
      for (const i of cart) {
        num += i.qty
      }
      setNumCart(num);
    } else {
      setNumCart(0)
    }
    setIsLoading(false);
  }

  // ປ່ຽນໜ້າ
  const perPage = 24;
  var pageCount = Math.ceil(listProduct.length / perPage);
  const indexStart = (currentPage - 1) * perPage;
  const indexEnd = indexStart + perPage
  try {
    var currentProduct = listProduct.slice(indexStart, indexEnd);
  } catch (err) {
    var currentProduct = [];
  }


  const handlePageClick = (selectedPage) => {
    setCurrentPage(selectedPage);
  };
  // _____________________________________________________

  //ຄົ້ນຫາສິນຄ້າ category , price
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedPriceRange, setSelectedPriceRange] = useState("");
  const [keyWord, setKeyWord] = useState("")


  const handleCategory = async (e) => {
    setSelectedCategory(e.target.value);
    const result = await findProduct(e.target.value, selectedPriceRange, keyWord);
    setListProduct(result);
  }

  const handlePriceRange = async (e) => {
    setSelectedPriceRange(e.target.value);
    const result = await findProduct(selectedCategory, e.target.value, keyWord);
    setListProduct(result);
  }
  const handleSearch = async () => {
    if (keyWord === '') {
      setListProduct(tempProduct)
    } else {
      const result = await findProduct(selectedCategory, selectedPriceRange, keyWord);
      setListProduct(result);
    }

  }
  const handleAddToCart = async (productID) => {
    setNumCart(numCart + 1)
    const x = await addToCart(productID, 1);
    if (x) {
      Swal.fire({
        icon: "success",
        title: "ເພີ່ມເຂົ້າກະຕ່າສຳເລັດແລ້ວ",
        showConfirmButton: false,
        timer: 1000,
        width:"340px"
      });
    }
  }
  const findProduct = async (cgr, price, keyword) => {
    // console.log("______________________")
    // console.log("cgr " + cgr)
    // console.log("price " + price)
    // console.log("keyword " + keyWord)

    var p1, p2;
    if (price !== "0") {
      if (price === "1") { p1 = 1000000; p2 = 9999999999 }
      else if (price === "2") { p1 = 500000; p2 = 1000000 }
      else if (price === "3") { p1 = 100000; p2 = 500000 }
      else if (price === "4") { p1 = 0; p2 = 100000 }
      else { }
    }

    var result = []
    //find category only
    if ((cgr && cgr !== 'all') && (!price || price === "0") && (!keyword || keyword === "")) {
      console.log("cgr only")
      for (const i of tempProduct) {
        if (i.productType._id === cgr) {
          result.push(i);
        }
      }
      return result
    }
    //find price only
    else if ((!cgr || cgr === 'all') && (price && price !== "0") && (!keyword || keyword === "")) {
      console.log("price only")
      for (const i of tempProduct) {
        if (i.price >= p1 && i.price <= p2) {
          result.push(i);
        }
      }
      return result
    }
    //find keyword only
    else if ((!cgr || cgr === 'all') && (!price || price === "0") && (keyword && keyword !== "")) {
      console.log("keyword only")
      for (const i of tempProduct) {
        if (i.name.startsWith(keyword) || i.name.endsWith(keyword)) {
          result.push(i);
        }
      }
      return result
    }
    //find more one
    else {
      //find cgr+price+keyword
      if (cgr && cgr !== 'all' && price && price !== "0" && keyword && keyword !== "") {
        console.log("find 3");
        for (const i of tempProduct) {
          if ((i.productType._id === cgr) && (i.price >= p1 && i.price <= p2) && ((i.name.startsWith(keyword) || i.name.endsWith(keyword)))) {
            result.push(i);
          }
        }
        return result
      }
      //find cgr+price
      else if (cgr !== 'all' && price !== "0" && keyword === "") {
        console.log("find cgr+price");
        for (const i of tempProduct) {
          if ((i.productType._id === cgr) && (i.price >= p1 && i.price <= p2)) {
            result.push(i);
          }
        }
        return result
      }
      //find cgr+keyword
      else if (cgr !== 'all' && price === "0" && keyword !== "") {
        console.log("find cgr+keyword");
        for (const i of tempProduct) {
          if ((i.productType._id === cgr) && ((i.name.startsWith(keyword) || i.name.endsWith(keyword)))) {
            result.push(i);
          }
        }
        return result
      }
      //find price+keyword
      else if ((!cgr || cgr === "all") && price !== "0" && keyword !== "") {
        console.log("find price+keyword");
        for (const i of tempProduct) {
          if ((i.price >= p1 && i.price <= p2) && ((i.name.startsWith(keyword) || i.name.endsWith(keyword)))) {
            result.push(i);
          }
        }
        return result
      }
      else {
        return tempProduct
      }
    }
  }
  //console.log(tempProduct)
  // _____________________________________________________
  return (
    <div className="w-full">
      <div className=" mt-20 lg:mt-16 mx-auto  w-full rounded-xl overflow-hidden">
        <div className="w-full p-2 bg-gradient-to-t from-blue-light to-blue-dark">
          <div className="grid grid-cols-1 lg:flex justify-between gap-3">
            <div className="flex justify-center gap-3">
              <select value={selectedCategory} onChange={handleCategory} className="select rounded-lg shadow-lg border-2 bg-sky-300 text-white">
                <option value='' selected disabled>ປະເພດສິນຄ້າ...</option>
                {listProduct&&listCategory.map((category) => (
                  <option value={category._id}>{category.name}</option>
                ))}
                <option value='all'>ທັງໝົດ</option>
              </select>
              <select value={selectedPriceRange} onChange={handlePriceRange} className="select rounded-lg shadow-lg border-2 bg-sky-300 text-white">
                <option value="" selected disabled>ລາຄາສິນຄ້າ...</option>
                <option value="4">{"<"} 100,000</option>
                <option value="3">100,000 - 500,000</option>
                <option value="2">500,000 - 1,000,000</option>
                <option value="1">{">"}1,000,000</option>
                <option value="0">ທັງໝົດ</option>
              </select>
            </div>
            <div className="flex justify-center items-center gap-3 relative">
              <img className=" hidden md:flex lg:flex" src={slidersetting} alt="" width={20} />
              <div className="rounded-lg flex items-center gap-3 bg-background-color">
                <FiSearch className="text-blue-dark ms-2" size={25} />
                <input
                  type="search"
                  className="p-2 bg-background-color rounded-r-lg font-medium outline-none"
                  onChange={(e) => setKeyWord(e.target.value)}
                  placeholder="Search"
                />
              </div>
              <button onClick={handleSearch} className="w-24 p-2 rounded-xl text-white  font-medium bg-gradient-to-b from-blue-light to-blue-dark">
                ຄົ້ນຫາ
              </button>
            </div>
          </div>
        </div>
        {/* Show product */}
        <div className="mt-3 h-[460px] lg:h-[405px] overflow-auto px-2">
          {!isLoading && currentProduct && currentProduct.length <= 0 &&
            <div className="w-full mt-10 flex justify-center items-center">
              <div className="flex flex-col items-center">
                <img alt="" src={emptyIcon}></img>
                <span className="text-center text-blue-light">ບໍ່ມີລາຍການສິນຄ້າ
                </span>
              </div>

            </div>
          }
          <div className="grid grid-cols-3 md:grid-cols-3 lg:grid-cols-8 gap-2">
            {isLoading ? <AnimationLoading /> :
              currentProduct ?
                currentProduct.map((product) => {
                  return (
                    <div className="bg-white shadow-lg rounded-lg border overflow-hidden">
                      <NavLink to={"/productdetail/" + product._id}>
                        <div className="w-full relative">
                          <div className="w-full h-[110px] overflow-hidden">
                            <img alt="" src={process.env.REACT_APP_IMGURL + product.images[0]} width={"100%"} />
                          </div>
                          <div className="px-2">
                            <span className="text-[12px] lg:text-[15px]">{product.name}</span>
                          </div>
                          <div className=" absolute top-0 right-0 text-[10px] text-white bg-gradient-to-b from-blue-light to-blue-dark px-2 rounded-bl-lg">
                            <span>{product.point} pv</span>
                          </div>
                        </div>
                      </NavLink>
                      <div className="pb-1 lg:pb-1 w-full flex justify-around">
                        <p className="flex items-center gap-2 text-xs"><FaKipSign /> {product.price.toLocaleString()}</p>
                        <span onClick={() => handleAddToCart(product._id)} className=" border mr-1 rounded-lg bg-gradient-to-b from-blue-light to-blue-dark text-white px-2 py-1 cursor-default hover:cursor-pointer"> <FaCartPlus /> </span>
                      </div>
                    </div>
                  )
                })
                : ''
            }
          </div>
        </div>
        <div className="w-full mt-3 mb-5 px-3 bg-white shadow-lg rounded-b-xl">
          <div className="w-full relative">
            <Link to="/productorder" className=" absolute left-3 top-3 ">
              <div className="p-2 relative rounded-lg font-Noto font-medium shadow-lg  border-2 border-blue-dark text-blue-dark  flex items-center justify-center gap-2">
                <BiSolidCart /> <span className=" hidden lg:inline-block">ສີນຄ້າໃນກະຕ່າ</span>
                {numCart != 0 && <div className="w-5 h-5 absolute -top-3 -right-2 bg-red-500 text-white rounded-full flex justify-center items-center">{numCart}</div>}
              </div>
            </Link>
            <ReactPaginate
              previousLabel={"<"}
              nextLabel={">"}
              breakLabel={"..."}
              containerClassName="Pagination"
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link prev"
              nextClassName="page-item"
              nextLinkClassName="page-link next"
              marginPagesDisplayed={0}
              pageRangeDisplayed={8}
              activeLinkClassName="active"
              onPageChange={({ selected }) =>
                handlePageClick(selected + 1)
              }
              pageCount={pageCount}
            />

          </div>
        </div>
      </div>
    </div>
  );
}

export default Product;
