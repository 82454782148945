import React, { useEffect, useState } from "react";
import hongfah from "../../assets/hongfah.png";
import rectangle from "../../assets/Rectangle 240 (1).png";
import { NavLink, useParams } from "react-router-dom";
import { findOrderByID } from "../../api/order";
import { PulseLoader } from "react-spinners";
import moment from "moment";
const AnimationLoading = () => {
  return (
    <PulseLoader
      color="#36d7b7"
      size={10}
      aria-label="Loading Spinner"
      data-testid="loader" />
  )
}
function dateFormat(dateString) {
  const dateObject = moment(dateString);
  return dateObject.format("DD-MM-YYYY");
}
function Paysuccess() {
  const { id } = useParams();
  const [order, setOrder] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchData();
  }, [])
  const fetchData = async () => {
    setLoading(true)
    const data = await findOrderByID(id);
    setOrder(data);
    setLoading(false)
  }
  console.log(order)
  return (
    <div className="w-full ">
      <div className="w-full mt-28 py-5 shadow-lg border p-2 bg-white rounded-xl md:mt-24 lg:mt-24 md:py-8 lg:py-10 lg:px-10">
        <div className="flex justify-between">
          <div className="flex justify-center items-center">
            <img src={hongfah} alt="" className="w-[100px] lg:w-[70%]" />
          </div>
          <div className="w-full relative">
            <div className="w-full h-full flex justify-center items-center">
              <div className="text-center">
                <h1 className=" text-2xl lg:text-5xl text-green-500 font-bold ">
                  ສັງຊື້ສຳເລັດ
                </h1>
                <p>
                  ເມື່ອໃບບິນຂອງທ່ານໄດ້ຮັບການກວດສອບ ແລະ ຈັດສົ່ງ, ລະບົບຈະແຈ້ງເຕືອນ
                  ແລະ ສົ່ງ SMS ແຈ້ງລາຍລະອຽດເມື່ອໄດ້ຮັບການອະນຸມັດ
                </p>
              </div>
            </div>
            <div className="text-center lg:absolute top-0 right-2 text-blue-light font-bold ">
              <h5> ລະຫັດບິນ : {order.orderCode} </h5>
              <span className="text-yellow-500">ກຳລັງກວດສອບ</span>
            </div>
          </div>
        </div>
        <div className="mt-20 lg:mt-10 w-full max-h-[500px] overflow-auto rounded-lg">
          <table className="table w-[600px] lg:w-full">
            <thead className="w-full rounded-t-xl bg-gradient-to-t from-blue-dark to-blue-light text-white sticky top-0">
              <tr className="">
                <th className="p-2 text-center">
                  ລະຫັດສີນຄ້າ
                </th>
                <th className="text-center">
                  ຮູບສິນຄ້າ
                </th>
                <th className="text-center">
                  ຊື່ສິນຄ້າ
                </th>
                <th className="text-center">
                  ຈຳນວນ
                </th>
                <th className="text-center">
                  ລາຄາລວມ
                </th>
                <th className="text-center">
                  ວັນທີ່ສັ່ງຊື້
                </th>
              </tr>
            </thead>
            <tbody className="">
              {loading ?
                <AnimationLoading />
                :
                order.products ?
                  order.products.map((product) => {
                    return (
                      <tr className=" border border-slate-300">
                        <td className=" ">
                          <div className="">
                            <span className="">{product.product_id.productCode}</span>
                          </div>
                        </td>
                        <td >
                          <div className="flex justify-center items-center p-2">
                            <img
                              src={process.env.REACT_APP_IMGURL+product.product_id.images[0]}
                              alt=""
                              width={100}
                              className="rounded-lg"
                            />
                          </div>

                        </td>
                        <td className="text-center">
                          <h1>{product.product_id.name} </h1>
                        </td>
                        <td className="text-center">
                          <h1>{product.qty}</h1>
                        </td>
                        <td className=" text-center">
                          {product.totalPrice.toLocaleString()}
                        </td>
                        {/* The last two cells in this row will span multiple columns */}
                        <td
                          className=" text-center"
                        >
                          {dateFormat(product.createdAt)}
                        </td>
                      </tr>
                    )
                  })
                  : '...'
              }

            </tbody>
          </table>
        </div>
      </div>
      <div className=" w-full flex flex-col ">
        <NavLink
          to="/product"
          className=" flex justify-center rounded-lg items-center w-60 text-xl font-bold mr-16 h-12 my-6 border-2 border-blue-dark text-blue-dark self-end "
        >
          ກັບໄປໜ້າຫຼັກ
        </NavLink>
      </div>
    </div>
  );
}

export default Paysuccess;
