
import { apiUrl } from './apiconfig';
import axios from "axios";
import qs from "qs"

export const getAllProduct = async () => {
    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: apiUrl + '/product/getall',
        headers: {
            'Authorization': 'Bearer ' + token()
        }
    };
    try {
        const response = await axios.request(config);
        if (response.data.message === "success") {
            // console.log(response.data.data)
            return response.data.data
        } else {
            return false;
        }
    } catch (error) {
        console.error(error);
        return false;
    }
}
export const getOneProduct = async (id) => {
    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: apiUrl + '/product/getone/' + id,
        headers: {
            'Authorization': 'Bearer ' + token()
        }
    };
    try {
        const response = await axios.request(config);
        if (response.data.message === "success") {
            return response.data.data
        } else {
            return false;
        }
    } catch (error) {
        console.error(error);
        return false;
    }
}
export const getAllCategory = async () => {
    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: apiUrl + '/prodcategory/getall',
        headers: {
            'Authorization': 'Bearer ' + token()
        }
    };
    try {
        const response = await axios.request(config);
        if (response.data.message === "success") {
            // console.log(response.data.data)
            return response.data.data
        } else {
            return false;
        }
    } catch (error) {
        console.error(error);
        return false;
    }
}
export const addToCart = async (product_id, qty) => {
    let data = qs.stringify({
        'product_id': product_id,
        'qty': qty
    })
    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: apiUrl + '/cart/additem',
        headers: {
            'Authorization': 'Bearer ' + token()
        },
        data: data
    };
    try {
        const response = await axios.request(config);
        if (response.data.status) {
            return true;
        } else {
            return false;
        }

    } catch (error) {
        console.error(error);
        return false;
    }

}
export const getCart = async () => {
    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: apiUrl + '/cart/item/getall?page=1',
        headers: {
            'Authorization': 'Bearer ' + token()
        }
    };
    try {
        const response = await axios.request(config);
        if (response.data.status) {
            //console.log(response)
            return response.data.data;
        } else {
            return [];
        }
    } catch (error) {
        console.error(error);
        return [];
    }
}
export const updateQTY = async (item_id, qty) => {
    let config = {
        method: 'put',
        maxBodyLength: Infinity,
        url: apiUrl + `/cart/item/qty/update/${item_id}?qty=${qty}`,
        headers: {
            'Authorization': 'Bearer ' + token()
        },
    };
    try {
        const response = await axios.request(config);
        //console.log(response)
        if (response.status) {
            return true;
        } else {
            return false;
        }
    } catch (error) {
        console.error(error);
        return false;
    }
}
export const deleteItem = async (id) => {
    let data = JSON.stringify({
        "itemsId": [
            id
        ]
    });

    let config = {
        method: 'put',
        maxBodyLength: Infinity,
        url: apiUrl + '/cart/item/delete',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+token()
        },
        data: data
    };

    axios.request(config)
        .then((response) => {
            if(response.data.status){
                return true;
            }else{
                return false;
            }
            
            //console.log(JSON.stringify(response.data));
            
        })
        .catch((error) => {
            console.log(error);
            return false;
        });

}
function token() {
    return localStorage.getItem("token")
};