import React, { useEffect, useState } from "react";
import logout from "../assets/logout.png";
import iconeUser from '../assets/newAsset/Asset 2.svg'
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import { getPosition } from "../api/linework";
import { getMyProfile } from "../api/auth";
import { GiHamburgerMenu } from "react-icons/gi";
import { IoMdRefresh } from "react-icons/io";
import Swal from "sweetalert2";
import verify from '../assets/verify.png';

function Navbar() {
  const location = useLocation();
  const [user, setUser] = useState([]);
  const [position, setPosition] = useState([]);
  const [positionIcon, setPositionIcon] = useState('');
  const [isLoggedIn, setLoggedIn] = useState(true);
  const [isHovered, setHovered] = useState(false);
  const [isHoverProfile, setHoverProfile] = useState(false);
  const [contentHovered, setContentHovered] = useState(false);
  const [contentPorfileHovered, setContentProfileHovered] = useState(false);
  const [isToggleMenu, setIsToggleMenu] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    fetchdata();
  }, [])
  const fetchdata = async () => {
    const data = await getPosition();
    const getuser = JSON.parse(localStorage.getItem("user"));
    setUser(getuser);
    setPosition(data)
  }
  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("tokenExpiresAt");
    localStorage.removeItem("refresToken");
    localStorage.removeItem("refresTokenExpiresAt");
    localStorage.removeItem("user");
    localStorage.removeItem("position");
    localStorage.removeItem("position_icon");

    setLoggedIn(false);
  };
  const handleRefresh = async () => {
    const oldData = JSON.parse(localStorage.getItem("user"))
    const getUser = await getMyProfile();
    getUser.isMaintainSales = oldData.isMaintainSales
    const process = localStorage.setItem("user", JSON.stringify(getUser));
    window.location.reload();
  }
  const logoutAndRedirect = () => {
    handleLogout();
    navigate("/login");
  };

  const handleLogin = () => {
    setLoggedIn(true);
    window.location.href = "/login";
  };
  const Alert = () => {
    Swal.fire({
      html: '<div class="flex flex-col items-center">' +
        '<img src="' + verify + '" alt="verify" class="w-16 h-16 mb-4" />' +
        '<h2 class="text-lg font-semibold mb-2">ທ່ານຍັງບໍ່ທັນຢືນຢັນຕົວຕົນ</h2>' +
        '<p>ທ່ານຕ້ອງຢືນຢັນຕົວຕົນກ່ອນ ຈຶ່ງຈະສາມາດນຳໃຊ້ຟັງຊັ້ນຕ່າງໆຂອງພວກເຮົາໄດ້</p>' +
        '</div>',
      showCancelButton: true,
      confirmButtonColor: "#00AEEF",
      confirmButtonText: "ຢືນຢັນ",
      cancelButtonText: "ພາຍຫຼັງ",
      cancelButtonColor: "#ffffff",
      cancelButtonText: '<span style="color: blue;">ພາຍຫຼັງ</span>',
      reverseButtons: true,
      width: "340px"
    }).then((result) => {
      if (result.isConfirmed) {
        navigate("/verifyswall");
      } else {

      }
    });
  }
  console.log(position)
  return (
    <div className=" min-w-fit shadow-lg bg-white">

      <div className="flex justify-between">
        <div className=" lg:ps-[250px] flex justify-center items-center">
          <button onClick={handleRefresh}><IoMdRefresh size={30} /></button>
        </div>
        <div className="w-full py-3 px-4 flex items-center justify-between md:justify-end lg:justify-end gap-7">
          <div className="flex justify-center items-center gap-2">
            <div className=" hidden lg:block md:block relative ">
              {position?.user_id?.position_id?.icon ?
                <div className="w-[50px] h-[50px] rounded-full overflow-hidden">
                  <img alt="positonicon" src={process.env.REACT_APP_IMGURL + position?.user_id?.position_id?.icon} className="w-full h-full object-cover" />
                </div>
                : position?.user_id?.package_id?.image &&
                <div className="w-[50px] h-[50px] rounded-full overflow-hidden">
                  <img alt="positonicon" src={process.env.REACT_APP_IMGURL + position?.user_id?.package_id?.image} className="w-full h-full object-cover" />
                </div>
              }
            </div>
            <div className=" hidden lg:block md:block relative ">
              <div className={`text-xs sm:text-lg  flex items-center justify-center  bg-sky-200 rounded-full`}>
                <h1 className=" text-blue-dark px-2">ທ່ານມີ {position ? position?.pvTotal : '0'} PV</h1>
              </div>
            </div>
          </div>
          <div className="flex">
            <NavLink to="/profile" className="flex justify-center items-center">
              <div
                className={`flex justify-center items-center rounded-full}`}
              >
                <div className=" flex lg:hidden md:hidden relative ">
                  {position?.user_id?.position_id?.icon ?
                    <div className="w-[50px] h-[50px] rounded-full overflow-hidden">
                      <img alt="positonicon" src={process.env.REACT_APP_IMGURL + position?.user_id?.position_id?.icon} className="w-full h-full object-cover" />
                    </div>
                    : position?.user_id?.package_id?.image ?
                      <div className="w-[50px] h-[50px] rounded-full overflow-hidden">
                        <img src={process.env.REACT_APP_IMGURL + position?.user_id?.package_id?.image} className="w-full h-full object-cover" />
                      </div>
                      :
                      <div className="">
                        <img className="w-[50px] h-[50px] rounded-full overflow-hidden object-cover" src={position?.user_id?.profile ? process.env.REACT_APP_IMGURL + position?.user_id?.profile : iconeUser} alt="" width={40} />
                      </div>
                  }
                </div>
                <div className=" hidden md:flex lg:flex">
                  <img className="w-[50px] h-[50px] rounded-full overflow-hidden object-cover" src={position?.user_id?.profile ? process.env.REACT_APP_IMGURL + position?.user_id?.profile : iconeUser} alt="" width={40} />
                </div>

                <h1 className="ms-3">{position?.user_id?.firstName} {" "} {position?.user_id?.lastName}</h1>
              </div>
            </NavLink>
          </div>
          {isLoggedIn ? (
            <div
              className="hidden md:flex lg:flex justify-center items-center"
              onClick={logoutAndRedirect}
            >
              <img src={logout} alt="" className="w-8 h-8 cursor-pointer" />
            </div>
          ) : (
            <NavLink
              to="/login"
              className="flex justify-center items-center"
              onClick={handleLogin}
            >
              {/* Your login button content */}
            </NavLink>
          )}
          <button onClick={() => { setIsToggleMenu(!isToggleMenu) }} className='md:hidden lg:hidden'>
            <GiHamburgerMenu size={30} />
          </button>
        </div>
      </div>
      <div className={` ${isToggleMenu ? 'block' : 'hidden'} md:hidden lg:hidden`}>
        <div className="z-50 p-4 grid border-t-2 bg-white">
          <NavLink to="/" onClick={() => { setIsToggleMenu(false) }} className="p-2 border-b-2">Dashboard</NavLink>
          <NavLink to="/product" onClick={() => { setIsToggleMenu(false) }} className="p-2 border-b-2">ສີນຄ້າ</NavLink>
          <NavLink to="/order" onClick={() => { setIsToggleMenu(false) }} className="p-2 border-b-2">ອໍເດີ້ສັ່ງຊື້</NavLink>
          <button
            onClick={() => {
              setIsToggleMenu(false);
              if (user.isVerify) {
                navigate("/member")
              } else {
                Alert();
              }
            }}
            className="text-left p-2 border-b-2">ແຜນຜັງສາຍງານ</button>
          <NavLink to="/bonus" onClick={() => { setIsToggleMenu(false) }} className="p-2 border-b-2">ປະຫວັດໂບນັດ</NavLink>
          <button
            onClick={() => {
              setIsToggleMenu(false);
              if (user.isVerify) {
                navigate("/ewalletwithdraw")
              } else {
                Alert();
              }
            }}
            className="text-left p-2 border-b-2">ກະເປົ໋າ E-wallet</button>
          <NavLink to="/travel" onClick={() => { setIsToggleMenu(false) }} className="p-2 border-b-2">ທິບທ່ອງທ່ຽວ</NavLink>
          <button onClick={logoutAndRedirect} className="p-4 border-b-2 text-red-500">ອອກຈາກລະບົບ</button>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
