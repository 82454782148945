import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import paybankaccount from "../../assets/paybankaccount.png";
import paywallet from "../../assets/paywallet.png";
import bankgray from "../../assets/bankgray.png";
import ewalletwhite from "../../assets/ewalletwhite.png";
import cash from "../../assets/cash.png";
import walleticon from "../../assets/wallet.png";
import { FaPhoneAlt } from "react-icons/fa";
import billqr from "../../assets/billqr.jpg";
import { IoCloudUploadOutline } from "react-icons/io5";
import { searchUserCode } from "../../api/order";
import Swal from "sweetalert2";
import { PulseLoader } from 'react-spinners'
import { getCart } from "../../api/product";
import { createOrderWithBCELone, createOrderWithEwallet } from "../../api/order";
import { getMyWallet } from "../../api/ewallet";
import { getCCLbonus } from "../../api/ewallet";
import verify from '../../assets/verify.png';
import { getallBranch } from "../../api/branch";


const AutoWrapInput = ({ value, onChange }) => {
  return (
    <div className=" p-2 rounded-lg  ">
      <textarea
        className=" w-full border rounded-lg resize-none bg-white"
        placeholder="ຂໍ້ມູນເພີ່ມເຕີມ"
        value={value}
        onChange={onChange}
      />
    </div>
  );
};
function PayChoice() {
  const navigate = useNavigate();
  const [branchList, setBranchList] = useState([]);
  const [branchDetail, setBranchDetail] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedCompany, setSelectedCompany] = useState("");
  const [selectedBranch, setSelectedBranch] = useState("");
  const [selectedHongFar, setSelectedHongFar] = useState("");
  const [call, setCall] = useState("")
  const [inputText, setInputText] = useState("");

  // new state
  const [cart, setCart] = useState([]);
  const [wallet, setWallet] = useState([]);
  const [amount, setAmount] = useState(0)
  const [payChoice, setPayChoice] = useState('');
  const [userCode, setUserCode] = useState('')
  const [orderFor, setOrderFor] = useState('')
  const [receiverName, setReceiverName] = useState('')
  const [phone, setPhone] = useState('');
  const [passwordWallet, setPasswordWallet] = useState('');
  const [isloading, setIsloading] = useState(false);
  const [loadPay, setLoadPay] = useState(false);
  const [isShip, setIsShip] = useState(false);
  const [isNoShip, setIsNoShip] = useState(false);
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    fetchdata()
    setPayChoice('bcelone');
    setSelectedCompany("ອານຸສິດ ຂົນສົ່ງດ່ວນ");
    setSelectedHongFar(0)
    setIsShip(true);
  }, [])

  async function fetchdata() {
    setIsloading(true);
    const data = await getCart();
    const branchs = await getallBranch();

    var countprice = 0
    for (const i of data) {
      countprice += (i.product_id.price * i.qty)
    }
    if (branchs) {
      setBranchList(branchs.data);
    }
    setCart(data);
    setAmount(countprice);
    setIsloading(false);
  }
  const handleImage = (e) => {
    setSelectedImage(e.target.files[0])
  }
  const handleEnterKeyPress = (event) => {
    if (event.key === 'Enter') {
      // Perform some action when Enter is pressed, like submitting the form
      handleSearchUser();
    }
  }

  //btn create
  const handleSubmit = async () => {
    const error = {};
    if (isShip) {
      if (!selectedBranch) {
        error.selectedBranch = 'ສາຂາ'
      }
      if (!inputText) {
        error.inputText = 'ທີ່ຢູ້: ບ້ານ,ເມືອງ,ແຂວງ'
      }
    }
    if (isNoShip) {
      if (!call) {
        error.call = 'ເບີໂທຕິດຕໍ່'
      } else {
        const trimmedPhoneNumber = call.trim();
        if (!trimmedPhoneNumber || !/^\d{8}$/.test(trimmedPhoneNumber)) {
          error.call = 'ເບີໂທຕິດຕໍ່ ບໍ່ຖືກຕ້ອງ'
        }
      }

    }
    if (payChoice === 'bcelone') {
      if (!selectedImage) {
        error.img = "ອັບໂຫລດໃບບີນ"
      }
    } else {
      if (!passwordWallet) {
        error.password = "ລະຫັດກະເປົ່າ"
      }
    }
    // ຖ້າຂໍ້ມູນຄົບຖ້ວນ
    if (Object.keys(error).length === 0) {
      Swal.fire({
        title: "ຢັ້ງຢືນຊຳລະເງິນ!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: "#00AEEF",
        confirmButtonText: "ຢືນຢັນ",
        cancelButtonText: "ພາຍຫຼັງ",
        cancelButtonColor: "#ffffff",
        cancelButtonText: '<span style="color: blue;">ພາຍຫຼັງ</span>',
        reverseButtons: true,
        width:"340px"
      }).then(async (result) => {
        if (result.isConfirmed) {
          const cartItemID = [];
          for (const i of cart) {
            cartItemID.push(i._id);
          }

          if (payChoice === 'bcelone') { //ຈ່າຍຜ່ານ bcelone
            setLoadPay(true);
            const createorder = await createOrderWithBCELone(
              cartItemID,
              orderFor,
              isShip,
              call,
              selectedImage,
              isShip ? selectedCompany : branchList && branchList[selectedHongFar] && branchList[selectedHongFar].name,
              selectedBranch,
              inputText,
              branchList && branchList[selectedHongFar] && branchList[selectedHongFar].address,
              branchList && branchList[selectedHongFar] && branchList[selectedHongFar].village,
              branchList && branchList[selectedHongFar] && branchList[selectedHongFar].district,
              branchList && branchList[selectedHongFar] && branchList[selectedHongFar].province
            )
            // }branchList && branchList[selectedHongFar] && branchList[selectedHongFar].name
            // const createorder = await createOrderWithBCELone(cartItemID, orderFor, isShip, call, selectedImage, isShip ? selectedCompany : branchList&&branchList[selectedHongFar]&&branchList[selectedHongFar].name, selectedBranch, inputText)
            setLoadPay(false)
            if (createorder) {
              Swal.fire({
                title: "ສຳເລັດ!",
                text: "ສັ່ງຊື້ສຳເລັດ!",
                icon: "success",
                width:"340px"
              }).then(() => {
                navigate(`/paysuccess/${createorder._id}`)
              });
            } else {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "ສັ່ງຊື້ບໍ່ສຳເລັດ!",
                width:"340px"
              });
            }
          }
          else if (payChoice === 'ewallet') {//ຈ່າຍຜ່ານ ewallet
            setLoadPay(true)
            const createorder = await createOrderWithEwallet(
              cartItemID,
              orderFor,
              isShip,
              call,
              selectedImage,
              isShip ? selectedCompany : branchList && branchList[selectedHongFar] && branchList[selectedHongFar].name,
              selectedBranch,
              inputText,
              branchList && branchList[selectedHongFar] && branchList[selectedHongFar].address,
              branchList && branchList[selectedHongFar] && branchList[selectedHongFar].village,
              branchList && branchList[selectedHongFar] && branchList[selectedHongFar].district,
              branchList && branchList[selectedHongFar] && branchList[selectedHongFar].province,
              passwordWallet
            )
            console.log(createorder)
            setLoadPay(false);
            if (createorder === 'your balance is not enough.') {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "ເງິນບໍ່ພໍ!",
                width:"340px"
              });
            } else if (createorder === 'invalid: password') {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "ລະຫັດບໍ່ຖືກຕ້ອງ!",
                width:"340px"
              })
            } else {
              Swal.fire({
                title: "ສຳເລັດ!",
                text: "ສັ່ງຊື້ສຳເລັດ!",
                icon: "success",
                width:"340px"
              }).then(() => {
                navigate(`/paysuccess/${createorder._id}`)
              });
            }
          } else {

          }
        } else {
        }
      });


    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "ຂໍ້ມູນບໍ່ຄົບຖ້ວນ!",
        width:"340px",
        width:"340px"
      });
    }
    setErrors(error);
  }

  // --------------------------------------
  // const handleImageSelect = (event) => {
  //   setSelectedImage(URL.createObjectURL(event.target.files[0]));
  // };
  const handleCompanyChange = (e) => {
    setSelectedCompany(e.target.value);
  };
  const handleHongFaChange = (e) => {
    setSelectedHongFar(e.target.value);
    console.log(e.target.value)
  };

  const handleBranchChange = (e) => {
    setSelectedBranch(e.target.value);
  };

  const handleInputChange = (e) => {
    setInputText(e.target.value);
  };

  const handleSearchUser = async () => {
    if (userCode !== '') {
      setIsloading(true);
      const user = await searchUserCode(userCode)
      if (user) {
        const user_id = JSON.parse(localStorage.getItem("user"))._id;
        if (user._id === user_id) {
          Swal.fire("ນີ້ແມ່ນບັນຊີຂອງທ່ານເອງ \n ກະລຸໃຊ້ບັນຊີອື່ນ!").then(() => {
            setUserCode('')
            setPhone('')
            setReceiverName('')
            setOrderFor(user_id);
          });

        } else {
          setReceiverName(user.firstName + ' ' + user.lastName)
          setPhone(user.phoneNumber);
          setOrderFor(user._id);
        }

      } else {
        Swal.fire("ບໍ່ມີຜູ້ໃຊ້ລະຫັດສະມາຊິກນີ້!").then(() => {
          setUserCode('')
          setPhone('')
          setReceiverName('')
        });
      }
      setIsloading(false);
    } else {
      const error = {};
      if (!userCode) {
        error.userCode = "ລະຫັດສະມາຊິກ"
      }
      setErrors(error)
      setReceiverName('')
      setPhone('')
    }
  }
  const handleCancel = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "ຂໍ້ມູນຈະຖືກລຶບ!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "ຍົກເລີກຊຳລະເງິນ",
      cancelButtonText: "ປິດ",
      width:"340px"
    }).then((result) => {
      if (result.isConfirmed) {
        navigate("/productorder")
      }
    });
  }
  const handleSelectDelivery = (num) => {
    if (num === 0) {
      setIsShip(true)
      setIsNoShip(false)
    } else {
      setIsShip(false)
      setIsNoShip(true);
    }
  }
  const AnimationLoading = () => {
    return (
      <PulseLoader
        color="#36d7b7"
        size={10}
        aria-label="Loading Spinner"
        data-testid="loader" />
    )
  }
  const handlePayChoice = async (x) => {
    if (x === 'bcelone') {
      setPayChoice('bcelone')
    } else {
      setPayChoice('ewallet')
      const wallet = await getCCLbonus()
      if (wallet) {
        setWallet(wallet)
      } else {
        Swal.fire({
          html: '<div class="flex flex-col items-center">' +
            '<img src="' + verify + '" alt="verify" class="w-16 h-16 mb-4" />' +
            '<h2 class="text-lg font-semibold mb-2">ທ່ານຍັງບໍ່ທັນຢືນຢັນຕົວຕົນ</h2>' +
            '<p>ທ່ານຕ້ອງຢືນຢັນຕົວຕົນກ່ອນ ຈຶ່ງຈະສາມາດນຳໃຊ້ຟັງຊັ້ນຕ່າງໆຂອງພວກເຮົາໄດ້</p>' +
            '</div>',
          showCancelButton: true,
          confirmButtonColor: "#00AEEF",
          confirmButtonText: "ຢືນຢັນ",
          cancelButtonText: "ພາຍຫຼັງ",
          cancelButtonColor: "#ffffff",
          cancelButtonText: '<span style="color: blue;">ພາຍຫຼັງ</span>',
          reverseButtons: true,
          width:"340px"
        }).then((result) => {
          if (result.isConfirmed) {
            navigate("/verifyswall")
          } else {
            setPayChoice('bcelone')
          }
        });
      }
    }
  }
  return (
    <div className="w-full relative">
      {loadPay &&
        <div className=" fixed pt-10 lg:p-96 inset-0 :bg-black bg-opacity-25 backdrop-blur-sm z-50">
          <PulseLoader
            className=" text-center"
            color="#36d7b7"
            size={30}
            aria-label="Loading Spinner"
            data-testid="loader" />
        </div>
      }
      <div className="w-full mt-28 py-5 shadow-lg border p-2 bg-white rounded-xl md:mt-24 lg:mt-24 md:py-8 lg:py-10 lg:px-10">
        <div className="w-full text-center text-sm lg:text-3xl font-medium text-blue-dark pb-3">
          <h1>ຊື້ໃຫ້ຜູ້ອື່ນ (ສາມາດວ່າງໄດ້)</h1>
        </div>
        <div className=" w-full">
          <div className="">
            <label htmlFor="branch" className=" font-medium ">
              ລະຫັດສະມາຊິກ
            </label>
            <div className=" flex items-center border border-blue-dark rounded-lg ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                className=" ml-4 mx-2"
              >
                <path
                  d="M9.54361 11.6817C5.99217 12.0027 3.28647 15.0036 3.33361 18.5692V18.75C3.33361 19.4403 3.89326 20 4.58361 20C5.27397 20 5.83361 19.4403 5.83361 18.75V18.5192C5.79604 16.3299 7.41166 14.463 9.58361 14.1858C11.8763 13.9585 13.9192 15.6328 14.1466 17.9255C14.16 18.061 14.1668 18.1971 14.1669 18.3333V18.75C14.1669 19.4403 14.7266 20 15.4169 20C16.1073 20 16.6669 19.4403 16.6669 18.75V18.3333C16.6629 14.6473 13.6714 11.6624 9.98541 11.6665C9.83803 11.6667 9.69068 11.6717 9.54361 11.6817Z"
                  fill="#00AEEF"
                />
                <path
                  d="M10.0002 10C12.7617 10 15.0002 7.76141 15.0002 5C15.0002 2.23859 12.7617 0 10.0002 0C7.23884 0 5.00024 2.23859 5.00024 5C5.00298 7.76027 7.23997 9.99723 10.0002 10ZM10.0002 2.5C11.3809 2.5 12.5002 3.6193 12.5002 5C12.5002 6.3807 11.3809 7.5 10.0002 7.5C8.61954 7.5 7.50024 6.3807 7.50024 5C7.50024 3.6193 8.61954 2.5 10.0002 2.5Z"
                  fill="#00AEEF"
                />
              </svg>
              <input
                type="search"
                name=""
                id=""
                value={userCode}
                onChange={(e) => { setUserCode(e.target.value) }}
                onKeyPress={handleEnterKeyPress}
                className="p-2 flex w-full focus:border-blue-dark outline-none bg-white"
              />{" "}
              <button onClick={handleSearchUser} className="p-3 bg-gradient-to-t from-blue-dark to-blue-light flex items-center justify-center rounded-lg gap-3 text-white text-lg font-bold ">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="21"
                  height="20"
                  viewBox="0 0 21 20"
                  fill="none"
                >
                  <path
                    d="M20.1935 17.9925L15.6596 13.4586C16.7511 12.0054 17.3404 10.2367 17.3384 8.4192C17.3384 3.77688 13.5615 0 8.9192 0C4.27688 0 0.5 3.77688 0.5 8.4192C0.5 13.0615 4.27688 16.8384 8.9192 16.8384C10.7367 16.8404 12.5054 16.2511 13.9586 15.1596L18.4925 19.6935C18.722 19.8986 19.0213 20.0081 19.329 19.9995C19.6367 19.9909 19.9295 19.8648 20.1472 19.6472C20.3648 19.4295 20.4909 19.1367 20.4995 18.829C20.5081 18.5213 20.3986 18.222 20.1935 17.9925ZM2.90549 8.4192C2.90549 7.2298 3.25818 6.06711 3.91898 5.07816C4.57977 4.08921 5.51899 3.31842 6.61785 2.86325C7.71671 2.40809 8.92587 2.289 10.0924 2.52104C11.259 2.75308 12.3305 3.32583 13.1715 4.16686C14.0126 5.00789 14.5853 6.07944 14.8174 7.24598C15.0494 8.41253 14.9303 9.62169 14.4751 10.7205C14.02 11.8194 13.2492 12.7586 12.2602 13.4194C11.2713 14.0802 10.1086 14.4329 8.9192 14.4329C7.32485 14.431 5.79635 13.7968 4.66897 12.6694C3.5416 11.542 2.9074 10.0135 2.90549 8.4192Z"
                    fill="white"
                  />
                </svg>
                ຄົ້ນຫາ
              </button>
            </div>
            {isloading ? <AnimationLoading /> : ''}
            {errors.userCode && <span className='pt-1 text-red-500'>{errors.userCode}</span>}
          </div>
          <div className="mt-3">
            <label htmlFor="branch" className=" font-medium ">
              ຊື່ຜູ້ຮັບ
            </label>
            <div className=" flex items-center border border-blue-dark rounded-lg ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                className=" ml-4 mx-2"
              >
                <path
                  d="M9.54361 11.6817C5.99217 12.0027 3.28647 15.0036 3.33361 18.5692V18.75C3.33361 19.4403 3.89326 20 4.58361 20C5.27397 20 5.83361 19.4403 5.83361 18.75V18.5192C5.79604 16.3299 7.41166 14.463 9.58361 14.1858C11.8763 13.9585 13.9192 15.6328 14.1466 17.9255C14.16 18.061 14.1668 18.1971 14.1669 18.3333V18.75C14.1669 19.4403 14.7266 20 15.4169 20C16.1073 20 16.6669 19.4403 16.6669 18.75V18.3333C16.6629 14.6473 13.6714 11.6624 9.98541 11.6665C9.83803 11.6667 9.69068 11.6717 9.54361 11.6817Z"
                  fill="#00AEEF"
                />
                <path
                  d="M10.0002 10C12.7617 10 15.0002 7.76141 15.0002 5C15.0002 2.23859 12.7617 0 10.0002 0C7.23884 0 5.00024 2.23859 5.00024 5C5.00298 7.76027 7.23997 9.99723 10.0002 10ZM10.0002 2.5C11.3809 2.5 12.5002 3.6193 12.5002 5C12.5002 6.3807 11.3809 7.5 10.0002 7.5C8.61954 7.5 7.50024 6.3807 7.50024 5C7.50024 3.6193 8.61954 2.5 10.0002 2.5Z"
                  fill="#00AEEF"
                />
              </svg>
              <input
                type="text"
                name=""
                id=""
                readOnly
                value={receiverName}
                className=" flex w-full p-3 focus:border-blue-dark outline-none rounded-lg bg-white"
              />
            </div>
          </div>
          <div className="mt-3">
            <label htmlFor="branch" className=" font-medium ">
              ເບີໂທຜູ້ຮັບ
            </label>
            <div className=" flex items-center border border-blue-dark rounded-lg ">
              <FaPhoneAlt className=" ml-4 mx-4 text-blue-dark" />
              <input
                type="text"
                name=""
                id=""
                value={phone}
                readOnly
                className=" flex w-full p-3 focus:border-blue-dark outline-none rounded-lg bg-white"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="w-full mt-5 py-5 shadow-lg border p-2 bg-white rounded-xl  md:py-8 lg:py-10 lg:px-10">
        <div className="w-full text-center text-sm lg:text-3xl font-medium text-blue-dark pb-3">
          <h1>ວິທີຮັບສິນຄ້າ</h1>
        </div>
        <div className="flex justify-between items-center gap-2 lg:gap-10">
          <div onClick={() => handleSelectDelivery(0)} className={`w-full h-[500px] lg:h-[350px] p-4 border rounded-xl ${isShip ? ` bg-gradient-to-r from-blue-dark to-blue-light` : 'bg-productpay-menu-color'}`}>
            <div className="w-full flex flex-col justify-center">
              <div className="w-full text-center">
                <h1 className="my-2">ຈັດສົ່ງໃຫ້ຂ້ອຍ</h1>
              </div>
              <div className="w-full">
                <label htmlFor="company">ບໍລິສັດ ຂົນສົ່ງ</label>
                <select
                  id="company"
                  className="select w-full rounded-lg shadow-lg bg-white"
                  onChange={handleCompanyChange}
                >
                  <option selected value={"ອານຸສິດ ຂົນສົ່ງດ່ວນ"}>ອານຸສິດ ຂົນສົ່ງດ່ວນ</option>
                  <option value={"ຮຸ່ງອາລຸນ ຂົນສົ່ງດ່ວນ"}>ຮຸ່ງອາລຸນ ຂົນສົ່ງດ່ວນ</option>
                  <option value={"ມີໄຊ ຂົນສົ່ງດ່ວນ"}>ມີໄຊ ຂົນສົ່ງດ່ວນ</option>
                </select>
              </div>
              <div className="mt-2">
                <label htmlFor="branch">ສາຂາ</label>
                <input
                  type="text"
                  id="branch"
                  className="w-full p-3 rounded-lg bg-white"
                  placeholder="ປ້ອນຂໍ້ມູນສາຂາ"
                  value={selectedBranch}
                  onChange={handleBranchChange}
                />
                {errors.selectedBranch && <span className='pt-1 text-red-500'>{errors.selectedBranch}</span>}
              </div>
              <div className="w-full mt-2 bg-white rounded-lg">
                <AutoWrapInput
                  value={inputText}
                  onChange={handleInputChange}
                />
                {errors.inputText && <span className='pt-1 text-red-500'>{errors.inputText}</span>}
              </div>
            </div>
          </div>
          <div onClick={() => handleSelectDelivery(1)} className={`w-full h-[500px] lg:h-[350px] p-4 border rounded-xl ${isNoShip ? ` bg-gradient-to-r from-blue-dark to-blue-light` : 'bg-productpay-menu-color'} rounded-xl`}>
            <div className="w-full flex flex-col justify-center">
              <div className="w-full text-center">
                <h1 className="my-2">ໄປຮັບເອງທີ່ສາຂາ</h1>
              </div>
              <div className="w-full">
                <label htmlFor="company">ເລືອກສາຂາໄປຮັບ</label>
                <select
                  id="company"
                  className="select w-full rounded-lg shadow-lg bg-white"
                  onChange={e => setSelectedHongFar(e.target.value)}
                >
                  {branchList && branchList.length > 0 && branchList.map((item, index) => {
                    return (
                      <option key={index} value={index}>
                        {item.name}
                      </option>
                    )
                  })}
                </select>
              </div>
              <div className="mt-2">
                <label htmlFor="branch">ເບີໂທຕິດຕໍ່</label>
                <div className=" flex gap-2 items-center bg-white rounded-lg">
                  <label
                    htmlFor="branch"
                    className="p-3 justify-center flex text-gray-400 "
                  >
                    {" "}
                    20{" "}
                  </label>
                  <input
                    type="text"
                    id="branch"
                    className="w-full p-3 rounded-lg bg-white"
                    placeholder="7xxxxxxx"
                    value={call}
                    onChange={(e) => setCall(e.target.value)}
                  />
                </div>
                {errors.call && <span className='pt-1 text-red-500'>{errors.call}</span>}
              </div>
              <div className="mt-2 p-2 bg-white rounded-lg flex flex-col justify-center ">
                <div className="">
                  <li>
                    {branchList && branchList[selectedHongFar] && branchList[selectedHongFar].name}
                  </li>
                  <li>
                    ຕັ້ງຢູ່:{branchList && branchList[selectedHongFar] && branchList[selectedHongFar].address},
                    {branchList && branchList[selectedHongFar] && branchList[selectedHongFar].village},
                    {branchList && branchList[selectedHongFar] && branchList[selectedHongFar].district},
                    {branchList && branchList[selectedHongFar] && branchList[selectedHongFar].province},

                  </li>
                  <li>
                    ເບີໂທຕິດຕໍ່:{branchList && branchList[selectedHongFar] && branchList[selectedHongFar].phoneNumber},
                  </li>
                  <li> ເປິດບໍລິການແຕ 8:00 ຫາ 17:00</li>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full mt-5 py-5 shadow-lg border p-2 bg-white rounded-xl md:py-8 lg:py-10 lg:px-10">
        <div>
          <div className="w-full text-center text-sm lg:text-3xl font-medium text-blue-dark pb-3">
            <h1>ວິທີຊຳລະຄ່າສິນຄ້າ</h1>
          </div>
          <div className=" flex justify-around gap-2">
            <div
              onClick={() => { handlePayChoice('bcelone') }}
              className={`p-2 lg:p-8 border bg-gradient-to-r  flex items-center text-sm lg:text-3xl gap-3 lg:gap-6 justify-center rounded-xl font-bold ${payChoice === 'bcelone' ? 'from-blue-dark to-blue-light text-white' : 'from-gray-400 to-gray-200 text-gray-500'}`}>
              <img className="w-10 lg:w-100" src={payChoice === 'bcelone' ? paybankaccount : bankgray} alt="" /> ຊຳລະຜ່ານບັນຊີທະນາຄານ
            </div>
            <div
              onClick={() => { handlePayChoice('ewallet') }}
              className={`p-2 lg:p-8 border bg-gradient-to-t  flex items-center text-sm lg:text-3xl gap-3 lg:gap-6 justify-center rounded-xl font-bold ${payChoice === 'ewallet' ? 'from-blue-dark to-blue-light text-white' : 'from-gray-400 to-gray-200 text-gray-500'}`}>
              <img className="w-10 lg:w-100" src={payChoice === 'ewallet' ? ewalletwhite : paywallet} alt="" /> ຊຳລະຜ່ານກະເປົ່າ Ewallet
            </div>
          </div>
        </div>
        {payChoice === 'bcelone' ?
          <div className="mt-5 w-full ">
            <div className="grid grid-cols-2">
              <div className="text-center">
                <div className="flex flex-col">
                  <span>ຊຳລະຜ່ານ QR ນີ້</span>
                  <span> ຈຳນວນເງິນທີ່ຕ້ອງໂອນ <span className="text-green-500 font-medium">{amount.toLocaleString()}</span> ກີບ</span>
                </div>
                <div className="flex justify-center items-center">
                  <img className="w-[100%] lg:w-[60%]" src={billqr} alt="" />
                </div>
              </div>
              <div className="">
                {selectedImage ? (
                  <div className="flex justify-center items-center">
                    <img
                      src={URL.createObjectURL(selectedImage)}
                      className="w-[100%] lg:w-[30%]"
                      alt=""
                    />
                  </div>
                ) : ''}
                <div className="relative w-full rounded-full flex justify-center items-center">
                  <div className=" flex flex-col items-center gap-3">
                    <IoCloudUploadOutline className=" text-gray-400 w-16 h-20" />
                    <h2 className="mt-3 text-gray-400 font-medium font-Noto text-2xl ">
                      ອັບໂຫລດໃບບີນ
                    </h2>
                    <button className=" p-2 border-2 border-blue-light text-blue-light font-bold text-lg rounded-lg">
                      ເລືອກຮູບ
                    </button>
                  </div>
                  <input
                    type="file"
                    accept=".jpg,.png,gif"
                    className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                    onChange={handleImage}
                  />
                </div>
                {errors.img && <span className='pt-1 text-red-500'>{errors.img}</span>}
              </div>
            </div>
          </div>
          :
          <>
            <div className=" w-full">
              <div className="mt-3">
                <label htmlFor="branch" className=" font-medium ">
                  ເງີນໃນ Wallet ປັດຈຸບັນ
                </label>
                <div className="w-full flex items-center border border-blue-dark rounded-lg font-medium ">
                  <img src={cash} alt="" className=" my-4 px-4 " />
                  <h1> {wallet ? wallet.BonusDetail && wallet.BonusDetail.cashback.toLocaleString() + " ກີບ" : 'ບໍ່ມີຂໍ້ມູນ'} </h1>
                </div>
              </div>
              <div className="mt-3 relative">
                <label htmlFor="branch" className=" font-medium ">
                  {" "}
                  ລະຫັດກະເປົ່າ
                </label>
                <img src={walleticon} alt="" className="absolute left-4 top-10" />
                <input type="password"
                  value={passwordWallet}
                  onChange={(e) => setPasswordWallet(e.target.value)}
                  className="w-full ps-10 py-3 flex items-center border border-blue-dark rounded-lg font-medium bg-white" />
                {errors.password && <span className='pt-1 text-red-500'>{errors.password}</span>}
              </div>
            </div>
            <div className="pt-10">
              <span> ລະບົບຈະຫັກເງີນໃນກະເປົາຂອງທ່ານ </span>
              <div className=" flex  items-center text-3xl font-bold gap-2 ">
                <h1 className=" ">{amount.toLocaleString()} </h1>
                <h1>ກີບ</h1>
              </div>
            </div>
          </>}
        <div className=" w-full grid grid-cols-2 gap-5 lg:gap-10 py-10">
          <button onClick={handleCancel} className="w-full py-3 border rounded-lg  bg-gradient-to-t from-gray-400 to-gray-200 text-white text-xl font-bold ">
            ຍົກເລີກ
          </button>
          <button onClick={handleSubmit} className="w-full py-3 flex justify-center items-center border rounded-lg  bg-gradient-to-r from-blue-light to-blue-dark text-white text-xl font-bold ">
            ຢັ້ງຢືນຊຳລະເງິນ
          </button>
        </div>
      </div>

    </div>
  );
}

export default PayChoice;
