
import { Navigate } from "react-router-dom";
import { Layout } from "../pages";

const PrivateRoute = ({ children }) => {
  const token = localStorage.getItem("token");
  const tokenExpiresAt = localStorage.getItem("tokenExpiresAt");
  const now = new Date();
  const comparedDate = new Date(tokenExpiresAt);
  const isAfter = comparedDate.getTime() > now.getTime();
  if (!token || !isAfter) {
    localStorage.removeItem("token");
    localStorage.removeItem("tokenExpiresAt");
    localStorage.removeItem("refresToken");
    localStorage.removeItem("refresTokenExpiresAt");
    return <Navigate to="/login" />
  }
  else {
    return (
      <>
        <Layout>{children}</Layout>
      </>
    );
  }
};

export default PrivateRoute;
