import { apiUrl } from './apiconfig';
import axios from "axios";

export const getAllPackage = async () => {
    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: apiUrl + '/package/getall',
        headers: {
            'Authorization': 'Bearer ' + token()
        }
    };
    try {
        const response = await axios.request(config);
        if (response.data.status) {
            //console.log(response)
            return response.data.data;
        } else {
            return [];
        }
    } catch (error) {
        console.error(error);
        return [];
    }
}
export const getOnePackage = async (id) => {
    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: apiUrl + '/package/getone/' + id,
        headers: {
            'Authorization': 'Bearer ' + token()
        }
    };
    try {
        const response = await axios.request(config);
        if (response.data.status) {
            //console.log(response)
            return response.data.data;
        } else {
            return [];
        }
    } catch (error) {
        console.error(error);
        return [];
    }
}
export const createOrderPackageWithBCELONE = async (package_id, slip) => {
    var data = new FormData();
    data.append('package_id', package_id);
    data.append('paymentType', 'transfer');
    data.append('slip', slip);

    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: apiUrl + '/package/order/create',
        headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + token()
        },
        data: data
    };
    try {
        const response = await axios.request(config);
        return response.data
    } catch (error) {
        console.error(error);
        return error.response.data;
    }
}
export const createOrderPackageWithEwallet = async (package_id, walletPassword) => {
    var data = new FormData();
    data.append('package_id', package_id);
    data.append('paymentType', 'wallet');
    data.append('walletPassword', walletPassword);

    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: apiUrl + '/package/order/create',
        headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + token()
        },
        data: data
    };
    try {
        const response = await axios.request(config);
        return response.data
    } catch (error) {
        console.error(error);
        return error.response.data;
    }
}
function token() {
    return localStorage.getItem("token")
};