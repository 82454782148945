import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { FaRegEye } from "react-icons/fa";
import { FaRegEyeSlash } from "react-icons/fa";
import iconeUser from '../../assets/newAsset/Asset 2.svg'
import verify from '../../assets/verify.png';
import Swal from 'sweetalert2';

import './style2.css'
import { getMyTeam } from '../../api/linework';
import { getAllPosition } from "../../api/position"
import { PulseLoader } from 'react-spinners';
const AnimationLoading = () => {
	return (
		<PulseLoader
			color="#36d7b7"
			size={10}
			aria-label="Loading Spinner"
			data-testid="loader" />
	)
}
function Member() {
	const [searchParams] = useSearchParams();
	const query = searchParams.get('x');
	const navigate = useNavigate();
	const [myTeam, setMyTeam] = useState([]);
	const [loading, setLoading] = useState(false);
	const [isShowBinary, setIsShowBinary] = useState(Boolean);

	useEffect(() => {
		fetchdata();
	}, [])
	async function fetchdata() {
		setLoading(true);
		const data = await getMyTeam();
		// console.log(data)
		if (data) {
			setMyTeam(data);
			if (query === 'table') {
				setIsShowBinary(false);
			} else {
				setIsShowBinary(true);
			}
		} else {
			// setMyTeam([]);
			// // Alert()
		}
		setLoading(false);
	}

	const handleShow = (format) => {
		if (format === 'binary') {
			setIsShowBinary(true);
		} else {
			setIsShowBinary(false);
		}

	}
	const Alert = () => {
		return (
			Swal.fire({
				html: '<div class="flex flex-col items-center">' +
					'<img src="' + verify + '" alt="verify" class="w-16 h-16 mb-4" />' +
					'<h2 class="text-lg font-semibold mb-2">ທ່ານຍັງບໍ່ທັນຢືນຢັນຕົວຕົນ</h2>' +
					'<p>ທ່ານຕ້ອງຢືນຢັນຕົວຕົນກ່ອນ ຈຶ່ງຈະສາມາດນຳໃຊ້ຟັງຊັ້ນຕ່າງໆຂອງພວກເຮົາໄດ້</p>' +
					'</div>',
				showCancelButton: true,
				confirmButtonColor: "#00AEEF",
				confirmButtonText: "ຢືນຢັນ",
				cancelButtonText: "ພາຍຫຼັງ",
				cancelButtonColor: "#ffffff",
				cancelButtonText: '<span style="color: blue;">ພາຍຫຼັງ</span>',
				reverseButtons: true,
			}).then((result) => {
				if (result.isConfirmed) {
					navigate("/verifyswall")
				} else {
					navigate("/")
				}
			})
		)
	}
	console.log(myTeam)
	return (
		<div className='w-full h-screen'>
			<div className="w-full mt-20 lg:mt-16 p-5 shadow-lg bg-gradient-to-b from-blue-dark to-blue-light rounded-xl">
				<div className='flex justify-center lg:justify-start gap-4'>
					<div onClick={() => handleShow('binary')} className={` w-48 flex justify-center items-center ${isShowBinary ? 'bg-white text-blue-light' : 'bg-sky-400 text-white'} h-10 rounded-lg  font-bold `}>
						<span>ສະແດງຕາມລຳດັບ</span>
					</div>
					<div onClick={() => handleShow('table')} className={` w-48 flex justify-center items-center ${isShowBinary ? 'bg-sky-400 text-white' : 'bg-white text-blue-light'} h-10 rounded-lg font-bold `}>
						<span>ສະແດງຕາມຕາຕະລາງ</span>
					</div>
				</div>
			</div>
			<div className="w-full mt-5 p-5 shadow-lg borde bg-white rounded-xl md:mt-5 lg:mt-5 lg:p-0">
				<div className='my-2  relative'>
					{loading ?
						<AnimationLoading />
						:
						myTeam && isShowBinary ?
							<ShowMyTeamBinaryTree {...myTeam} />
							:
							<ShowMyTeamTable {...myTeam.subtree} />
					}
				</div>
			</div>
			<div className="w-full mt-5 p-5 shadow-lg bg-white rounded-xl md:mt-5 lg:mt-5 lg:p-0">
				<div className=' grid grid-cols-2 lg:grid-cols-4 p-2'>
					{loading ? <div className="flex"><AnimationLoading /></div> :
						myTeam.positionCount &&
						myTeam.positionCount.map((item) => {
							if (item._id) {
								return (
									<div className='flex items-center gap-3 px-6 '>
										<div className='flex justify-center mb-2'>
											<div className='h-[60px] w-[60px]  rounded-full  overflow-hidden'>
												<img alt='' className=' w-full h-full object-cover' src={process.env.REACT_APP_IMGURL + item.icon} />
											</div>
										</div>
										<div>
											<h1 className=' font-bold'>
												{item.title}</h1>
											<div className=' flex items-center gap-2 text-gray-500'>
												<p>{item.count}</p>
												<p>ຄົນ</p>
											</div>
										</div>
									</div>
								)
							} else {
								return (
									<div className='flex items-center gap-3 px-6 '>
										<img src={iconeUser}
											alt=""
											className="w-[60px]" />
										<div>
											<h1 className=' font-bold'>ບໍ່ທັນມີຕຳແໜ່ງ</h1>
											<div className=' flex items-center gap-2 text-gray-500'>
												<p>{item.count}</p>
												<p>ຄົນ</p>
											</div>
										</div>
									</div>
								)
							}
						})}
				</div>
			</div>
		</div>
	)
}

const ShowMyTeamBinaryTree = (x) => {
	const elementRef = useRef(null);
	const containerRef = useRef(null);
	const scrollContainerRef = useRef(null);
	const props = x.subtree
	const [showLineWork, setShowLineWork] = useState(true);
	const [oneUser, setOneUser] = useState([]);
	const [isShowMyInfo, setIsShowMyInfo] = useState(false)
	const [zoom, setZoom] = useState(50);

	useEffect(() => {
		scrollToCenter();
	}, [])
	useEffect(() => {
		scrollToCenter();
	}, [showLineWork])
	const scrollToCenter = () => {
		if (scrollContainerRef.current) {
			const scrollContainer = scrollContainerRef.current;
			const scrollWidth = scrollContainer.scrollWidth;
			const clientWidth = scrollContainer.clientWidth;
			// Calculate the scroll position to center the content
			const scrollPosition = (scrollWidth - clientWidth) / 2;
			// Scroll to the calculated position
			scrollContainer.scrollLeft = scrollPosition;
		}
	};
	const handleShowOneInfo = ({ ...data }) => {
		setOneUser(data)
		setShowLineWork(false)
		scrollToCenter();
	}
	const handleEye = () => {
		setIsShowMyInfo(!isShowMyInfo)
	}
	const handdleBack = () =>{
		setShowLineWork(true);
	}
	return (
		<>
			{showLineWork ?
				<div className=' relative'>
					<button onClick={handleEye} className='m-3'>{isShowMyInfo ? <FaRegEye color='#0db4e8' size={20} /> : <FaRegEyeSlash color='#0db4e8' size={20} />}</button>
					<div className='flex justify-center h-[500px]'>
						<div className='box w-full' style={{ zoom: `${zoom}%` }} ref={scrollContainerRef}>
							<div className="tree w-[10000px]" >
								{/* root */}
								<ul>
									<li >
										<a onClick={() => handleShowOneInfo({ ...props })} ref={elementRef} tabIndex="-1">
											<div className='flex justify-center pb-2'>
												<div className='h-[60px] w-[60px]  rounded-full  overflow-hidden'>
													<img alt='' className=' w-full h-full object-cover' src={props.user_id.position_id.icon ? process.env.REACT_APP_IMGURL + props.user_id.position_id.icon : props.user_id.package_id.image ? process.env.REACT_APP_IMGURL + props.user_id.package_id.image : iconeUser} />
												</div>
											</div>
											<span>{props.user_id.firstName + " " + props.user_id.lastName}</span>
										</a>
										{/* LV1 */}
										{props.children && props.children.length > 0 ?
											<ul>
												{props.children.map((LV1) => {
													return (
														<li>
															{/* src={LV1.user_id.position_id._id ? props.user_id.position_id.icon : iconeUser} */}
															<a onClick={() => handleShowOneInfo({ ...LV1 })}>
																<div className='flex justify-center mb-2'>
																	<div className='h-[60px] w-[60px]  rounded-full  overflow-hidden'>
																		<img alt='' className=' w-full h-full object-cover' src={LV1.user_id.position_id.icon ? process.env.REACT_APP_IMGURL + LV1.user_id.position_id.icon : LV1.user_id.package_id.image ? process.env.REACT_APP_IMGURL + LV1.user_id.package_id.image : iconeUser} />
																	</div>
																</div>
																<span>{LV1.user_id.firstName + " " + LV1.user_id.lastName}</span>
															</a>
															{/* LV2 */}
															{LV1.children ? LV1.children.length > 0 ?
																<ul>

																	{LV1.children.map((LV2) => {
																		return (
																			<li>
																				<a onClick={() => handleShowOneInfo({ ...LV2 })}>
																					<div className='flex justify-center mb-2'>
																						<div className='h-[60px] w-[60px]  rounded-full  overflow-hidden'>
																							<img alt='' className=' w-full h-full object-cover' src={LV2.user_id.position_id.icon ? process.env.REACT_APP_IMGURL + LV2.user_id.position_id.icon : LV2.user_id.package_id.image ? process.env.REACT_APP_IMGURL + LV2.user_id.package_id.image : iconeUser} />
																						</div>
																					</div>
																					<span>{LV2.user_id.firstName + " " + LV2.user_id.lastName}</span>
																				</a>
																				{/* LV3 */}
																				{LV2.children ? LV2.children.length > 0 ?
																					<ul>
																						{LV2.children.map((LV3) => {
																							return (
																								<li>
																									<a onClick={() => handleShowOneInfo({ ...LV3 })}>
																										<div className='flex justify-center mb-2'>
																											<div className='h-[60px] w-[60px]  rounded-full  overflow-hidden'>
																												<img alt='' className=' w-full h-full object-cover' src={LV3.user_id.position_id.icon ? process.env.REACT_APP_IMGURL + LV3.user_id.position_id.icon : LV3.user_id.package_id.image ? process.env.REACT_APP_IMGURL + LV3.user_id.package_id.image : iconeUser} />
																											</div>
																										</div>
																										<span>{LV3.user_id.firstName + " " + LV3.user_id.lastName}</span>
																									</a>
																									{/* LV4 */}
																									{LV3.children ? LV3.children.length > 0 ?
																										<ul>
																											{LV3.children.map((LV4) => {
																												return (
																													<li>
																														<a onClick={() => handleShowOneInfo({ ...LV4 })}>
																															<div className='flex justify-center mb-2'>
																																<div className='h-[60px] w-[60px]  rounded-full  overflow-hidden'>
																																	<img alt='' className=' w-full h-full object-cover' src={LV4.user_id.position_id.icon ? process.env.REACT_APP_IMGURL + LV4.user_id.position_id.icon : LV4.user_id.package_id.image ? process.env.REACT_APP_IMGURL + LV4.user_id.package_id.image : iconeUser} />
																																</div>
																															</div>
																															<span>{LV4.user_id.firstName + " " + LV4.user_id.lastName}</span>
																														</a>
																														{LV4.children && LV4.children.length > 0 &&
																															<ul>
																																{LV4.children.map((LV5) => {
																																	return (
																																		<li>
																																			<a onClick={() => handleShowOneInfo({ ...LV5 })}>
																																				<div className='flex justify-center mb-2'>
																																					<div className='h-[60px] w-[60px]  rounded-full  overflow-hidden'>
																																						<img alt='' className=' w-full h-full object-cover' src={LV5.user_id.position_id.icon ? process.env.REACT_APP_IMGURL + LV5.user_id.position_id.icon : LV5.user_id.package_id.image ? process.env.REACT_APP_IMGURL + LV5.user_id.package_id.image : iconeUser} />
																																					</div>
																																				</div>
																																				<span>{LV5.user_id.firstName + " " + LV5.user_id.lastName}</span>
																																			</a>
																																		</li>
																																	)
																																})}
																															</ul>
																														}
																													</li>
																												)
																											})}
																										</ul>
																										: '' : ''
																									}
																								</li>
																							)
																						})}
																					</ul>

																					: '' : ''
																				}
																			</li>

																		)
																	})}
																</ul>
																: '' : ''
															}
														</li>
													)
												})}
											</ul>
											:
											<ul>
												<li>
													<a>
														<div className='flex justify-center mb-2'>
															<div className='h-[60px] w-[60px]  rounded-full  overflow-hidden'>
																<img alt='' className=' w-full h-full object-cover' src={iconeUser} />
															</div>
														</div>
														<span>ລູກທີມຊັ້ນ 1</span>
													</a>
													<ul>
														<li>
															<a>
																<div className='flex justify-center mb-2'>
																	<div className='h-[60px] w-[60px]  rounded-full  overflow-hidden'>
																		<img alt='' className=' w-full h-full object-cover' src={iconeUser} />
																	</div>
																</div>
																<span>ລູກທີມຊັ້ນ 2</span>
															</a>
															<ul>
																<li>
																	<a>
																		<div className='flex justify-center mb-2'>
																			<div className='h-[60px] w-[60px]  rounded-full  overflow-hidden'>
																				<img alt='' className=' w-full h-full object-cover' src={iconeUser} />
																			</div>
																		</div>
																		<span>ລູກທີມຊັ້ນ 3</span>
																	</a>
																	<ul>
																		<li>
																			<a>
																				<div className='flex justify-center mb-2'>
																					<div className='h-[60px] w-[60px]  rounded-full  overflow-hidden'>
																						<img alt='' className=' w-full h-full object-cover' src={iconeUser} />
																					</div>
																				</div>
																				<span>ລູກທີມຊັ້ນ 4</span>
																			</a>
																			<ul>
																				<li>
																					<a>
																						<div className='flex justify-center mb-2'>
																							<div className='h-[60px] w-[60px]  rounded-full  overflow-hidden'>
																								<img alt='' className=' w-full h-full object-cover' src={iconeUser} />
																							</div>
																						</div>
																						<span>ລູກທີມຊັ້ນ 5</span>
																					</a>
																				</li>
																			</ul>
																		</li>
																	</ul>
																</li>
															</ul>
														</li>
													</ul>
												</li>
											</ul>
										}
									</li>
								</ul>
							</div>
						</div>
					</div>
					{isShowMyInfo ? <TableMyInfo {...x} /> : ''}
					<div className=' absolute bottom-10 gap-3'>
						<button onClick={() => {
							setZoom(zoom + 2)
							scrollToCenter();
						}} className='p-2 border rounded-full me-3 text-black'>+</button>
						<button onClick={() => {

							if (zoom - 2 > 0) {

								setZoom(zoom - 2)
								scrollToCenter();
							}
						}} className='p-2 border rounded-full text-black'>-</button>
					</div>
				</div>
				:
				<div>
					<div className='w-full lg:w-[96%] shadow-lg border bg-white rounded-xl'>
						<div className="m-5">
							<button onClick={handdleBack}
								className='flex items-center justify-center w-40 gap-4 font-bold bg-[#F6F4F4] rounded-xl'
							>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="24"
									height="24"
									viewBox="0 0 24 24"
									fill="none"
									className='my-3'
								>
									<g clip-path="url(#clip0_1432_9467)">
										<path
											d="M16.5283 -1.85948e-07L7.02127 9.52C6.36714 10.1772 5.99991 11.0667 5.99991 11.994C5.99991 12.9213 6.36714 13.8108 7.02127 14.468L16.5393 24L18.6553 21.879L9.13727 12.348C9.04354 12.2542 8.99088 12.1271 8.99088 11.9945C8.99088 11.8619 9.04354 11.7348 9.13727 11.641L18.6453 2.121L16.5283 -1.85948e-07Z"
											fill="black"
										/>
									</g>
									<defs>
										<clipPath id="clip0_1432_9467">
											<rect width="24" height="24" fill="white" />
										</clipPath>
									</defs>
								</svg>
								<span>ສາຍງານ</span>
							</button>
						</div>
						<div>
							{oneUser ?
								<div className='w-full p-3 lg:p-10'>
									<div className='lg:w-[20vw]'>
										<div className='flex justify-between'>
											<span>ສາຍງານ:</span>
											<span className=' font-bold'>{oneUser.lineUp ? oneUser.lineUp : 'ບໍ່ມິ'}</span>
										</div>
										<div className='flex justify-between'>
											<span>ຕຳແໜ່ງ:</span>
											<span className=' font-bold'>{oneUser.user_id && oneUser.user_id.position_id && oneUser.user_id.position_id.title ? oneUser.user_id.position_id.title : 'ບໍ່ມິ'}</span>
										</div>
										<div className='flex justify-between'>
											<span>ຄະແນນສ່ວນໂຕ:</span>
											<span className=' font-bold'>{oneUser.pvTotal}</span>
										</div>
									</div>
									<hr />
									{oneUser.user_id ?
										<div className='w-full lg:w-[20vw]'>
											<div className='flex justify-between'>
												<span>ລະຫັດສະມາຊິກ:</span>
												<span className=' font-bold'>{oneUser.user_id.userCode}</span>
											</div>
											<div className='flex justify-between'>
												<span>ຊື່:</span>
												<span className=' font-bold'>{oneUser.user_id.firstName}</span>
											</div>
											<div className='flex justify-between'>
												<span>ນາມສະກຸນ:</span>
												<span className=' font-bold'>{oneUser.user_id.lastName}</span>
											</div>
										</div>
										: ''}
									<br></br>
									<hr></hr>
									<div className='w-full'>
										<span>ລູກທີມ</span>
										{oneUser.children !== null ? oneUser.children.length > 0 ?
											<>
												<table className=" w-full lg:w-[50%] table-auto border-collapse border border-slate-400">
													<thead>
														<tr>
															<th className='border border-slate-300'>ລະຫັດ</th>
															<th className='border border-slate-300'>ຊື່</th>
															<th className='border border-slate-300'>ນາມສະກຸນ</th>
														</tr>
													</thead>
													<tbody>
														{oneUser.children.map((team) => {
															return (
																<tr>
																	<td className='border border-slate-300'>{team.user_id.userCode}</td>
																	<td className='border border-slate-300'>{team.user_id.firstName}</td>
																	<td className='border border-slate-300'>{team.user_id.lastName}</td>
																</tr>
															)
														})}
													</tbody>
												</table>
											</>
											: '' : ''}

									</div>
								</div>
								:
								''
							}
						</div>
					</div>
				</div>
			}

		</>
	)
}
const ShowMyTeamTable = (props) => {
	const [root, setRoot] = useState([]);
	const [myTeam, setMyTeam] = useState([]);
	const [loading, setLoading] = useState(false);
	useEffect(() => {
		fetchdata();
	}, [])
	
	async function fetchdata() {
		const users = []
		const data = props
		setRoot(data);
		// get lv2
		if (data.children) {
			if (data.children.length > 0) {
				for (const lv2 of data.children) {
					users.push(lv2);
					//get lv3
					if (lv2.children) {
						if (lv2.children.length > 0) {
							for (const lv3 of lv2.children) {
								users.push(lv3);
								if (lv3.children) {
									if (lv3.children.length > 0) {
										for (const lv4 of lv3.children) {
											users.push(lv4)
											if (lv4.children) {
												if (lv4.children.length > 0) {
													for (const lv5 of lv4.children) {
														users.push(lv5)
														if(lv5.children){
															if(lv5.children.length > 0){
																for(const lv6 of lv5.children ){
																	users.push(lv6);
																}
															}
														}
													}
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
		setMyTeam(users);
	}
	
	return (
		<div className='px-5'>
			<div className="w-full h-[500px] overflow-auto">
				<table className="table-auto w-[500px] lg:w-full">
					<thead className="w-full text-lg font-bold text-blue-light border-b sticky top-0">
						<tr className="border border-slate-400 bg-white">
							<th className="py-2">
								ຊື່
							</th>
							<th className=" ">
								ລະຫັດບັນຊີ
							</th>
							<th className="">
								ແພັກເກດ
							</th>
							<th className="">
								ຕຳແໜ່ງ
							</th>
							<th className=" ">
								ຄະແນນສ່ວນໂຕ
							</th>

						</tr>
					</thead>
					<tbody>
						{loading ? <AnimationLoading />
							: root ?
								<>
									<tr className=" border border-slate-300">
										<td className="">
											<h1 className="text-center">
												{root.user_id ? root.user_id.firstName + ' ' + root.user_id.lastName : ''}
											</h1>
										</td>
										<td className="text-center">
											{" "}
											{root.user_id ? root.user_id.userCode : ''}
										</td>
										<td className=" text-center">
											<p>{root?.user_id?.package_id?.packageName ? root?.user_id?.package_id?.packageName  : 'ບໍ່ມີ'} </p>
										</td>
										<td className=" text-center">
											<p>{root?.user_id?.position_id?.title ? root?.user_id?.position_id?.title : 'ບໍ່ມີ'} </p>
										</td>
										<td className=" text-center">
											{root?.pvTotal}
										</td>
									</tr>
									{myTeam.map((user) => {
										return (
											<tr className="border border-slate-300">
												<td className="text-center">
													<h1 className=" ">
														{user.user_id?.firstName}{' '} {user.user_id?.lastName}
													</h1>
												</td>
												<td className="text-center">
													{" "}
													{user.user_id?.userCode}
												</td>
												<td className=" text-center">
													<p>{user.user_id.package_id ? user?.user_id?.package_id?.packageName : 'ບໍ່ມີ'} </p>
												</td>
												<td className=" text-center">
													<p>{user.user_id?.position_id?.title ? user.user_id?.position_id?.title  : 'ບໍ່ມີ'} </p>
												</td>
												<td className=" text-center">
													{user.pvTotal}
												</td>
											</tr>
										)
									})}
								</>
								: 'ບໍ່ມີຂໍ້ມູນ'
						}
					</tbody>
				</table>
			</div>
		</div>
	)

}

const TableMyInfo = (props) => {
	const user = props.subtree.user_id;
	const lineup = props.subtree.lineUp
	const PV = props.PV
	return (
		<div className=' absolute right-0 lg:right-2 bottom-0 w-[15rem] gap-3 font-medium text-sm flex flex-col items-center h-[33rem]  mt-[-39.5rem] ml-[59rem] -z-1'>
			<div className=' w-full bg-white rounded-lg shadow-lg  flex justify-center text-black '>
				<div className=' flex flex-col gap-2 my-1 w-[95%]  '>
					<span className=' my-2 '>
						ລະຫັດສະມາຊິກ {user.userCode}</span>
					<div className=' flex gap-2 '>
						<span>
							ຊື່</span>
						<span>
							{user.firstName + ' ' + user.lastName}</span>
					</div>
					<div className=' flex gap-2  '>
						<div className=' w-[50px] '>
							<span>ຜູ້ອັບໄລ</span>
						</div>
						<span>
							{lineup ? lineup : 'ບໍ່ມີ'}
						</span>
					</div>
					<div className=' flex gap-2  '>
						{user.position_id.title ?
							<>
								<div className=' w-[50px] '>
									<span>ຕຳແໜ່ງ</span>
								</div>
								<span>
									{user.position_id.title}
								</span>
							</> :
							user.package_id.packageName ?
								<>
									<div className=' w-[50px] '>
										<span>ແພັກເກດ</span>
									</div>
									<span>
										{user.package_id.packageName}
									</span>
								</> :
								<>
									<div className=' w-[50px] '>
										<span>ຕຳແໜ່ງ</span>
									</div>
									<span>
										ບໍ່ມີ
									</span>
								</>

						}

					</div>

					<div className=' flex '>
						<div className=' w-[10rem]'>
							<span>ຄະແນນສ່ວນໂຕ</span>
						</div>
						<span>{PV.myPv.toLocaleString()} PV</span>
					</div>
					<div className=' flex '>
						<div className=' w-[10rem]'>
							<span>ຄະແນນທີມ</span>
						</div>
						<span>{PV.childrenPv.toLocaleString()} PV</span>
					</div>
					<div className=' flex '>
						<div className=' w-[10rem]'>
							<span>ໂບນັດແນະນຳ</span>
						</div>
						<span>{PV.recommended.toLocaleString()} PV</span>
					</div>
					<div className=' flex '>
						<div className=' w-[10rem]'>
							<span>ໂບນັດບໍລິຫານທີມ</span>
						</div>
						<span>{PV.bonusPv.toLocaleString()} PV</span>
					</div>
					<div className=' flex '>
						<div className=' w-[10rem]'>
							<span>ຄະແນນລວມ</span>
						</div>
						<span>{PV.totalPv.toLocaleString()} PV</span>
					</div>
					<div>
						<span className='text-red-500'>ຖ້າຂໍ້ມູນບໍ່ຕົງກັນ ໃຫ້ກົດ Refresh ທາງເທິງ</span>
					</div>
				</div>
			</div>

			{/*  = ============================ table ===================  */}
			{/* <div className=' flex w-full bg-white justify-center rounded-lg shadow-lg  text-black'>
				<div className=' w-[90%] '>
					<div className=' w-full'>
						<table className=' flex flex-col my-4 '>
							<thead>
								<tr key="" className=' w-full h-10'>
									<th className=' border  h-full  items-center  w-[83px] '>    </th>
									<th className=' border  h-full  items-center  w-20'> ທີມງານຊ້າຍ    </th>
									<th className=' border  h-full  items-center  w-20'> ທີມງານຂວາ    </th>
								</tr>
							</thead>
							<tbody>
								<tr key="" className='flex h-8 items-center text-[12px]'>
									<td className=' w-20 h-full  items-center border flex'>Point ຄົງເຫຼືອ</td>
									<td className=' w-20  h-full  items-center  border flex justify-center '>0.00</td>
									<td className=' w-20  h-full  items-center  border flex justify-center '>0.00</td>
								</tr>
								<tr key="" className='flex h-10 text-[12px]'>
									<td className=' w-20  h-full  items-center  border flex '>Point ລາຍວັນ</td>
									<td className=' w-20  h-full  items-center  border flex justify-center '>0.00</td>
									<td className=' w-20  h-full  items-center  border flex justify-center '>0.00</td>
								</tr>
								<tr key="" className='flex h-10 text-[12px]'>
									<td className='  h-full  items-center  w-20 border flex '>Point ລວມ</td>
									<td className=' w-20 border flex justify-center  h-full  items-center  '>0.00</td>
									<td className=' w-20 border flex justify-center  h-full  items-center '>0.00</td>
								</tr>
							</tbody>
						</table>

						<div className=' flex  '>
							<div className=' w-[8rem] '>
								<span>    ມື້ສະໝັກສະມາຊິກ</span>

							</div>
							<span> 11/5/2022 </span>
						</div>
						<div className=' flex my-4 '>
							<div className=' w-[8rem] '>
								<span>    ມື້ສະໝັກສະມາຊິກ</span>

							</div>
							<span> 11/5/2022 </span>
						</div>
					</div>
				</div>
			</div> */}
		</div>
	)
}

export default Member
