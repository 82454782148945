import { React, useState, useEffect } from 'react'
import transfer from "../../assets/transfer.png";
import account from "../../assets/account.svg"
import { IoCall, IoSearch } from 'react-icons/io5'
import wallet from '../../assets/wallet.png'
import cash from '../../assets/cash.png'
import { NavLink, useNavigate } from 'react-router-dom'
import { getMyWallet, getWithdraw,getCCLbonus } from '../../api/ewallet';
import { PulseLoader } from 'react-spinners';
import Swal from 'sweetalert2';
import verify from '../../assets/verify.png';
const AnimationLoading = () => {
	return (
		<PulseLoader
			color="#36d7b7"
			size={10}
			aria-label="Loading Spinner"
			data-testid="loader" />
	)
}

function EwalletMoneyWithdraw() {
	const navigate = useNavigate();
	const [myWallet, setMyWallet] = useState([]);
	const [loaading, setLoading] = useState(false);
	const [loadPay, setLoadPay] = useState(false);
	//const [phone, setPhone] = useState('')
	const [amount, setAmount] = useState();
	const [password, setPassword] = useState('')
	const [errors, setErrors] = useState([]);

	useEffect(() => {
		fetchData();
	}, [])
	async function fetchData() {
		setLoading(true);
		const data1 = await getMyWallet();
		const data2 = await getCCLbonus();
		if (data1) {
			setMyWallet(data1);
		} else {
			Alert()
		}
		setLoading(false);
	}
	const handleSubmit = () => {
		const error = {}
		// if (!phone) {
		// 	error.phone = "ເບີໂທ"
		// }
		// if (phone) {
		// 	if (phone.length < 8 || phone.length > 11) {
		// 		error.phone = "ເບີບໍ່ຖືກຕ້ອງ"
		// 	}
		// }
		if (!amount) {
			error.amount = "ຈຳນວນທີ່ຕ້ອງການໂອນ"
		}
		if(amount < 49000){
			error.amount = "ຖອນຂັ້ນຕ່ຳ 50.000 ກີບ"
		}
		
		if (amount > myWallet.balance) {
			error.amount = "ເງິນບໍ່ພໍ"
		}
		if (!password) {
			error.password = "ລະຫັດກະເປົ່າ"
		}
		setErrors(error);
		if (Object.keys(error).length === 0) {
			Swal.fire({
				title: "ຖອນເງິນ",
				html: `<span>ຈຳນວນທີ່ຕ້ອງການຖອນ ${amount.toLocaleString()} ກີບ</span>`,
				icon: "question",
				showCancelButton: true,
				confirmButtonColor: "#00AEEF",
				confirmButtonText: "ຢືນຢັນ",
				cancelButtonText: "ຍົກເລີກ",
				cancelButtonColor: "#ffffff",
				cancelButtonText: '<span style="color: blue;">ພາຍຫຼັງ</span>',
				reverseButtons: true,
				width:"340px"
			}).then(async (result) => {
				if (result.isConfirmed) {
					setLoadPay(true)
					const x = await getWithdraw(amount, password)
					setLoadPay(false)
					if (x.status) {
						Swal.fire({
							title: "ສຳເລັດ!",
							text: "ທ່ານໄດ້ຖອນເງິນຈຳນວນ: " + amount,
							icon: "success"
						}).then(() => {
							navigate("/ewalletwithdraw")
						});
					} else {
						Swal.fire({
							title: "ຖອນເງິນບໍ່ສຳເລັດ!",
							text: x.message,
							icon: "error"
						});
					}
				} else {
				}
			})
		}
	}
	const Alert = () => {
		return (
			Swal.fire({
				html: '<div class="flex flex-col items-center">' +
					'<img src="' + verify + '" alt="verify" class="w-16 h-16 mb-4" />' +
					'<h2 class="text-lg font-semibold mb-2">ທ່ານຍັງບໍ່ທັນຢືນຢັນຕົວຕົນ</h2>' +
					'<p>ທ່ານຕ້ອງຢືນຢັນຕົວຕົນກ່ອນ ຈຶ່ງຈະສາມາດນຳໃຊ້ຟັງຊັ້ນຕ່າງໆຂອງພວກເຮົາໄດ້</p>' +
					'</div>',
				showCancelButton: true,
				confirmButtonColor: "#00AEEF",
				confirmButtonText: "ຢືນຢັນ",
				cancelButtonText: "ພາຍຫຼັງ",
				cancelButtonColor: "#ffffff",
				cancelButtonText: '<span style="color: blue;">ພາຍຫຼັງ</span>',
				reverseButtons: true,
			}).then((result) => {
				if (result.isConfirmed) {
					navigate("/verifyswall")
				} else {
					navigate("/")
				}
			})
		)
	}
	return (
		<div className='w-full relative'>
			{loadPay &&
				<div className=" fixed pt-10 lg:p-96 inset-0 :bg-black bg-opacity-25 backdrop-blur-sm z-50">
					<PulseLoader
						className=" text-center"
						color="#36d7b7"
						size={30}
						aria-label="Loading Spinner"
						data-testid="loader" />
				</div>
			}
			<div className="w-full mt-20 py-5 shadow-lg border p-2 bg-white rounded-xl lg:mt-16 md:py-8 lg:py-10 lg:px-10">
				<div className='w-full'>
					<div className='flex justify-start items-center gap-3'>
						<span className=''>ເງິນທັງໝົດໃນ wallet</span>
						<span className='font-bold text-blue-light text-2xl lg:text-4xl'>{loaading ? <AnimationLoading /> : myWallet.balance ? myWallet.balance.toLocaleString() + ' ກີບ' : ''}</span>
					</div>
				</div>
				<div className='w-full lg:w-[50%]'>
					<div className=' mt-5'>
						<label>ເລກບັນຊີ</label>
						<div className='w-full p-2 flex items-center gap-3 border border-blue-dark rounded-lg  '>
							<img src={account} alt='' />
							<input type="text" value={loaading ? '...' : myWallet.user_id ? myWallet.user_id.bank.accountNo + ' (' + myWallet.user_id.bank.bankName + ')' : ''} className='w-full text-lg outline-none ' readOnly />
						</div>
					</div>
					<div className=' mt-3 '>
						<label>ຊື່ຜູ້ຮັບ</label>
						<div className='w-full p-2 flex items-center border border-blue-dark rounded-lg  '>
							<div className=' px-3 '>
								<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none ">
									<path d="M9.54361 11.6817C5.99217 12.0027 3.28647 15.0036 3.33361 18.5692V18.75C3.33361 19.4403 3.89326 20 4.58361 20C5.27397 20 5.83361 19.4403 5.83361 18.75V18.5192C5.79604 16.3299 7.41166 14.463 9.58361 14.1858C11.8763 13.9585 13.9192 15.6328 14.1466 17.9255C14.16 18.061 14.1668 18.1971 14.1669 18.3333V18.75C14.1669 19.4403 14.7266 20 15.4169 20C16.1073 20 16.6669 19.4403 16.6669 18.75V18.3333C16.6629 14.6473 13.6714 11.6624 9.98541 11.6665C9.83803 11.6667 9.69068 11.6717 9.54361 11.6817Z" fill="#00AEEF" />
									<path d="M10 10C12.7614 10 15 7.76141 15 5C15 2.23859 12.7614 0 10 0C7.23859 0 5 2.23859 5 5C5.00273 7.76027 7.23973 9.99723 10 10ZM10 2.5C11.3807 2.5 12.5 3.6193 12.5 5C12.5 6.3807 11.3807 7.5 10 7.5C8.6193 7.5 7.5 6.3807 7.5 5C7.5 3.6193 8.6193 2.5 10 2.5Z" fill="#00AEEF" />
								</svg>
							</div>
							<input type="text" className=' w-full text-lg rounded-lg outline-none ' value={loaading ? '...' : myWallet.user_id ? myWallet.user_id.bank.accountName : ''} readOnly />
						</div>
					</div>
					<div className='mt-2'>
						<label>ຈຳນວນທີ່ຕ້ອງການຖອນ ຂັ້ນຕ່ຳ 50.000 ກີບ</label>
						<div className='w-full p-2 flex items-center border border-blue-dark rounded-lg relative'>
							<span className=' absolute right-5'>LAK</span>
							<div className=' px-3 '>
								<img src={cash} alt="" />
							</div>
							<input type="number" min={0} placeholder='LAK' defaultValue={amount} onChange={(e) => { setAmount(e.target.value) }} className=' w-full text-lg rounded-lg outline-none' />
						</div>
						{errors.amount && <span className='pt-1 text-red-500'>{errors.amount}</span>}
					</div>
					<div className='mt-2'>
						<label>ລະຫັດກະເປົ່າ </label>
						<div className='w-full p-2 flex items-center border border-blue-dark rounded-lg  '>
							<div className=' px-3 '>
								<img src={wallet} alt="" />
							</div>
							<input type="password" value={password} onChange={(e) => setPassword(e.target.value)} className=' w-full text-lg rounded-lg outline-none' />
						</div>
						{errors.password && <span className='pt-1 text-red-500'>{errors.password}</span>}
					</div>
					<div className='pt-5 flex justify-around gap-4'>
						<NavLink to="/ewalletwithdraw" className='w-full py-3 bg-gradient-to-t from-gray-500 to-gray-200 text-white text-center rounded-lg font-bold text-lg '> ຍົກເລີກ </NavLink>
						<button onClick={handleSubmit} className='w-full py-3 bg-gradient-to-t from-blue-dark to-blue-light font-bold text-lg  text-white rounded-lg '> ຖອນເງິນ</button>
					</div>
				</div>
			</div>
		</div>
	)
}

export default EwalletMoneyWithdraw
