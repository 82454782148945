export function stringToASCII(data) {
    let asciiArray = [];
    for (let i = 0; i < data.length; i++) {
        const asciiCode = data.charCodeAt(i);
        asciiArray.push(asciiCode);
    }
    return asciiArray;
}
export function asciiToString(asciiArray) {
    let string = "";
    for (let i = 0; i < asciiArray.length; i++) {
        const character = String.fromCharCode(asciiArray[i]);
        string += character;
    }
    return string;
}