import { apiUrl } from './apiconfig';
import axios from "axios";
export const searchUserCode = async (userCode) => {
    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: apiUrl + '/user/getone/usercode/' + userCode,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token()
        }
    };
    try {
        const response = await axios.request(config);
        if (response.data.status) {
            //console.log(response.data.data)
            return response.data.data
        } else {
            return false;
        }
    } catch (error) {
        console.error(error);
        return false;
    }
}
export const getMyOrder = async () => {
    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: apiUrl + '/order/getall/myorder?page=1',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token()
        }
    };
    try {
        const response = await axios.request(config);
        if (response.data.status) {
            //console.log(response.data.data)
            return response.data.data
        } else {
            return false;
        }
    } catch (error) {
        console.error(error);
        return false;
    }
}
export const getMyPackageOrder = async () => {
    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: apiUrl + '/package/order/me?status=unapproved&page=1',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token()
        }
    };
    try {
        const response = await axios.request(config);
        if (response.data.status) {
            //console.log(response.data.data)
            return response.data.data
        } else {
            return false;
        }
    } catch (error) {
        console.error(error);
        return false;
    }
}
export const findOrderByID = async (id) => {
    const orders = await getMyOrder();
    var status = false
    for (const i of orders) {
        if (i._id === id) {
            status = true
            return i
        }
    }
    if (!status) {
        return false;
    }

}
export const createOrderWithBCELone = async (items, orderFor, isShip, phoneNumber, slip, express, branch, address, HFAddress, HFVillage, HFDistrict, HFProvince) => {
    var data = new FormData();
    const cartItemID = []
    for (const i of items) {
        data.append('cartItemID', i)
    }
    data.append('paymentType', 'transfer');
    data.append('orderFor', orderFor);
    if (isShip) {
        data.append('delivery', 'ຈັດສົ່ງ');
        data.append('express', express);
        data.append('province', branch);
        data.append('address', address);
    } else {
        data.append('delivery', 'ມາຮັບຢູ່ສາຂາ');
        data.append('phoneNumber', phoneNumber)
        data.append('express', express);
        data.append('address', HFAddress);
        data.append('village', HFVillage);
        data.append('district', HFDistrict);
        data.append('province', HFProvince);

    }
    data.append('slip', slip);


    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: apiUrl + '/order/create',
        headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + token()
        },
        data: data
    };
    try {
        const response = await axios.request(config);
        //console.log(response)
        if (response.data.status) {
            //console.log(response.data.data)
            return response.data.data
        } else {
            return false;
        }
    } catch (error) {
        console.error(error);
        return false;
    }
}
export const createOrderWithEwallet = async (items, orderFor, isShip, phoneNumber, slip, express, branch, address, HFAddress, HFVillage, HFDistrict, HFProvince, password) => {
    var data = new FormData();
    const cartItemID = []
    for (const i of items) {
        data.append('cartItemID', i)
    }
    data.append('paymentType', 'wallet');
    if (orderFor) {
        data.append('orderFor', 'orderFor');
    }
    if (isShip) {
        data.append('delivery', 'ຈັດສົ່ງ');
        data.append('express', express);
        data.append('province', branch);
        data.append('address', address);
    } else {
        data.append('delivery', 'ມາຮັບຢູ່ສາຂາ');
        data.append('phoneNumber', phoneNumber)
        data.append('express', express);
        data.append('address', HFAddress);
        data.append('village', HFVillage);
        data.append('district', HFDistrict);
        data.append('province', HFProvince);
    }
    data.append('password', password)


    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: apiUrl + '/order/create',
        headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + token()
        },
        data: data
    };
    try {
        const response = await axios.request(config);
        //console.log(response)
        if (response.data.status) {
            //console.log(response.data.data)
            return response.data.data
        } else {
            return false;
        }
    } catch (error) {
        console.error(error);
        return error.response.data.message;
    }
}

export const getMyHistorySale = async () => {
    const orderPadding = []
    const orders = await getMyOrder();
    if (orders) {
        for (const i of orders) {
            if (i.status === "success") {
                orderPadding.push(i)
            }
        }
    }
    return orderPadding;
}

function token() {
    return localStorage.getItem("token")
};