import React, { useState } from "react";

//call api
import { addMember } from "../../api/auth";

//......................
import { successSwal, errorSwal } from "../../helpers/sweetalert";
import { PulseLoader } from "react-spinners";
const AddTeam = () => {
    const [userCode, setUserCode] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [dateBrith, setDateBirth] = useState("");
    const [loading, setLaoding] = useState(false);
    const [errors, setErrors] = useState({});

    const handleSubmit = async () => {
        const error = {}
        if (!userCode) {
            error.userCode = "ID";
        } if (!firstName) {
            error.firstName = "ຊື່";
        }
        if (!lastName) {
            error.lastName = "ນາມສະກຸນ";
        }
        if (!dateBrith) {
            error.dateBrith = "ວ/ດ/ປ ເກີດ"
        }
        setErrors(error);

        if (Object.keys(error).length === 0) {
            setLaoding(true);
            const result = await addMember(userCode, firstName, lastName, dateBrith);
            const x = result.response.data;
            console.log(x)
            if (x.status) {
                setLaoding(false);
                successSwal("ເພີ່ມສຳເລັດ")
            } else {
                setLaoding(false);
                if (x.message === 'invalid: userCode') {
                    errorSwal("ບໍ່ມີ ID ສະມາຊິກນີ້")
                } else {
                    errorSwal(x.message)
                }
            }
        }


    }
    return (
        <div className="w-full ">
            {loading &&
                <div className=" fixed pt-10 lg:p-96 inset-0 :bg-black bg-opacity-25 backdrop-blur-sm z-50">
                    <PulseLoader
                        className=" text-center"
                        color="#36d7b7"
                        size={30}
                        aria-label="Loading Spinner"
                        data-testid="loader" />
                </div>
            }
            <div className="w-full mt-20 lg:mt-16 py-5 shadow-lg border p-2 bg-white rounded-xl md:py-8 lg:py-10 lg:px-10">
                <div className='grid grid-cols-1 lg:grid-cols-2 gap-10'>
                    <div>
                        <div>
                            <h1 className='pb-2 text-sm lg:text-xl text-slate-400 border-b-2 border-slate-300'>ຂໍ້ມູນສະມາຊິກ</h1>
                        </div>
                        <div className='mt-5 flex flex-col gap-4'>
                            <div className='w-full lg:flex justify-start items-center gap-4'>
                                <label className=' w-[20%] lg:flex justify-end items-end'>ID</label>
                                <div className="w-full">
                                    <input
                                        type='text'
                                        className={`w-full border-2 p-2 rounded-lg ${errors.userCode && 'border border-red-500'}`}
                                        placeholder='User ID'
                                        value={userCode}
                                        onChange={e => setUserCode(e.target.value)}
                                    />
                                    <span className="text-red-500">{errors.userCode && errors.userCode}</span>
                                </div>
                            </div>
                            <div className='w-full lg:flex justify-start items-center gap-4'>
                                <label className=' w-[20%] lg:flex justify-end'>ຊື່</label>
                                <div className="w-full">
                                    <input
                                        type='text'
                                        className={`w-full border-2 p-2 rounded-lg ${errors.firstName && 'border border-red-500'}`}
                                        placeholder='First Name'
                                        value={firstName}
                                        onChange={e => setFirstName(e.target.value)}
                                    />
                                    <span className="text-red-500">{errors.firstName && errors.firstName}</span>
                                </div>
                            </div>
                            <div className='w-full lg:flex justify-start items-center gap-4'>
                                <label className=' w-[20%] lg:flex justify-end'>ນາມສະກຸນ</label>
                                <div className="w-full">
                                    <input
                                        type='text'
                                        className={`w-full border-2 p-2 rounded-lg ${errors.lastName && 'border border-red-500'}`}
                                        placeholder='Last Name'
                                        value={lastName}
                                        onChange={e => setLastName(e.target.value)}
                                    />
                                    <span className="text-red-500">{errors.lastName && errors.lastName}</span>
                                </div>
                            </div>
                            <div className='w-full lg:flex justify-start items-center gap-4'>
                                <label className=' w-[20%] lg:flex justify-end'>ວ/ດ/ປ ເກີດ</label>
                                <div className="w-full">
                                    <input
                                        type='date'
                                        className={`w-full border-2 p-2 rounded-lg ${errors.dateBrith && 'border border-red-500'}`}
                                        placeholder='Last Name'
                                        value={dateBrith}
                                        onChange={e => setDateBirth(e.target.value)}
                                    />
                                    <span className="text-red-500">{errors.dateBrith && errors.dateBrith}</span>
                                </div>

                            </div>
                        </div>
                        <div className="w-full lg:flex justify-center pb-10">
                            <button onClick={handleSubmit} className="w-full lg:w-[50%] py-2 mt-5 lg:text-xl bg-blue-600 text-white rounded-xl">ສະໝັກສະມາຊິກ</button>
                        </div>
                    </div>
                </div>

            </div >
        </div >
    )
}

export default AddTeam