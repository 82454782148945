import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { getTrips } from '../../api/trip'
import { PulseLoader } from 'react-spinners'
import moment from 'moment'
function Travel() {
	const [tripNow, setTripNow] = useState([]);
	const [tripSucs, setTripSucs] = useState([]);
	const [loaading, setLoading] = useState(false);
	const [isNowTrip, setIsNowTrip] = useState(true);
	useEffect(() => {
		fetchdata();
	}, [])
	async function fetchdata() {
		setLoading(true);
		const data = await getTrips();
		if (data) {
			const temp1 = [];
			const temp2 = [];
			for (const i of data) {
				if (i.isSuccess) {
					temp1.push(i);
				} else {
					temp2.push(i)
				}
			}
			setTripSucs(temp1);
			setTripNow(temp2)
			setIsNowTrip(true);

		}
		setLoading(false);
	}
	const AnimationLoading = () => {
		return (
			<PulseLoader
				color="#36d7b7"
				size={10}
				aria-label="Loading Spinner"
				data-testid="loader" />
		)
	}
	function dateFormat(dateString) {
		const dateObject = moment(dateString);
		return dateObject.format("DD-MM-YYYY");
	}
	const TripSucs = () => {

	}
	const TripNow = () => {

	}
	console.log(tripNow);
	console.log(tripSucs);
	return (
		<div className='w-full'>
			<div className="w-full mt-20 py-5 shadow-lg border p-2 bg-white rounded-xl lg:mt-16 md:py-8 lg:py-10 lg:px-10">
				<div className='flex justify-end gap-3'>
					<button onClick={() => { setIsNowTrip(true) }} className={`px-2 border-2 border-purple-500 rounded-lg ${isNowTrip ? ' bg-blue-300' : ''}`}>ທິບທ່ອງທຽ່ວ ປັດຈຸບັນ</button>
					<button onClick={() => { setIsNowTrip(false) }} className={`px-2 border-2 border-purple-500 rounded-lg ${!isNowTrip && ' bg-blue-300'}`}>ປະຫວັດທ່ອງທ່ຽວ</button>
				</div>
				{isNowTrip ?
					<>
						<div className=''>
							<h1 className='mt-5 text-4xl text-blue-light font-bold text-center'>ທິບທ່ອງທຽ່ວ</h1>
						</div>
						<div className=' w-full mt-5 grid grid-cols-2 mg:grid-cols-3 lg:grid-cols-4 gap-3'>
							{loaading ?
								<AnimationLoading /> :
								tripNow.length > 0 && tripNow.map((trip) => {
									return (
										<NavLink to={"/traveldetail/" + trip._id} className="w-full flex justify-center">
											<div className=''>
												<div className=' absolute  bg-white rounded-full flex items-center justify-center mt-4 ml-4  '>
													<span className=' px-2 font-medium '>{dateFormat(trip.departureDate)}
													</span>
												</div>
												<img src={process.env.REACT_APP_IMGURL + trip.cover}
													className='rounded-lg'
													alt="" />
												<div className=' absolute ml-4 mt-[-4rem] text-white flex-col flex font-bold  '>
													{/* <span className='  font-bold text-lg  '>{trip[0].placeName}</span> */}
													<span>{trip.placeName}</span>
												</div>
											</div>
										</NavLink>
									)
								})
							}
						</div>
					</>
					:
					<>
						<div className=''>
							<h1 className='mt-5 text-4xl text-blue-light font-bold text-center'>ປະຫວັດທ່ອງທ່ຽວ</h1>
						</div>
						<div className=' w-full mt-5 grid grid-cols-2 mg:grid-cols-3 lg:grid-cols-4 gap-3'>
							{loaading ?
								<AnimationLoading /> :
								tripSucs.map((trip) => {
									return (
										<NavLink to={"/traveldetail/" + trip._id} className="w-full flex justify-center">
											<div className=''>
												<div className=' absolute  bg-white rounded-full flex items-center justify-center mt-4 ml-4  '>
													<span className=' px-2 font-medium '>{dateFormat(trip.departureDate)}
													</span>
												</div>
												<img src={process.env.REACT_APP_IMGURL+trip.cover}
													className='rounded-lg'
													alt="" />
												<div className=' absolute ml-4 mt-[-4rem] text-white flex-col flex font-bold  '>
													{/* <span className='  font-bold text-lg  '>{trip[0].placeName}</span> */}
													<span>{trip.placeName}</span>
												</div>
											</div>
										</NavLink>
									)
								})
							}
						</div>

					</>
				}
			</div>
		</div>
	)
}

export default Travel
