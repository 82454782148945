import React, { useEffect, useState } from "react";
import withdraw from "../../assets/withdraw.png";
import transfer from "../../assets/transfer.png";
import linechartpurple from "../../assets/linechartpurple.jpeg";
import linechart from "../../assets/linchart.jpeg";
import lineChartGreen from "../../assets/linechartgreen.jpeg"
import { IoSearch } from "react-icons/io5";
import { IoIosSettings } from "react-icons/io";
import { NavLink, useNavigate } from "react-router-dom";
import { getMyWallet, getTransaction, getCCLbonus, changeWalletPass } from "../../api/ewallet";
import PulseLoader from "react-spinners/PulseLoader";
import moment from 'moment';
import Swal from "sweetalert2";
import verify from '../../assets/verify.png';


function EwalletWithdraw() {
	const navigate = useNavigate();
	const [myWallet, setMyWallet] = useState([]);
	const [transaction, setTransaction] = useState([]);
	const [loaading, setLoading] = useState(false);
	const [loadingChangPass, setLaodingChangPass] = useState(false)
	const [cclbonus, setCCLbonus] = useState([]);
	useEffect(() => {
		fetchData();
	}, [])
	async function fetchData() {
		setLoading(true);
		const data1 = await getMyWallet();
		const data2 = await getCCLbonus();
		if (data1) {
			const data3 = await getTransaction();
			setMyWallet(data1);
			setCCLbonus(data2);
			setTransaction(data3);

		} else {
			Alert()
		}
		setLoading(false);

	}
	const AnimationLoading = () => {
		return (
			<PulseLoader
				color="#36d7b7"
				size={10}
				aria-label="Loading Spinner"
				data-testid="loader" />
		)
	}
	const Alert = () => {
		return (
			Swal.fire({
				html: '<div class="flex flex-col items-center">' +
					'<img src="' + verify + '" alt="verify" class="w-16 h-16 mb-4" />' +
					'<h2 class="text-lg font-semibold mb-2">ທ່ານຍັງບໍ່ທັນຢືນຢັນຕົວຕົນ</h2>' +
					'<p>ທ່ານຕ້ອງຢືນຢັນຕົວຕົນກ່ອນ ຈຶ່ງຈະສາມາດນຳໃຊ້ຟັງຊັ້ນຕ່າງໆຂອງພວກເຮົາໄດ້</p>' +
					'</div>',
				showCancelButton: true,
				confirmButtonColor: "#00AEEF",
				confirmButtonText: "ຢືນຢັນ",
				cancelButtonText: "ພາຍຫຼັງ",
				cancelButtonColor: "#ffffff",
				cancelButtonText: '<span style="color: blue;">ພາຍຫຼັງ</span>',
				reverseButtons: true,
				width:"340px"
			}).then((result) => {
				if (result.isConfirmed) {
					navigate("/verifyswall")
				} else {
					navigate("/")
				}
			})
		)
	}
	const handdleSetting = async () => {
		const { value: formValues } = await Swal.fire({
			title: "ປ່ຽນລະຫັດ E-wallet",
			html: `
			  <input id="swal-input1" class="swal2-input" Placeholder="ລະຫັດເກົ່າ">
			  <input id="swal-input2" class="swal2-input" Placeholder="ລະຫັດໃໝ່">
			  <input id="swal-input3" class="swal2-input" Placeholder="ຢືນຢັນລະຫັດໃໝ່">
			`,
			allowOutsideClick: false,
			allowEscapeKey: false,
			focusConfirm: false,
			showCancelButton: true,
			cancelButtonText: "ຍົກເລີກ",
			confirmButtonText: "ປ່ຽນ",
			preConfirm: () => {
				return {
					"odlPass": document.getElementById("swal-input1").value,
					"newPass": document.getElementById("swal-input2").value,
					"conPass": document.getElementById("swal-input3").value,

				};
			},
			width:"340px"
		});
		if (formValues) {
			//console.log(formValues)
			if (!formValues.odlPass) {
				Swal.fire({
					title: "ໃສ່ລະຫັດຜ່ານເກົ່າ",
					icon: "error",
					width:"340px"
				});
			} else if (!formValues.newPass) {
				Swal.fire({
					title: "ໃສ່ລະຫັດຜ່ານໃໝ່",
					icon: "error",
					width:"340px"
				});
			} else if (!formValues.conPass) {
				Swal.fire({
					title: "ຢືນຢັນລະຫັດໃໝ່",
					icon: "error",
					width:"340px"
				});
			} else if (formValues.newPass !== formValues.conPass) {
				Swal.fire({
					title: "ລະຫັດໃໝ່ບໍ່ຕົງກັນ",
					icon: "error",
					width:"340px"
				});
			} else {
				setLaodingChangPass(true);
				const x = await changeWalletPass(formValues.odlPass, formValues.conPass);
				if (x.status) {
					setLaodingChangPass(false);
					Swal.fire({
						title: x.message,
						icon: "success",
						width:"340px"
					});
				} else {
					setLaodingChangPass(false);
					Swal.fire({
						title: x.message,
						icon: "error",
						width:"340px"
					});
				}
			}

		}
	}
	return (
		< div className="w-full" >
			{loadingChangPass &&
				<div className=" fixed pt-10 lg:p-96 inset-0 :bg-black bg-opacity-25 backdrop-blur-sm z-50">
					<PulseLoader
						className=" text-center"
						color="#36d7b7"
						size={30}
						aria-label="Loading Spinner"
						data-testid="loader" />
				</div>
			}
			<div className="w-full mt-20 lg:mt-16 py-5 shadow-lg border p-2 bg-white rounded-xl md:py-8 lg:py-10 lg:px-10">
				<div className="mb-2 flex justify-end ">
					<span onClick={handdleSetting} className="p-2 flex bg-white border rounded-lg"><IoIosSettings size={25} /> Setting</span>
				</div>
				<div className="w-full grid grid-cols-2 lg:grid-cols-4 gap-3 lg:gap-5">
					<div className=" w-full bg-white border rounded-lg shadow-lg">
						<div className=" flex items-center  justify-between ">
							<div className=" flex items-center gap-4 ">
								<div className="  ml-3 bg-purple-200 my-2 border rounded-full h-12 flex items-center justify-center w-12">
									<svg xmlns="http://www.w3.org/2000/svg" width="25" height="21" viewBox="0 0 25 21" fill="none">
										<path d="M0 17.6391C0 18.5241 0.32924 19.3729 0.915291 19.9987C1.50134 20.6245 2.2962 20.9761 3.125 20.9761H21.875C22.7038 20.9761 23.4987 20.6245 24.0847 19.9987C24.6708 19.3729 25 18.5241 25 17.6391V8.46237H0V17.6391ZM3.68304 13.1103C3.68304 12.6362 3.85941 12.1815 4.17337 11.8463C4.48733 11.511 4.91314 11.3227 5.35714 11.3227H8.03571C8.47972 11.3227 8.90553 11.511 9.21949 11.8463C9.53344 12.1815 9.70982 12.6362 9.70982 13.1103V14.3021C9.70982 14.7762 9.53344 15.2309 9.21949 15.5662C8.90553 15.9015 8.47972 16.0898 8.03571 16.0898H5.35714C4.91314 16.0898 4.48733 15.9015 4.17337 15.5662C3.85941 15.2309 3.68304 14.7762 3.68304 14.3021V13.1103Z" fill="#9747FF" />
										<path d="M21.875 0H3.125C2.2962 0 1.50134 0.351576 0.915291 0.977384C0.32924 1.60319 0 2.45197 0 3.337V4.88632H25V3.337C25 2.45197 24.6708 1.60319 24.0847 0.977384C23.4987 0.351576 22.7038 0 21.875 0Z" fill="#9747FF" />
									</svg>
								</div>
								<h1 className="text-sm lg:text-xl font-medium">
									ໄດ້ຮັບເງິນຄືນ
								</h1>
							</div>
						</div>
						<div className="flex justify-center  ">
							<img src={linechartpurple}
								alt=""
								className="w-full " />
						</div>
						<div className="flex w-full items-center justify-center font-bold text-purple-600 text-xl ">
							<h1>
								{loaading ? <AnimationLoading /> : cclbonus && cclbonus.BonusDetail ? cclbonus.BonusDetail.cashback.toLocaleString() : 0}
							</h1>
						</div>
					</div>
					<div className=" w-full  bg-white border rounded-lg shadow-lg">
						<div className=" flex items-center  justify-between ">
							<div className=" flex items-center gap-1 ">
								<div className=" px-4 my-2">
									<svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
										<path opacity="0.2" d="M47.28 29.8062C44.0745 42.6634 31.0515 50.4874 18.1927 47.2819C5.33999 44.0765 -2.48476 31.0534 0.721486 18.1969C3.92549 5.3382 16.9477 -2.4873 29.8027 0.718197C42.6607 3.9237 50.4847 16.9482 47.2792 29.8062H47.28Z" fill="green" fill-opacity="0.6" />
										<path fill-rule="evenodd" clip-rule="evenodd" d="M31.2281 21.2118C31.5517 19.0453 29.9022 17.8807 27.6468 17.1038L28.3784 14.1691L26.5915 13.7239L25.8792 16.5813C25.4101 16.4643 24.9278 16.3538 24.4485 16.2445L25.1659 13.3683L23.3806 12.9231L22.6484 15.8568C22.2597 15.7682 21.8781 15.6807 21.5077 15.5886L21.5098 15.5795L19.0462 14.9643L18.571 16.8723C18.571 16.8723 19.8964 17.176 19.8684 17.1949C20.5919 17.3755 20.7232 17.8543 20.7008 18.2338L19.8674 21.5771C19.9172 21.5898 19.9819 21.6081 20.0531 21.6366L19.8648 21.5898L18.6962 26.2732C18.6076 26.493 18.3832 26.8227 17.8775 26.6975C17.8953 26.7235 16.5791 26.3734 16.5791 26.3734L15.6923 28.4188L18.0174 28.9983C18.2799 29.0641 18.5392 29.1317 18.7957 29.1986L18.7959 29.1987C18.9619 29.242 19.1267 29.285 19.2904 29.327L18.5511 32.2957L20.3355 32.7409L21.0681 29.8042C21.555 29.9365 22.0282 30.0586 22.4912 30.1736L21.7616 33.0966L23.548 33.5418L24.2872 30.5791C27.3334 31.1555 29.6244 30.923 30.5876 28.1684C31.3645 25.9501 30.5494 24.6705 28.9467 23.8356C30.1139 23.5654 30.9931 22.7977 31.2276 21.2118H31.2281ZM27.1461 26.9351C26.639 28.9711 23.4514 28.1287 21.9943 27.7436L21.9942 27.7436C21.8637 27.7091 21.7472 27.6783 21.6476 27.6535L22.6286 23.7211C22.7503 23.7515 22.899 23.7849 23.0674 23.8226C24.5749 24.1609 27.6642 24.8542 27.1466 26.9351H27.1461ZM23.3704 21.9978C24.5862 22.3222 27.2365 23.0293 27.6982 21.1797C28.1702 19.2876 25.5931 18.7173 24.3345 18.4387C24.193 18.4074 24.0682 18.3798 23.9662 18.3544L23.0768 21.921C23.161 21.942 23.2598 21.9683 23.3704 21.9978Z" fill="green" fill-opacity="0.7" />
									</svg>
								</div>
								<h1 className="text-sm lg:text-xl font-medium">
									ໂບນັດແນະນຳ
								</h1>
							</div>
						</div>
						<div className="flex justify-center  ">
							<img src={lineChartGreen}
								alt=""
								className="w-full" />
						</div>
						<div className="flex w-full items-center justify-center font-bold text-green-600 text-xl ">
							<h1>
								{loaading ? <AnimationLoading /> : cclbonus && cclbonus.BonusDetail ? cclbonus.BonusDetail.recommended.toLocaleString() : 0}
							</h1>
						</div>
					</div>
					<div className=" w-full  bg-white border rounded-lg shadow-lg">
						<div className=" flex items-center  justify-between ">
							<div className=" flex items-center gap-1 ">
								<div className=" px-4 my-2">
									<svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
										<path opacity="0.2" d="M47.28 29.8062C44.0745 42.6634 31.0515 50.4874 18.1927 47.2819C5.33999 44.0765 -2.48476 31.0534 0.721486 18.1969C3.92549 5.3382 16.9477 -2.4873 29.8027 0.718197C42.6607 3.9237 50.4847 16.9482 47.2792 29.8062H47.28Z" fill="#F7931A" fill-opacity="0.6" />
										<path fill-rule="evenodd" clip-rule="evenodd" d="M31.2281 21.2118C31.5517 19.0453 29.9022 17.8807 27.6468 17.1038L28.3784 14.1691L26.5915 13.7239L25.8792 16.5813C25.4101 16.4643 24.9278 16.3538 24.4485 16.2445L25.1659 13.3683L23.3806 12.9231L22.6484 15.8568C22.2597 15.7682 21.8781 15.6807 21.5077 15.5886L21.5098 15.5795L19.0462 14.9643L18.571 16.8723C18.571 16.8723 19.8964 17.176 19.8684 17.1949C20.5919 17.3755 20.7232 17.8543 20.7008 18.2338L19.8674 21.5771C19.9172 21.5898 19.9819 21.6081 20.0531 21.6366L19.8648 21.5898L18.6962 26.2732C18.6076 26.493 18.3832 26.8227 17.8775 26.6975C17.8953 26.7235 16.5791 26.3734 16.5791 26.3734L15.6923 28.4188L18.0174 28.9983C18.2799 29.0641 18.5392 29.1317 18.7957 29.1986L18.7959 29.1987C18.9619 29.242 19.1267 29.285 19.2904 29.327L18.5511 32.2957L20.3355 32.7409L21.0681 29.8042C21.555 29.9365 22.0282 30.0586 22.4912 30.1736L21.7616 33.0966L23.548 33.5418L24.2872 30.5791C27.3334 31.1555 29.6244 30.923 30.5876 28.1684C31.3645 25.9501 30.5494 24.6705 28.9467 23.8356C30.1139 23.5654 30.9931 22.7977 31.2276 21.2118H31.2281ZM27.1461 26.9351C26.639 28.9711 23.4514 28.1287 21.9943 27.7436L21.9942 27.7436C21.8637 27.7091 21.7472 27.6783 21.6476 27.6535L22.6286 23.7211C22.7503 23.7515 22.899 23.7849 23.0674 23.8226C24.5749 24.1609 27.6642 24.8542 27.1466 26.9351H27.1461ZM23.3704 21.9978C24.5862 22.3222 27.2365 23.0293 27.6982 21.1797C28.1702 19.2876 25.5931 18.7173 24.3345 18.4387C24.193 18.4074 24.0682 18.3798 23.9662 18.3544L23.0768 21.921C23.161 21.942 23.2598 21.9683 23.3704 21.9978Z" fill="#F7931A" fill-opacity="0.7" />
									</svg>
								</div>
								<h1 className="text-sm lg:text-xl font-medium">
									ໂບນັດບໍລິຫານທີມ
								</h1>
							</div>
						</div>
						<div className="flex justify-center  ">
							<img src={linechart}
								alt=""
								className="w-full" />
						</div>
						<div className="flex w-full items-center justify-center font-bold text-orange-600 text-xl ">
							<h1>
								{loaading ? <AnimationLoading /> : cclbonus && cclbonus.BonusDetail && cclbonus.BonusDetail.bonusTeamePV ? cclbonus.BonusDetail.bonusTeamePV.toLocaleString() : '0' }
							</h1>
						</div>
					</div>
					<div className=" w-full  bg-white border rounded-lg shadow-lg">
						<div className=" flex items-center  justify-between ">
							<div className=" flex items-center gap-1 ">
								<div className=" px-4 my-2">
									<svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
										<path opacity="0.2" d="M47.28 29.8062C44.0745 42.6634 31.0515 50.4874 18.1927 47.2819C5.33999 44.0765 -2.48476 31.0534 0.721486 18.1969C3.92549 5.3382 16.9477 -2.4873 29.8027 0.718197C42.6607 3.9237 50.4847 16.9482 47.2792 29.8062H47.28Z" fill="#F7931A" fill-opacity="0.6" />
										<path fill-rule="evenodd" clip-rule="evenodd" d="M31.2281 21.2118C31.5517 19.0453 29.9022 17.8807 27.6468 17.1038L28.3784 14.1691L26.5915 13.7239L25.8792 16.5813C25.4101 16.4643 24.9278 16.3538 24.4485 16.2445L25.1659 13.3683L23.3806 12.9231L22.6484 15.8568C22.2597 15.7682 21.8781 15.6807 21.5077 15.5886L21.5098 15.5795L19.0462 14.9643L18.571 16.8723C18.571 16.8723 19.8964 17.176 19.8684 17.1949C20.5919 17.3755 20.7232 17.8543 20.7008 18.2338L19.8674 21.5771C19.9172 21.5898 19.9819 21.6081 20.0531 21.6366L19.8648 21.5898L18.6962 26.2732C18.6076 26.493 18.3832 26.8227 17.8775 26.6975C17.8953 26.7235 16.5791 26.3734 16.5791 26.3734L15.6923 28.4188L18.0174 28.9983C18.2799 29.0641 18.5392 29.1317 18.7957 29.1986L18.7959 29.1987C18.9619 29.242 19.1267 29.285 19.2904 29.327L18.5511 32.2957L20.3355 32.7409L21.0681 29.8042C21.555 29.9365 22.0282 30.0586 22.4912 30.1736L21.7616 33.0966L23.548 33.5418L24.2872 30.5791C27.3334 31.1555 29.6244 30.923 30.5876 28.1684C31.3645 25.9501 30.5494 24.6705 28.9467 23.8356C30.1139 23.5654 30.9931 22.7977 31.2276 21.2118H31.2281ZM27.1461 26.9351C26.639 28.9711 23.4514 28.1287 21.9943 27.7436L21.9942 27.7436C21.8637 27.7091 21.7472 27.6783 21.6476 27.6535L22.6286 23.7211C22.7503 23.7515 22.899 23.7849 23.0674 23.8226C24.5749 24.1609 27.6642 24.8542 27.1466 26.9351H27.1461ZM23.3704 21.9978C24.5862 22.3222 27.2365 23.0293 27.6982 21.1797C28.1702 19.2876 25.5931 18.7173 24.3345 18.4387C24.193 18.4074 24.0682 18.3798 23.9662 18.3544L23.0768 21.921C23.161 21.942 23.2598 21.9683 23.3704 21.9978Z" fill="#F7931A" fill-opacity="0.7" />
									</svg>
								</div>
								<h1 className=" text-sm lg:text-xl font-medium">
									ໂບນັດຂື້ນຕ່ຳແຫນ່ງ
								</h1>
							</div>
						</div>
						<div className="flex justify-center  ">
							<img src={linechart}
								alt=""
								className="w-full" />
						</div>
						<div className="flex w-full items-center justify-center font-bold text-orange-600 text-xl ">
							<h1>
								{loaading ? <AnimationLoading /> : cclbonus && cclbonus.BonusDetail ? cclbonus.BonusDetail.bonus_postition.toLocaleString() : 0}
							</h1>
						</div>
					</div>

				</div>
			</div>
			<div className="w-full mt-5 py-5 shadow-lg border p-2 bg-white rounded-xl md:mt-5 lg:mt-5 md:py-8 lg:py-10 lg:px-10">
				<div><h1 className="text-xl md:text-2xl lg:text-2xl font-bold ">ປະຫວັດການໂອນ</h1></div>
				<div className="w-full h-[500px] overflow-auto rounded-lg ">
					<table className="w-[800px] lg:w-full table-auto">
						<thead className="w-full rounded-t-xl bg-gradient-to-t from-blue-dark to-blue-light text-white sticky top-0">
							<tr className="">
								<th className="p-3">
									ວັນທີ່
								</th>
								<th className="">
									ລາຍລະອຽດ
								</th>
								<th className=" ">
									ຈຳນວນ
								</th>
								<th className="">
									ໝາຍເຫດ
								</th>
							</tr>
						</thead>
						{loaading ? <AnimationLoading /> : !transaction ? ' ' : transaction.map((element) => {

							const dateString = element.createdAt;
							var formattedDate
							if (dateString) {
								const dateObject = moment(dateString);
								formattedDate = dateObject.format("YYYY-MM-DD");
							}
							function ShwoSlip() {
								Swal.fire({
									imageUrl: element.slip,
									imageHeight: 500,
									imageAlt: "slip transaction",
									width:"340px"
								});
							}
							return (
								<tr onClick={() => ShwoSlip()} className="border border-slate-300">
									<td className="p-2 text-center">
										{formattedDate}
									</td>
									<td className="text-center">
										{element.description}
									</td>
									<td className="text-center">
										{element.amount.toLocaleString() + " ກີບ"}
									</td>

									<td className="">
										{element.status}
									</td>
								</tr>
							)
						})}
					</table>
				</div>
			</div>
		</div >
	)
}
export default EwalletWithdraw;
