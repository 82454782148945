import { apiUrl } from './apiconfig';
import axios from "axios";
import qs from "qs"
import { getPosition } from './linework';
import { stringToASCII } from '../serviec/hashpassword';

export const verifyDocument = async (docNo, docName, bankName, accountName, accountNo, docImage, bankImage) => {
    let data = new FormData();
    data.append('docNo', docNo);
    data.append('docName', docName);
    data.append('bankName', bankName);
    data.append('accountName', accountName);
    data.append('accountNo', accountNo);
    data.append('docImage', docImage);
    data.append('bankImage', bankImage);
    console.log(docName);
    let config = {
        method: 'put',
        maxBodyLength: Infinity,
        url: apiUrl + '/user/upsert/doc',
        headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + token(),
        },
        data: data
    };
    try {
        const response = await axios.request(config);
        console.log(response);
        return response.data;
    } catch (error) {
        console.log(error.response.data)
        return error.response.data;
    }
}
export const upProfile = async (avartar) => {
    let data = new FormData();
    data.append('image', avartar);
    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: apiUrl + '/user/upprofile',
        headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + token(),
        },
        data: data
    };
    try {
        const response = await axios.request(config);
        return response.data
    } catch (error) {
        console.log(error.response.data)
        return error.response.data;
    }
}
export const addMember = async (userCode, fname, lname, datebirth) => {
    const qs = require('qs');
    let data = JSON.stringify({
        'userCode': userCode,
        'firstName': fname,
        'lastName': lname,
        'birthDay': datebirth
    });
    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: apiUrl + '/user/add/member/subscript',
        headers: {
            'content-type': "application/json; charset=utf-8",
            'Authorization': 'Bearer ' + token()
        },
        data: data
    };
    try {
        const response = await axios.request(config);
        if (response.data.status) {
            return response
        } else {
            return response
        }
    } catch (error) {
        return error;
    }
}
export const changePassword = async (oldpass, newpass) => {
    let data = qs.stringify({
        'oldPassword': oldpass,
        'newPassword': newpass,
        'confirmNewPassword': newpass
    });
    console.log(data)
    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: apiUrl + '/user/editpassword',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': 'Bearer ' + token()
        },
        data: data
    };
    try {
        const response = await axios.request(config);
        return response.data
    } catch (error) {
        console.error(error);
        return error.response.data;
    }
}
export const getRegister = async (phoneNumber) => {
    const data = {
        phoneNumber: phoneNumber
    };

    const config = {
        headers: {
            "Content-Type": "application/json",
        },
    };

    try {
        const response = await axios.post(
            apiUrl + "/user",
            data,
            config
        );
        console.log(response)
        return response;
    } catch (error) {
        console.error(error);
        return error.response;
    }
}
export const getMyProfile = async () => {
    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: apiUrl + '/user/myprofile',
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem("token")
        }
    };
    try {
        const response = await axios.request(config);
        if (response.data.status) {
            //console.log(response.data.data)
            return response.data.data
        } else {
            return false;
        }
    } catch (error) {
        console.error(error);
        return false;
    }
}
export const callApiLogin = async (userCode, password, remember) => {
    const data = {
        userCode: userCode,
        password: password,
    };

    const config = {
        headers: {
            "Content-Type": "application/json",
        },
    };

    try {
        const response = await axios.post(
            apiUrl + "/user/login",
            data,
            config
        );
        if (response.data.status) {
            localStorage.setItem("user", JSON.stringify(response.data.data));
            localStorage.setItem("token", response.data.data.token);
            localStorage.setItem("tokenExpiresAt", response.data.data.tokenExpiresAt);
            localStorage.setItem("refresToken", response.data.data.refresToken);
            localStorage.setItem("refresTokenExpiresAt", response.data.data.refresTokenExpiresAt);
            if (remember) {
                const expiration = Date.now() + 30 * 24 * 60 * 60 * 1000; // 30 days expiration
                const pass = stringToASCII(password)
                localStorage.setItem('rememberData', JSON.stringify({ value: { userCode, pass }, expiration }));
            } else {
                localStorage.removeItem("rememberData");
            }

            if (response.data.data.position_id) {
                localStorage.setItem("position_icon", response.data.data.position_id.icon);
            }
            const position = await getPosition();
            localStorage.setItem("position", JSON.stringify(position));
        }
        return response.data;
    } catch (error) {
        console.log(error);
        return false;
    }
}
function token() {
    return localStorage.getItem("token")
};
