import React, { useState } from "react";
import hong from "../../assets/hong.svg";
import { NavLink, useNavigate } from "react-router-dom";
import { getRegister } from "../../api/auth";
import Swal from "sweetalert2";
import { PulseLoader } from "react-spinners";
import { stringToASCII } from "../../serviec/hashpassword";
function Register() {
  const [phoneNumber, setPhoneNumber] = useState("");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const handleRegister = async () => {
    // Validate phone number
    const trimmedPhoneNumber = phoneNumber.trim();
    if (!trimmedPhoneNumber) {
      setError("ໃສ່ເບີໂທ");
    }
    else if (!/^\d{8}$/.test(trimmedPhoneNumber)) {
      setError("ເບີໂທບໍ່ຖືກຕ້ອງ");
    } else {
      setError("");
      setLoading(true);
      const result = await getRegister(phoneNumber);
      setLoading(false)
      if (result.data.status) {
        const userCode = result.data.data.userCode;
        const pass = stringToASCII(result.data.data.password);
        const expiration = Date.now() + 30 * 24 * 60 * 60 * 1000; // 30 days expiration
        localStorage.setItem("tempRegister", JSON.stringify(result.data.data));
        localStorage.setItem("tempRegister", JSON.stringify(result.data.data));
        localStorage.setItem('rememberData', JSON.stringify({ value: { userCode, pass }, expiration }));
        navigate("/register/success");
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: result.data.message,
        });
      }
      console.log(result);
    }
  }
  return (
    <div className="w-full min-h-screen">
      {loading &&
        <div className=" fixed pt-10 lg:p-96 inset-0 :bg-black bg-opacity-25 backdrop-blur-sm z-50">
          <PulseLoader
            className=" text-center"
            color="#36d7b7"
            size={30}
            aria-label="Loading Spinner"
            data-testid="loader" />
        </div>
      }
      <div className="bg-blue-dark flex justify-between items-center">
        <div className="ps-3">
          <div className="flex justify-center">
            <img src={hong} alt="" className="w-[100px]" />
          </div>
          <h1 className="text-white text-center">
            ບໍລິດສັດ ຫົງຟ້າຈຳກັດ
          </h1>
        </div>
        <div>
          <span className="font-medium text-white text-2xl">ສະໝັກສະມາຊິກ</span>
        </div>
        <div></div>
      </div>
      <div className="w-full lg:w-[60%] lg:m-auto">
        <div className=" bg-white shadow-lg rounded-lg mt-5 px-5 py-20">
          <div>
            <p className=" text-center font-bold text-2xl text-blue-dark">Enter Your Phone Number</p>
          </div>
          <div className="lg:w-[50%] pt-5 m-auto">
            <div className="w-full items-center flex border text-xl rounded border-blue-dark">
              <h1 className="w-24 flex items-center justify-center border-r border-blue-dark h-full">
                +856
              </h1>
              <div className="flex rounded items-center gap-2">
                <h1 className="ml-4">20</h1>
                <input
                  type="text"
                  value={phoneNumber}
                  placeholder=" Phone Number "
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  className="p-2 outline-none bg-white"
                />
              </div>

            </div>
            {error && <span className='pt-2 text-red-500'>{error}</span>}
          </div>
        </div>
        <div className="px-5 mt-5 flex justify-between">
          <NavLink
            to="/login"
            className="flex items-center justify-center border-2 border-blue-dark w-[45%] h-14 rounded-lg"
          >
            {" "}ຍົກເລິກ{" "}
          </NavLink>
          <button
            className="flex items-center justify-center text-white bg-blue-dark w-[45%] h-14 rounded-lg"
            onClick={handleRegister}
          >
            ຢືນຢັນ{" "}
          </button>
        </div>
      </div>
    </div>
  );
}
export default Register;
