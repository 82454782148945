import React from 'react';
import linechartgreen from "../../assets/linechartgreen.jpeg";

import { PulseLoader } from "react-spinners";

const AnimationLoading = () => {
  return (
    <PulseLoader
      color="#36d7b7"
      size={10}
      aria-label="Loading Spinner"
      data-testid="loader" />
  )
}
function DashboardAreachart2(props) {
  return (
    <div>
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <div className=" px-4 my-2">
            <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
              <path opacity="0.2" d="M47.28 29.8062C44.0745 42.6634 31.0515 50.4874 18.1927 47.2819C5.33999 44.0765 -2.48476 31.0534 0.721486 18.1969C3.92549 5.3382 16.9477 -2.4873 29.8027 0.718197C42.6607 3.9237 50.4847 16.9482 47.2792 29.8062H47.28Z" fill="green" fill-opacity="0.6" />
              <path fill-rule="evenodd" clip-rule="evenodd" d="M31.2281 21.2118C31.5517 19.0453 29.9022 17.8807 27.6468 17.1038L28.3784 14.1691L26.5915 13.7239L25.8792 16.5813C25.4101 16.4643 24.9278 16.3538 24.4485 16.2445L25.1659 13.3683L23.3806 12.9231L22.6484 15.8568C22.2597 15.7682 21.8781 15.6807 21.5077 15.5886L21.5098 15.5795L19.0462 14.9643L18.571 16.8723C18.571 16.8723 19.8964 17.176 19.8684 17.1949C20.5919 17.3755 20.7232 17.8543 20.7008 18.2338L19.8674 21.5771C19.9172 21.5898 19.9819 21.6081 20.0531 21.6366L19.8648 21.5898L18.6962 26.2732C18.6076 26.493 18.3832 26.8227 17.8775 26.6975C17.8953 26.7235 16.5791 26.3734 16.5791 26.3734L15.6923 28.4188L18.0174 28.9983C18.2799 29.0641 18.5392 29.1317 18.7957 29.1986L18.7959 29.1987C18.9619 29.242 19.1267 29.285 19.2904 29.327L18.5511 32.2957L20.3355 32.7409L21.0681 29.8042C21.555 29.9365 22.0282 30.0586 22.4912 30.1736L21.7616 33.0966L23.548 33.5418L24.2872 30.5791C27.3334 31.1555 29.6244 30.923 30.5876 28.1684C31.3645 25.9501 30.5494 24.6705 28.9467 23.8356C30.1139 23.5654 30.9931 22.7977 31.2276 21.2118H31.2281ZM27.1461 26.9351C26.639 28.9711 23.4514 28.1287 21.9943 27.7436L21.9942 27.7436C21.8637 27.7091 21.7472 27.6783 21.6476 27.6535L22.6286 23.7211C22.7503 23.7515 22.899 23.7849 23.0674 23.8226C24.5749 24.1609 27.6642 24.8542 27.1466 26.9351H27.1461ZM23.3704 21.9978C24.5862 22.3222 27.2365 23.0293 27.6982 21.1797C28.1702 19.2876 25.5931 18.7173 24.3345 18.4387C24.193 18.4074 24.0682 18.3798 23.9662 18.3544L23.0768 21.921C23.161 21.942 23.2598 21.9683 23.3704 21.9978Z" fill="green" fill-opacity="0.7" />
            </svg>
          </div>
          <h1 className="text-sm lg:text-xl font-medium">
            ຍອດຂາຍ
          </h1>
        </div>
      </div>
      <div className="w-full flex justify-center">
        <img src={linechartgreen} alt="" className="w-[40rem] h-[8rem]" />
      </div>
      <div className="flex w-full items-center justify-center font-bold text-green-600 lg:text-xl">
        <h1>
          {props.loading ? <AnimationLoading /> : props.data + " ກິບ"}
        </h1>
      </div>
    </div>
  );
}

export default DashboardAreachart2;
