import { createContext, useContext, useState } from 'react'

const useAuthContext = createContext()

export default function AuthContext({ children }) {

  const [formData, setFormData] = useState({
    ReferralCode: "",
    firstName: "",
    lastName: "",
    email:"",
    birthDay: "",
    village: "",
    district: "",
    province: "",
    docNo: "",
    docName: "ບັດປະຈຳຕົວ",
    bankName: "BCEL",
    accountName: "",
    accountNo: "",
    docImage: null,
    bankImage: null,
  });
  return (
    <useAuthContext.Provider value={{formData, setFormData}}>
        {children}
    </useAuthContext.Provider>
  )
}

export function useAuth() {
    return useContext(useAuthContext)
}