
import AllRoute from './routes/AllRoute';
import AuthContext from './context/AuthContext';
function App() {
  return (
    //  <div>
    //   <AllRoute/>
    //  </div>
    <AuthContext>
      <AllRoute />
    </AuthContext>
  );
}

export default App;
