import React from 'react'
import { useState, useEffect } from 'react';
import moneywithdraw from '../../assets/moneywithdraw.png'
import { IoCloudUploadOutline } from "react-icons/io5";
import wallet from '../../assets/wallet.png'
import cash from '../../assets/cash.png'
import { NavLink, useNavigate } from 'react-router-dom'
import { getMyWallet, getDeposit } from '../../api/ewallet';
import { PulseLoader } from 'react-spinners';
import Swal from 'sweetalert2';
import verify from '../../assets/verify.png';
const AnimationLoading = () => {
    return (
        <PulseLoader
            color="#36d7b7"
            size={10}
            aria-label="Loading Spinner"
            data-testid="loader" />
    )
}
function EwalletMoneyTransfer() {
    const navigate = useNavigate();
    const [selectedImage, setSelectedImage] = useState(null);
    const [myWallet, setMyWallet] = useState([]);
    const [loaading, setLoading] = useState(false);
    const [loadPay, setLoadPay] = useState(false);
    //const [phone, setPhone] = useState('')
    const [amount, setAmount] = useState();
    const [password, setPassword] = useState('')
    const [errors, setErrors] = useState([]);

    useEffect(() => {
        fetchData();
    }, [])
    async function fetchData() {
        setLoading(true);
        const data1 = await getMyWallet();
        if (data1) {
            setMyWallet(data1);
        } else {
            Alert()
        }
        setLoading(false);
    }
    const handleSubmit = async () => {
        const error = {}
        // if (!phone) {
        // 	error.phone = "ເບີໂທ"
        // }
        // if (phone) {
        // 	if (phone.length < 8 || phone.length > 11) {
        // 		error.phone = "ເບີບໍ່ຖືກຕ້ອງ"
        // 	}
        // }
        if (!amount) {
            error.amount = "ຈຳນວນເງິນທີ່ຕ້ອງການເຕີມ"
        }
        if (amount < 1) {
            error.amount = "ຈຳນວນເງິນບໍ່ຖືກຕ້ອງ"
        }
        if (!selectedImage) {
            error.slip = "slip ໂອນເງິນ"
        }
        // if (!password) {
        // 	error.password = "ລະຫັດກະເປົ່າ"
        // }
        setErrors(error);
        if (Object.keys(error).length === 0) {
            setLoadPay(true)
            const x = await getDeposit(amount, selectedImage, password)
            setLoadPay(false)
            if (x.status) {
                Swal.fire({
                    title: "ສຳເລັດ!",
                    html: "ທ່ານໄດ້ເຕີມເງິນຈຳນວນ: " + amount + "\nລໍຖ້າແອັດມິນກວດສອບ",
                    icon: "success"
                }).then(() => {
                    navigate("/ewalletwithdraw")
                });
            } else {
                Swal.fire({
                    title: "ເຕີມເງິນບໍ່ສຳເລັດ!",
                    text: x.message,
                    icon: "error"
                });
            }
        }
    }
    const Alert = () => {
        return (
            Swal.fire({
                html: '<div class="flex flex-col items-center">' +
                    '<img src="' + verify + '" alt="verify" class="w-16 h-16 mb-4" />' +
                    '<h2 class="text-lg font-semibold mb-2">ທ່ານຍັງບໍ່ທັນຢືນຢັນຕົວຕົນ</h2>' +
                    '<p>ທ່ານຕ້ອງຢືນຢັນຕົວຕົນກ່ອນ ຈຶ່ງຈະສາມາດນຳໃຊ້ຟັງຊັ້ນຕ່າງໆຂອງພວກເຮົາໄດ້</p>' +
                    '</div>',
                showCancelButton: true,
                confirmButtonColor: "#00AEEF",
                confirmButtonText: "ຢືນຢັນ",
                cancelButtonText: "ພາຍຫຼັງ",
                cancelButtonColor: "#ffffff",
                cancelButtonText: '<span style="color: blue;">ພາຍຫຼັງ</span>',
                reverseButtons: true,
            }).then((result) => {
                if (result.isConfirmed) {
                    navigate("/verifyswall")
                } else {
                    navigate("/")
                }
            })
        )
    }
    const handleImage = (e) => {
        setSelectedImage(e.target.files[0])
    }
    return (
        <div className='w-full relative'>
            {loadPay &&
                <div className=" fixed pt-10 lg:p-96 inset-0 :bg-black bg-opacity-25 backdrop-blur-sm z-50">
                    <PulseLoader
                        className=" text-center"
                        color="#36d7b7"
                        size={30}
                        aria-label="Loading Spinner"
                        data-testid="loader" />
                </div>
            }
            <div className="w-full l mt-20 py-5 shadow-lg border p-2 bg-white rounded-xl lg:mt-16 md:py-8 lg:py-10 lg:px-10">
                <div className='w-full lg:w-[50%]'>
                    <div className='flex justify-start items-center gap-3'>
                        <span className=''>ເງິນຄົງເຫຼື່ອໃນ wallet</span>
                        <span className='font-bold text-blue-light text-2xl lg:text-4xl'>{loaading ? <AnimationLoading /> : myWallet.balance ? myWallet.balance.toLocaleString() + ' ກີບ' : ''}</span>
                    </div>
                    <div className=' mt-5'>
                        <label>ຈຳນວນທີ່ຕ້ອງການເຕີມ</label>
                        <div className='w-full p-2 flex items-center border border-blue-dark rounded-lg relative '>
                            <span className=' absolute right-3'>LAK</span>
                            <div className=' px-3 '>
                                <img src={cash} alt="" />
                            </div>
                            <input type="number" placeholder='LAK' min={0} max={myWallet.balance} defaultValue={amount} onChange={(e) => setAmount(e.target.value)} className=' w-full text-lg rounded-lg outline-none' />
                        </div>
                        {errors.amount && <span className='pt-1 text-red-500'>{errors.amount}</span>}
                    </div>
                    <div className=' mt-6 flex justify-center relative'>
                        {selectedImage ? (
                            <div>
                                <img
                                    src={URL.createObjectURL(selectedImage)}
                                    className=" h-full w-[200px] mx-auto object-cover"
                                    alt=""
                                />
                            </div>
                        ) : ''}

                        <div className="text-center flex flex-col items-center gap-3">
                            <IoCloudUploadOutline className=" text-gray-400 w-16 h-20" />
                            <h2 className="mt-3 text-gray-400 font-medium font-Noto text-2xl ">
                                Drag & Drop files here
                            </h2>
                            <h3 className=" text-gray-400">or</h3>
                            <button className=" border-2 border-blue-light text-blue-light font-bold text-lg w-40 h-12 rounded-lg">
                                {" "}
                                Browse Files{" "}
                            </button>
                            {errors.slip && <span className='pt-1 text-red-500'>{errors.slip}</span>}
                        </div>
                        <input
                            type="file"
                            accept=".jpg"
                            className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                            onChange={handleImage}
                        />
                    </div>
                    <div className='mt-5 flex justify-around gap-4'>
                        <NavLink to="/ewalletwithdraw" className='w-full py-3 text-center bg-gradient-to-t from-gray-500 to-gray-200 text-white rounded-lg font-bold text-lg   '> ຍົກເລີກ </NavLink>
                        <button onClick={handleSubmit} className='w-full py-3    bg-gradient-to-t from-blue-dark to-blue-light font-bold text-lg  text-white rounded-lg  '> ເຕີມເງິນ  </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EwalletMoneyTransfer