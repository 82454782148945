import React, { useState, useLayoutEffect, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { errorSwal, successSwal } from "../helpers/sweetalert";
import { Loader } from "../components";
import { asciiToString } from "../serviec/hashpassword";
//img
import hongfa from "../assets/hongfah.png";
import hong from "../assets/hong.svg";
import { FaRegEyeSlash } from "react-icons/fa";
import { FaRegEye } from "react-icons/fa";
//api
import { callApiLogin } from "../api/auth";
import Swal from "sweetalert2";
const Login = () => {
  const navigate = useNavigate();
  const [userCode, setUserCode] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [remember, setRemember] = useState(Boolean);
  const [errors, setErrors] = useState([]);
  const [isShowPass, setIsShowPass] = useState(false);
  const token = localStorage.getItem('token');
  useLayoutEffect(() => {
    if (token) {
      navigate("/");
    }
  }, [token, navigate]);

  useEffect(() => {
    const rememberdata = JSON.parse(localStorage.getItem("rememberData"));
    if (rememberdata) {
      setRemember(true);
      const pass = asciiToString(rememberdata.value.pass)
      const code = rememberdata.value.userCode;
      setUserCode(code);
      setPassword(pass);

    } else {
      setRemember(false);
    }
  }, [])

  const validate = () => {
    const error = {};
    if (!userCode) {
      error.userCode = "ຊື່ຜູ້ໃຊ້"
    }
    if (!password) {
      error.password = "ລະຫັດຜ່ານ"
    }
    return error;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const isValidate = validate();
    if (Object.keys(isValidate).length === 0) {
      try {
        setLoading(true);
        const result = await callApiLogin(userCode, password, remember);
        const isMaintainSales = result.data.isMaintainSales
        console.log(result);
        if (result.status) {
          if (result.data.isVerify) {
            if (!isMaintainSales) {
              Swal.fire({
                title: "ຮັກສາຍອດ?",
                text: "ທ່ານຕ້ອງຮັກສາຍອດ",
                icon: "info",
                width:"340px"
              });
            } else {
              successSwal("ເຂົ້າສູ່ລະບົບສຳເລັດ");
            }
          } else {
            successSwal("ເຂົ້າສູ່ລະບົບສຳເລັດ");
          }
          setLoading(false);
        } else {
          setLoading(false);
          errorSwal("ຊື່ຜູ້ໃຊ້ ຫຼື ລະຫັດຜ່ານຜິດ");
        }
      } catch (err) {
        setLoading(false);
        errorSwal("ຊື່ຜູ້ໃຊ້ ຫຼື ລະຫັດຜ່ານຜິດ");
      }
    } else {
      setErrors(isValidate);
    }
  }
  const handdleEyePass = () => {
    setIsShowPass(!isShowPass);
  }

  return (
    <div className="w-full fixed">
      <div className="login-background w-full h-screen place-items-center p-4 grid ">
        {loading && <Loader />}
        <div className="lg:w-[30%] h-[85%] w-full rounded-3xl flex justify-center items-center md:border-2 lg:border-2">
          <div className="bg-white w-full shadow-md md:w-[93%] md:h-[95%] rounded-3xl text-center py-4 px-2">
            <div className="flex w-full justify-center">
              <img src={hongfa} alt="" className="w-[100px]" />
            </div>
            <div className="mt-3">
              <div className="mb-3 px-4">
                <p className="self-start flex font-medium "> User ID</p>
                <div className="">
                  <input
                    type="text"
                    name="userCode"
                    value={userCode}
                    onChange={e => setUserCode(e.target.value)}
                    placeholder="ຊື່ຜູ້ໃຊ້"
                    className={`bg-white  text-black ${errors.userCode ? "border-red-500" : "border-gray-400"
                      } p-3 w-full border-2 outline-none focus:border-2 focus:border-main-color rounded-md`}
                  />
                </div>
                {errors.userCode && <p className="text-red-500 text-[12px] mt-2 text-left">{errors.userCode}</p>}
              </div>
              <div className="mb-3 px-4">
                <p className="self-start flex font-medium "> Password</p>
                <div className="relative">
                  <input
                    type={isShowPass ? "text" : "password"}
                    name="password"
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                    placeholder="ລະຫັດຜ່ານ"
                    className={`bg-white  text-black ${errors.password ? "border-red-500" : "border-gray-400"
                      } p-3 w-full border-2 outline-none focus:border-2 focus:border-main-color rounded-md`}
                  />
                  <button onClick={handdleEyePass} className=" h-full absolute right-0 top-0 p-3">{isShowPass ? <FaRegEye size={20} color="#2A91FF " /> : <FaRegEyeSlash size={20} color="#2A91FF " />}</button>
                </div>
                {errors.password && <p className="text-red-500 text-[12px] mt-2 text-left">{errors.password}</p>}
              </div>
              <div className="mt-2 px-4 grid grid-cols-1 lg:flex justify-between ">
                <div className="flex gap-2 ">
                  <input
                    type="checkbox"
                    checked={remember}
                    onChange={() => setRemember(!remember)}
                  /> <span> Remember me</span>
                </div>
                <ul>
                  <li className="underline">
                    <a href="https://wa.me/2054544541" target="bank"> ລືມລະຫັດຜ່ານ </a>
                  </li>
                </ul>
              </div>
              <div className="px-4 mt-3 ">
                <button
                  onClick={handleSubmit}
                  className="w-full font-medium text-base rounded-md text-white bg-blue-dark p-3 hover:shadow-md duration-300"
                >
                  ເຂົ້າສູລະບົບ
                </button>
              </div>
              <div className="px-4 my-2 ">
                <NavLink to="/register">
                  <button className="mt-3 w-full font-medium text-base rounded-md text-blue-dark border-blue-dark border-2 p-3 hover:shadow-md duration-300">
                    ລົງທະບຽນ
                  </button>
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>
      <img
        src={hong}
        alt=""
        className="hidden absolute mt-[-23rem] w-[350px] ml-[60.5rem] opacity-10 lg:block"
      />
      <img
        src={hong}
        alt=""
        className="hidden absolute mt-[-23rem] w-[350px] ml-[13rem] opacity-10 transform scale-x-[-1] lg:block"
      />
    </div>
  );
};
export default Login;
