import React, { useEffect, useState } from "react";
import hong from "../../assets/hong.svg";
import { NavLink, useNavigate } from "react-router-dom";
import { IoCloudUploadOutline } from "react-icons/io5";
import { FormDataVerify } from "../../api/verify";
import { Loader } from "../../components";
import { successSwal, errorSwal } from "../../helpers/sweetalert";
import { getMyProfile } from "../../api/auth";
import { useAuth } from "../../context/AuthContext";

function VerifyRegister1() {
  const { formData, setFormData } = useAuth()
  const navigate = useNavigate();
  const [load, setLoad] = useState(false);

  useEffect(() => {

    if (localStorage.getItem("sentVerify")) {
      navigate("/verifyregister3");
    }
  })
  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: files[0],
    }));
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // console.log(formData)

  const sumit = () => {
    setLoad(true);
    FormDataVerify(localStorage.getItem("token"), formData)
      .then(async (res) => {
        setLoad(false);
        if (res.status === true) {
          successSwal(res.data.message);
          const user = await getMyProfile();
          localStorage.setItem("user",JSON.stringify(user));
          navigate("/verifyregister3");
        } else {
          errorSwal(res.data.message)
        }
        setLoad(false);
      })
      .catch((err) => {

        console.log(err);
      });
  };

  return (
    <div>
      {!load ? "" : <Loader />}
      <div className="w-full fixed z-50">
        <div className="md:flex justify-between items-center p-2 bg-blue-dark">
          <div className="flex flex-col justify-center items-center">
            <img src={hong} alt="" className="md:w-24 md:ml-12 sml:w-20 w-10" />
            <h1 className="text-white md:ml-12 text-sm lg:text-lg">
              ບໍລິດສັດ ຫົງຟ້າຈຳກັດ
            </h1>
          </div>
          <ul className="steps w-full md:w-[80%] text-white text-lg font-medium ">
            {/* <li className="step step-primary">ຂໍ້ມູນສະໝັກ</li> */}
            <li
              data-content="✓"
              className="step step-neutral text-white md:text-lg"
            >
              ຂໍ້ມູນສະໝັກ
            </li>
            <li className="step step-primary ">ຢືນຢັນຕົວຕົນ</li>
            <li className="step">ເລືອກຕຳແໜ່ງ</li>
            <li className="step">ລໍຖ້າກວດສອບ</li>
          </ul>
          <div></div>
        </div>
      </div>
      <div className="  flex w-full justify-center py-6 ">
        <div className=" w-[96%]  lg:flex justify-around mt-32 ">
          <div className=" w-full mt-10 lg:w-[35%] flex border shadow-lg rounded-lg  justify-center">
            <div className="w-[95%] ">
              <h1 className=" text-3xl font-medium my-2">
                ລົງທະບຽນການສະໝັກສະມາຊິກ
              </h1>

              <h3 className=" text-xl font-medium text-blue-light ">
                ຂໍ້ກຳນົດຕ່າງໆໃນການເປັນສະມາຊິກ:
              </h3>
              <div>
                <p>ຍັງບໍ່ມີຂໍ້ມູນ</p>
                {/* <p>
                  Lorem ipsum dolor sit amet consectetur. Maecenas ullamcorper
                  etiam mauris at cursus.
                </p> */}
              </div>
            </div>
          </div>
          <div className="w-full lg:w-[60%] ">
            <div className=" flex w-full justify-center my-4 text-blue-light font-bold text-3xl">
              <span>ເອກະສານຢືນຢັນຕົວຕົນ</span>
            </div>

            <div className=" w-full border border-blue-light rounded flex justify-center ">
              <div className=" w-[90%] py-8 md:flex justify-around ">
                <div className="w-full mt-10 lg:w-[45%] justify-around gap-16 flex flex-col ">
                  <div className=" flex flex-col w-full">
                    <span>ເອກະສານຢືນຢັນຕົວຕົນ</span>
                    <select
                      name="docName"
                      onChange={handleChange}
                      id=""
                      defaultValue={formData.docName}
                      className=" h-12 rounded-lg outline-none bg-white text-gray-700 border border-gray-500 px-2"
                    >
                      <option value="ບັດປະຈຳຕົວ">ບັດປະຈຳຕົວ</option>
                      <option value="Passport">Passport</option>
                      <option value="ເອກະສານອື່ນ">ເອກະສານອື່ນ</option>
                    </select>
                  </div>
                  <div className=" flex flex-col w-full">
                    <div className=" flex flex-col ">
                      <label htmlFor="branch">ເລກ{formData.docName}</label>
                      <div className=" h-12 rounded w-full flex ">
                        <input
                          type="text"
                          name="docNo"
                          defaultValue={formData.docNo}
                          onChange={handleChange}
                          className=" outline-none h-full w-[95%] rounded  bg-white text-gray-700 border border-gray-500 px-2"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-full md:w-[45%] ">
                  <span>ອັບໂລດຮູບບັດປະຈຳຕົວ</span>
                  <div className=" border-2 rounded-lg">
                    {" "}
                    {formData.docImage ? (
                      <img
                        src={URL.createObjectURL(formData.docImage)}
                        className=" h-full w-full mx-auto object-cover"
                        alt=""
                      />
                    ) : (
                      <div className="relative  border-gray-300 p-2 mx-auto  w-[20rem] rounded-full">
                        <div className="text-center flex flex-col items-center gap-3">
                          <IoCloudUploadOutline className=" text-gray-400 w-12 h-12" />
                          <h2 className=" text-gray-400 font-medium font-Noto text-xl ">
                            Drag & Drop files here
                          </h2>
                          <h3 className=" text-gray-400">or</h3>
                          <button className=" border-2 border-blue-light text-blue-light font-bold text-lg w-40 h-10 rounded-lg">
                            {" "}
                            Browse Files{" "}
                          </button>
                        </div>
                        <input
                          type="file"
                          name="docImage"
                          defaultValue={formData.docImage}
                          className="absolute inset-0 w-full h-full opacity-0 cursor-pointer "
                          onChange={handleFileChange}
                        />
                      </div>
                    )}{" "}
                  </div>
                </div>
              </div>
            </div>
            <div className=" flex w-full justify-center my-4 text-blue-light font-bold text-3xl">
              <span>ປ້ອນຂໍ້ມູນບັນຊີທະນາຄານ</span>
            </div>

            <div className=" w-full border border-blue-light rounded flex justify-center ">
              <div className=" w-[90%] py-8 md:flex justify-around ">
                <div className="w-full lg:w-[45%] justify-around gap-16 flex flex-col ">
                  <div className=" flex flex-col w-full">
                    <span>ເລືອກທະນາຄານ</span>
                    <select
                      id=""
                      name="bankName"
                      defaultValue={formData.bankName}
                      onChange={handleChange}
                      className=" h-12 rounded-lg outline-none bg-white text-gray-700 border border-gray-500 px-2"
                    >
                      <option value="BCEL">BCEL</option>
                      <option value="LDB">LDB</option>
                      <option value="JDB">JDB</option>
                    </select>
                  </div>
                  <div className=" flex flex-col w-full">
                    <div className=" flex flex-col ">
                      <label htmlFor="branch">ຊື່ບັນຊີ</label>
                      <div className=" h-12 rounded w-full flex  justify-center ">
                        <input
                          type="text"
                          name="accountName"
                          defaultValue={formData.accountName}
                          onChange={handleChange}
                          className=" outline-none h-full w-[95%] rounded bg-white text-gray-700 border border-gray-500 px-2"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className=" flex flex-col w-full">
                    <div className=" flex flex-col ">
                      <label htmlFor="branch">ເລກບັນຊີ</label>
                      <div className=" h-12 rounded w-full flex ">
                        <input
                          type="text"
                          name="accountNo"
                          defaultValue={formData.accountNo}
                          onChange={handleChange}
                          className=" outline-none h-full w-[95%] rounded border bg-white text-gray-700 border-gray-500 px-2"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-full md:w-[45%] ">
                  <span>ອັບໂລດຮູບບັນຊີທະນາຄານ</span>
                  <div className=" border-2 rounded-lg ">
                    {" "}
                    {formData.bankImage ? (
                      <img
                        src={URL.createObjectURL(formData.bankImage)}
                        className=" h-full w-full mx-auto object-cover"
                        alt=""
                      />
                    ) : (
                      <div className="relative  border-gray-300 p-2 mx-auto  w-[20rem] rounded-full">
                        <div className="text-center flex flex-col items-center gap-3">
                          <IoCloudUploadOutline className=" text-gray-400 w-12 h-12" />
                          <h2 className=" text-gray-400 font-medium font-Noto text-xl ">
                            Drag & Drop files here
                          </h2>
                          <h3 className=" text-gray-400">or</h3>
                          <button className=" border-2 border-blue-light text-blue-light font-bold text-lg w-40 h-10 rounded-lg">
                            {" "}
                            Browse Files{" "}
                          </button>
                        </div>
                        <input
                          type="file"
                          name="bankImage"
                          className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                          onChange={handleFileChange}
                        />
                      </div>
                    )}{" "}
                  </div>
                </div>
              </div>
            </div>

            <div className=" w-full ">
              <div className=" w-full  flex justify-between  mt-12">
                <NavLink
                  to="/verifyswall"
                  className=" w-[45%] h-14 border border-blue-light flex justify-center items-center  text-blue-light text-xl font-medium rounded-lg "
                >
                  Back
                </NavLink>
                <button
                  onClick={sumit}
                  className=" w-[45%] h-14 border bg-blue-light flex justify-center items-center  text-white text-xl font-medium rounded-lg "
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VerifyRegister1;