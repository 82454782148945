import { apiUrl } from './apiconfig';
import axios from "axios";
export const getallBranch = async () => {
    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: apiUrl + '/branch/getall',
        headers: {
            'Authorization': 'Bearer ' + token()
        }
    };
    const result = await axios.request(config);
    return result.data;

}
function token() {
    return localStorage.getItem("token")
};