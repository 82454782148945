import React, { useState, useEffect } from "react";
import { IoIosArrowBack } from "react-icons/io";
//import rectangle from "../../assets/Rectangle 240 (1).png";
import { FaKipSign } from "react-icons/fa6";
import { Link, NavLink, useParams, useNavigate } from "react-router-dom";
import { getOneProduct, addToCart } from "../../api/product";
import Swal from 'sweetalert2';
export default function Productdetail() {
  const { id } = useParams();
  const navigate = useNavigate()
  const [product, setProduct] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [count, setCount] = useState(1);

  useEffect(() => { //ໂຫຼດຂໍ້ມູນ ສິນຄ້າ
    const fetchdata = async () => {
      setIsLoading(true);
      const data = await getOneProduct(id);
      setProduct(data);
      setIsLoading(false);
    }
    fetchdata();
  }, [])

  const handleAdd = () => {
    setCount(Math.max(1, count + 1));
  };

  const handleMinus = () => {
    setCount(Math.max(1, count - 1));
  };
  const AddToCart = async () => {
    const x = await addToCart(product._id, count);
    if (x) {
      Swal.fire({
        icon: "success",
        title: "ເພີ່ມເຂົ້າກະຕ່າສຳເລັດແລ້ວ",
        showConfirmButton: false,
        timer: 500,
        width:"340px"
      }).then(() => {
        navigate("/productorder")
      })
    }
  }
  return (
    <div className="w-full h-full">
      <div className="w-full mt-28 pt-5 b shadow-lg border p-2 bg-white rounded-xl md:mt-24 lg:mt-24 md:py-8 lg:py-10 lg:px-10">
        <div>
          <button onClick={() => { navigate(-1) }} className="flex items-center">
            <IoIosArrowBack className=" h-12 w-12" />
            <h1 className=" text-lg">ກັບໄປໜ້າກ່ອນ</h1>
          </button>
        </div>
        <div>
          <div>
            {isLoading ? 'loading...' : <ShowProductDetail {...product} />}
          </div>
          <div className="flex justify-center lg:justify-end items-center gap-6 ">
            <div className=" flex gap-4">
              <button
                className=" border w-8 h-8 rounded"
                onClick={handleMinus}
              >
                -
              </button>{" "}
              <input className=" w-20 border rounded-lg px-2 bg-white" type="number" value={count} onChange={e => setCount(parseInt(e.target.value))}/>
              {/* <h1 className="flex items-center"> {count}</h1>{" "} */}
              <button
                className="  border w-8 h-8 rounded"
                onClick={handleAdd}
              >
                +
              </button>{" "}
            </div>
            <button onClick={AddToCart} className=" border text-white font-medium bg-blue-light h-12 w-40 rounded-lg">
              ເລືອກສີນຄ້າ
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

function ShowProductDetail(product) {
  var product_img;
  var product_price = 0;
  if (product.images) {
    product_img = product.images[0]
    product_price = product.price.toLocaleString();
  }
  return (
    <>
      <div className=" w-full grid grid-cols-1 lg:flex justify-around mt-10">
        <div className="w-full lg:w-[40%] flex justify-center items-center">
          <div className="text-center">
            <h1 className="text-2xl font-bold p-2"> {product.name} </h1>
            <img src={process.env.REACT_APP_IMGURL+product_img} alt="imgproduct" className=" w-60 h-68 rounded-lg" />
            <h1 className="text-green-500 p-2">{product.point} point</h1>
          </div>
        </div>
        <div className="w-full lg:px-40">
          <div className="w-full h-[100px] px-5 rounded-2xl bg-sky-200 flex justify-around items-center">
            <div className="text-center">
              <h1 className=" text-gray-500">ຍອດຂາຍ</h1>
              <h1 className=" text-lg font-medium">{product.amount}</h1>
            </div>
            <div className=" border border-gray-400 h-full"></div>
            <div className="text-center">
              <h1 className=" text-gray-500">ລາຄາ</h1>
              <div className="flex justify-center items-center">
                <FaKipSign />
                <h1 className=" text-lg font-medium">{product_price}</h1>
              </div>
            </div>
          </div>
          <div className=" w-full flex flex-col my-3 lg:my-6 border border-slate-300 rounded-lg p-3">
            <h1 className=" text-xl font-medium  mt-4">ລາຍລະອຽດສີນຄ້າ</h1>
            <div className=" w-full text-lg" dangerouslySetInnerHTML={{ __html: product.detail }} >
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

