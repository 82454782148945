import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import hong from "../../assets/hong.svg";
import Swal from "sweetalert2";

function RegisterSuccess({ props }) {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem("tempRegister"))
    if (data) {
      setUsername(data.userCode);
      setPassword(data.password);
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "ທ່ານບໍ່ມີສິດເຂົ້າໃຊ້",
      }).then(() => {
        navigate("/login")
      });
    }
  }, [])

  const handleBack = () => {
    Swal.fire({
      title: "ບັນທຶກລະຫັດໄວ້ແລ້ວບໍ່",
      text:"ທ່ານຈະບໍ່ສາມາດເຂົ້າໜ້ານີ້ໄດ້ອີກ",
      icon: "info",
      showCancelButton: true,
      confirmButtonText: "ກັບໄປທີ່ໜ້າເຂົ້າສູ່ລະບົບ",
    }).then((result) => {
      if (result.isConfirmed) {
        localStorage.removeItem("tempRegister");
        navigate("/login");
      }
    });
  }
  return (
    <div className="w-full min-h-screen">
      <div className="bg-blue-dark flex justify-between items-center">
        <div className="ps-3">
          <div className="flex justify-center">
            <img src={hong} alt="" className="w-[100px]" />
          </div>
          <h1 className="text-white text-center">
            ບໍລິດສັດ ຫົງຟ້າຈຳກັດ
          </h1>
        </div>
        <div>
          <span className="font-medium text-white text-2xl">ສະໝັກສະມາຊິກສຳເລັດ</span>
        </div>
        <div></div>
      </div>
      <div className="mt-5 lg:w-[40%] m-auto ">
        <div className="py-4 rounded-lg w-full border shadow-lg bg-white">
          <div className="w-full border-b-2">
            <p className="p-3 text-center text-blue-dark font-bold">ລົງທະບຽນສຳເລັດ</p>
          </div>
          <div>
            <p className="p-4 text-center font-medium">ກະລຸນາຮັກສາໄອດີແລະລະຫັດຜ່ານຂອງທ່ານເປັນຄວາມລັບ</p>
          </div>
          <div className="w-[90%] lg:w-[50%] py-3 m-auto border border-blue-dark">
            <div className="px-3">
              <div className="grid grid-cols-2 gap-3">
                <p className="text-center">ໄອດີ</p>
                <p className="bg-sky-200 text-center">{username}</p>
              </div>
              <div className="mt-3 grid grid-cols-2 gap-3">
                <p className="text-center">ລະຫັດຜ່ານ</p>
                <p className="bg-sky-200 text-center">{password}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-4 flex justify-between w-full">
          <button onClick={handleBack}
            to="/login"
            className="w-full p-3 text-center bg-blue-dark  rounded text-white"
          >
            {" "}
            ກັບໄປທີ່ໜ້າເຂົ້າສູ່ລະບົບ{" "}
          </button>
        </div>
      </div>
    </div>
  );
}

export default RegisterSuccess;
