import { useState } from 'react'
import { Sidebar } from '../components';
import Navbar from '../components/Navbar';
const Layout = ({ children }) => {
  const [click, setClick] = useState(false);
  return (
    <div className='flex'>
      <div className=' hidden lg:block md:block w-[15rem] z-50 fixed left-0'>
        <Sidebar click={click} setClick={setClick} />
      </div>
      <div className=' z-40 w-full fixed top-0'>
        <Navbar />
      </div>
      <main className=' min-h-screen w-full ps-0 lg:ps-[15rem] md:ps-[15rem]'>
        <div className='h-full md:px-5 lg:px-10 md:py-5 lg:py-5'>
          {children}
        </div>
      </main>
    </div>
  )
}

export default Layout