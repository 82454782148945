import React, { useEffect, useState } from 'react'
import PackageCard from './PackageCard'
import PackageCardL from './PackageL'
import PackageCardM from './PackageCardM'
import { getAllPackage } from '../../api/package'
import { PulseLoader } from 'react-spinners'
const AnimationLoading = () => {
	return (
		<PulseLoader
			color="#36d7b7"
			size={10}
			aria-label="Loading Spinner"
			data-testid="loader" />
	)
}
function Package() {
	const [packages, setPackages] = useState([]);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		fetchdata();
	}, [])
	async function fetchdata() {
		setLoading(true);
		const data = await getAllPackage();
		setPackages(data);
		setLoading(false);
	}
	return (
		<div className=' mt-32  w-full h-[1100px] flex flex-col gap-8 items-center '>
			{loading ? <AnimationLoading /> :
				<div className=' w-[90%]  grid grid-cols-3 gap-8'>
					{packages.map((item) => {
						return (
							<>
								<div className=' w-80 h-80 border border-blue-light rounded-lg overflow-hidden '>
									<PackageCard {...item} />
								</div>
							</>
						)
					})}
				</div>
			}
		</div>
	)
}

export default Package
