import axios from "axios"
import { apiUrl } from "./apiconfig"

export async function fetchDataMyuser(token) {

    try {
        const response = await axios.get(apiUrl+"/user/myprofile",
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
        );
        return response
    } catch (error) {
        console.error("Error fetching data:", error);
        throw error;
    }
}

export const FormDataVerify = async (token, formData) => {
    try {
        const response = await axios.post(apiUrl+'/user/subscript', formData, 
        {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'multipart/form-data'
            }
        });
        return response.data
        // Handle success if needed
    } catch (error) {
        console.log(error)
        return error.response
        // Handle error if needed
    }
};