import React, { useEffect, useState } from 'react'
//call api
import { getMyProfile, upProfile, changePassword, verifyDocument } from '../../api/auth';

//picture
import iconeUser from '../../assets/newAsset/Asset 2.svg'

//......................
import moment from 'moment';
import { PulseLoader } from 'react-spinners';
import { successSwal, errorSwal } from '../../helpers/sweetalert';
import Swal from 'sweetalert2';
import { NavLink } from 'react-router-dom';

function dateFormat(dateString) {
    const dateObject = moment(dateString);
    return dateObject.format("DD/MM/YYYY");
}
const Profile = () => {
    const [myProfile, setMyProfile] = useState(null);
    const [loading, setLoading] = useState(false);
    const [loadingProcess, setLoadingProcess] = useState(false);
    const [oldpass, setOldPass] = useState("");
    const [newPass1, setNewPass1] = useState("");
    const [newPass2, setNewPass2] = useState("");
    const [newProfile, setNewProfile] = useState(null);
    const [ShowPF, setShowPF] = useState(null);

    const [errors, setErrors] = useState([]);

    //data for user not verify
    const [bankName, setBankName] = useState("BCEL")
    const [accountName, setAccontName] = useState("");
    const [accountNo, setAccontNo] = useState("");
    const [accountQR, setAccontQR] = useState(null);
    const [docName, setdocName] = useState("ບັດປະຈຳຕົວ");
    const [docNo, setdocNo] = useState("");
    const [docImage, setDocImage] = useState(null);
    useEffect(() => {
        fetchdata()
    }, [])

    async function fetchdata() {
        setLoading(true)
        const data = await getMyProfile();
        if (data) {
            setMyProfile(data)
        }
        setLoading(false)
    }
    const handleSelectProfile = (e) => {
        setNewProfile(e.target.files[0]);

        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setShowPF(reader.result);
            };
            reader.readAsDataURL(file);
        }
    }
    const handleUpProfile = async () => {
        setLoadingProcess(true);
        const result = await upProfile(newProfile);

        if (result.status) {
            setLoadingProcess(false);
            const user = JSON.parse(localStorage.getItem("user"))
            user.profile = result.data.profile;
            console.log(user);
            localStorage.setItem("user", JSON.stringify(user));
            Swal.fire({
                title: result.message,
                icon: "success"
            }).then(() => {
                window.location.reload();
            });

        } else {
            setLoadingProcess(false);
            errorSwal(result.message);
        }
    }
    const handleChangPass = async (e) => {
        e.preventDefault();
        const error = {};
        if (!oldpass) {
            error.oldpass = "ລະຫັດເກົ່າ";
        }
        if (!newPass1) {
            error.newPass1 = "ລະຫັດໃໝ່"
        }
        if (newPass1.length < 8) {
            error.newPass1 = "ລະຫັດຕ້ອງຫຼາຍກວ່າ 8 ຫຼັກ"
        }
        if (!newPass2) {
            error.newPass2 = "ຢືນຢັນລະຫັດໃໝ່"
        }
        if (newPass2 != newPass1) {
            error.newPass2 = "ລະຫັດບໍ່ຄືກັນ"
        }
        setErrors(error);

        if (Object.keys(error).length === 0) {
            setLoadingProcess(true);
            const result = await changePassword(oldpass, newPass1);
            if (result.status) {
                setLoadingProcess(false);
                if (result.message === "success") {

                    Swal.fire({
                        title: "ປ່ຽນລະຫັດສຳເລັດ",
                        text: "ທ່ານຕ້ອງເຂົ້າສູ່ລະບົບໃໝ່",
                        icon: "success"
                    }).then(() => {
                        localStorage.clear();
                        window.location.href = "/login";
                    });
                }
            } else {
                setLoadingProcess(false);
                errorSwal(result.message);
            }

        }
    }
    const handleSubmit = async (e) => {
        console.log(docNo, docName, bankName, accountName, accountNo, docImage, accountQR)
        e.preventDefault();
        const error = {};
        if (!accountName) {
            error.accountName = "ຊື່ບັນຊີ"
        }
        if (!accountNo) {
            error.accountNo = "ເລກບັນຊີ";
        }
        if (!accountQR) {
            error.accountQR = "QR"
        }
        if (!docNo) {
            error.docNo = "ເລກບັດ"
        }
        if (!docImage) {
            error.docImage = "ຮູບພາບບັດ"
        }
        setErrors(error);

        if (Object.keys(error).length === 0) {
            Swal.fire({
                title: "ສົ່ງຂໍ້ມູນຢືນຢັນຕົວຕົນ",
                text: "ຫາກຂໍ້ມູນໄດ້ຮັບການຢືນຢັນແລ້ວ ທ່ານຈະບໍ່ສາມາດປ່ຽນແປງໄດ້",
                icon: 'info',
                showCancelButton: true,
                confirmButtonText: "ສົ່ງ",
                denyButtonText: `ຍົກເລີກ`
            }).then(async (result) => {
                if (result.isConfirmed) {
                    setLoadingProcess(true);
                    const result = await verifyDocument(docNo, docName, bankName, accountName, accountNo, docImage, accountQR);
                    if (result.status) {
                        setLoadingProcess(false)
                        successSwal(result.message)
                    } else {
                        setLoadingProcess(false)
                        errorSwal(result.message)
                    }

                }
            });
        }
    }
    return (
        <>
            <div className='w-full'>
                {loadingProcess &&
                    <div className=" fixed pt-10 lg:p-96 inset-0 :bg-black bg-opacity-25 backdrop-blur-sm z-50">
                        <PulseLoader
                            className=" text-center"
                            color="#36d7b7"
                            size={30}
                            aria-label="Loading Spinner"
                            data-testid="loader" />
                    </div>
                }
                <div className="w-full mt-28 py-5 shadow-lg border p-2 bg-white rounded-xl md:mt-24 lg:mt-24 md:py-8 lg:py-10 lg:px-10">
                    {loading ? <AnimationLoading /> :
                        myProfile &&
                        <div>
                            <div className='w-full grid grid-cols-1 lg:grid-cols-2 gap-5 pb-5'>
                                <div>
                                    <div className='w-full flex justify-center items-center'>
                                        <div className='w-[150px] h-[150px] rounded-full overflow-hidden object-cover'>
                                            <img
                                                alt=''
                                                className=' rounded-full object-cover' src={newProfile ? ShowPF : myProfile.profile ? process.env.REACT_APP_IMGURL+myProfile.profile : iconeUser}
                                                style={{ maxWidth: '100%', height: 'auto', aspectRatio: '1/1' }}
                                            />
                                        </div>
                                    </div>
                                    <div className='flex justify-between items-center'>
                                        <div className='w-full mt-2 flex flex-col items-center '>
                                            <input
                                                type='file'
                                                accept='.png ,.jpg'
                                                onChange={handleSelectProfile}
                                                className='w-full p-2 bg-slate-400 rounded-lg ' />
                                        </div>
                                        {newProfile &&
                                            <div className='w-full mt-2 flex justify-center items-center'>
                                                <button
                                                    onClick={handleUpProfile}
                                                    className='bg-blue-500 p-2 rounded-lg text-white'
                                                >
                                                    ປ່ຽນຮູບ
                                                </button>
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className='w-full grid grid-cols-2 gap-3'>
                                    <div className='w-full'>
                                        <div className='w-full'>
                                            <p>ຊື່</p>
                                            <input type='text' className="w-full p-3 border rounded-lg bg-white" value={myProfile.firstName} readOnly />
                                        </div>
                                        <div className='w-full mt-3'>
                                            <p>ເບີໂທລະສັບ</p>
                                            <input type='text' className="w-full p-3 border rounded-lg bg-white" value={myProfile.phoneNumber} readOnly />
                                        </div>
                                        <div className='w-full mt-3'>
                                            <p>ວັນເດືອນປີເກີດ</p>
                                            <input type='text' className="w-full p-3 border rounded-lg bg-white" value={dateFormat(myProfile.birthday)} readOnly />
                                        </div>

                                        <div className='w-full mt-3'>
                                            <p>ເມືອງ</p>
                                            <input type='text' className="w-full p-3 border rounded-lg bg-white" value={myProfile.address && myProfile.address.province} readOnly />
                                        </div>
                                    </div>
                                    <div>
                                        <div className='w-full'>
                                            <p>ນາມສະກຸນ</p>
                                            <input type='text' className="w-full p-3 border rounded-lg bg-white" value={myProfile.lastName} readOnly />
                                        </div>
                                        <div className='w-full mt-3'>
                                            <p>ອີເມວ</p>
                                            <input type='text' className="w-full p-3 border rounded-lg bg-white" value={myProfile.email} readOnly />
                                        </div>
                                        <div className='w-full mt-3'>
                                            <p>ບ້ານຢູ່ປັດຈຸບັນ</p>
                                            <input type='text' className="w-full p-3 border rounded-lg bg-white" value={myProfile.address && myProfile.address.village} readOnly />
                                        </div>
                                        <div className='w-full mt-3'>
                                            <p>ແຂວງ</p>
                                            <input type='text' className="w-full p-3 border rounded-lg bg-white" value={myProfile.address && myProfile.address.district} readOnly />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div >
                                {myProfile.bank.accountNo ?
                                    <div className='w-full mt-5 pt-3 grid grid-cols-1 lg:grid-cols-2 gap-5 border-t-2'>
                                        <div className='w-full'>
                                            <h1 className='text-xl'>ບັນຊີທະນາຄານ</h1>
                                            <div>
                                                <div className='my-2'>
                                                    <span>ທະນາຄານ:<span className='border rounded-lg px-2'>{myProfile.bank.accountName}</span> </span>
                                                </div>
                                                <div className='my-2'>
                                                    <span>ເລກບັນຊີ: <span className='border rounded-lg px-2'>{myProfile.bank.accountNo}</span></span>
                                                </div>
                                                <div className='my-2'>
                                                    <img alt='' src={process.env.REACT_APP_IMGURL+myProfile.bank.accountImage} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='w-full'>
                                            <h1 className='text-xl'>ເອກະສານຢືນຢັນຕົວຕົນ</h1>
                                            <div>
                                                <div className='my-2'>
                                                    <span>ປະເພດບັດ:<span className='border rounded-lg px-2'>{myProfile.doc.docName}</span> </span>
                                                </div>
                                                <div className='my-2'>
                                                    <span>ເລກບັດ: <span className='border rounded-lg px-2'>{myProfile.doc.docNo}</span></span>
                                                </div>
                                                <div className='my-2'>
                                                    <img alt='' src={process.env.REACT_APP_IMGURL+myProfile.doc.docImage} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <div className='w-full border border-red-500 rounded-lg p-5 mb-3'>
                                        <p className='text-center'>ເພື່ອດຳເນີນການເຮັດທຸລະກຳຕ່າງໆ ທ່ານຕ້ອງຢືນຢັນບັນຊີທະນາຄານ ແລະ ເອກະສານປະຈຳໂຕ</p>
                                        <div className='w-full mt-5 pt-3 gap-5 border-t-2 flex justify-center'>
                                            <NavLink to={"/verifyswall"} className=' bg-green-500 text-white p-3 rounded-lg hover:bg-green-700'>ຢືນຢັນຕອນນີ້</NavLink>
                                            {/* <div className='w-full'>
                                                <h1 className='text-xl'>ບັນຊີທະນາຄານ</h1>
                                                <div className=' grid grid-cols-1 lg:grid-cols-2 gap-10'>
                                                    <div>
                                                        <div className='my-2'>
                                                            <span>ທະນາຄານ</span>
                                                            <select
                                                                className={`w-full border p-2 rounded-lg`}
                                                                onChange={e => setBankName(e.target.value)}
                                                            >
                                                                <option value={"BCEL"}>BCEL</option>
                                                                <option value={"JCB"}>JCB</option>
                                                                <option value={"LDB"}>LDB</option>
                                                                <option value={"STB"}>STB</option>
                                                                <option value={"SCB"}>SCB</option>
                                                            </select>
                                                        </div>
                                                        <div className='my-2'>
                                                            <span>ຊື່ບັນຊີ</span>
                                                            <input
                                                                type='text'
                                                                className={`w-full border p-2 rounded-lg 4${errors.accountName && 'border border-red-500'}`}
                                                                onChange={e => setAccontName(e.target.value)}
                                                            />
                                                            {errors.accountName && <span className='text-red-500'>{errors.accountName}</span>}

                                                        </div>
                                                        <div className='my-2'>
                                                            <span>ເລກບັນຊີ</span>
                                                            <input
                                                                type='text'
                                                                className={`w-full border p-2 rounded-lg 4${errors.accountNo && 'border border-red-500'}`}
                                                                onChange={e => setAccontNo(e.target.value)}
                                                            />
                                                            {errors.accountNo && <span className='text-red-500'>{errors.accountNo}</span>}

                                                        </div>
                                                        <div className='my-2'>
                                                            <span>QR</span>
                                                            <input
                                                                type='file'
                                                                accept='.jpg'
                                                                className={`w-full border p-2 rounded-lg 4${errors.accountQR && 'border border-red-500'}`}
                                                                onChange={e => setAccontQR(e.target.files[0])}
                                                            />
                                                            {errors.accountQR && <span className='text-red-500'>{errors.accountQR}</span>}
                                                            {accountQR &&
                                                                <div className='mt-3'>
                                                                    <img alt='' src={URL.createObjectURL(accountQR)} width={200} />
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className='my-2'>
                                                            <span>ປະເພດບັດ</span>
                                                            <select
                                                                className={`w-full border p-2 rounded-lg`}
                                                                onChange={e => setdocName(e.target.value)}
                                                            >
                                                                <option value="ບັດປະຈຳຕົວ" selected>ບັດປະຈຳຕົວ</option>
                                                                <option value="Passport">Passport</option>
                                                                <option value="ໃບຢັ້ງຢືນທີ່ຢູ່">ໃບຢັ້ງຢືນທີ່ຢູ່</option>

                                                            </select>
                                                        </div>
                                                        <div className='my-2'>
                                                            <span>ເລກບັດ</span>
                                                            <input
                                                                type='text'
                                                                className={`w-full border p-2 rounded-lg 4${errors.docNo && 'border border-red-500'}`}
                                                                value={docNo}
                                                                onChange={e => setdocNo(e.target.value)}
                                                            />
                                                            {errors.docNo && <span className='text-red-500'>{errors.docNo}</span>}
                                                        </div>
                                                        <div className='my-2'>
                                                            <span>ຮູບພາບບັດ</span>
                                                            <input
                                                                type='file'
                                                                accept='.jpg'
                                                                className={`w-full border p-2 rounded-lg 4${errors.docImage && 'border border-red-500'}`}

                                                                onChange={e => setDocImage(e.target.files[0])}
                                                            />
                                                            {errors.docImage && <span className='text-red-500'>{errors.docImage}</span>}
                                                            {docImage &&
                                                                <div className='mt-3'>
                                                                    <img alt='' src={URL.createObjectURL(docImage)} width={200} />
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div> */}
                                                {/* <div className='mt-3 flex justify-center items-center'>
                                                    <button
                                                        className='bg-blue-500 py-2 px-5 text-white rounded-lg'
                                                        onClick={handleSubmit}
                                                    >
                                                        ສົ່ງ
                                                    </button>
                                                </div> */}
                                            {/* </div> */}
                                        </div>
                                    </div>
                                }
                            </div>
                            <div className='w-full pt-3 border-t-2'>
                                <div className='w-full lg:w-[50%]'>
                                    <h1 className='text-xl font-bold mb-2'>ປ່ຽນລະຫັດຜ່ານ</h1>
                                    <div className='my-2'>
                                        <input
                                            className={`w-full border-2 rounded-lg p-2 bg-white ${errors.oldpass && 'border border-red-500'}`}
                                            type='password'
                                            placeholder='ລະຫັດເກົ່າ'
                                            value={oldpass}
                                            onChange={e => setOldPass(e.target.value)}
                                        />
                                        {errors.oldpass && <span className='text-red-500'>{errors.oldpass}</span>}
                                    </div>
                                    <div className='my-2'>
                                        <input
                                            className={`w-full border-2 rounded-lg p-2 bg-white ${errors.newPass1 && 'border border-red-500'}`}
                                            type='password'
                                            placeholder='ລະຫັດໃໝ່'
                                            value={newPass1}
                                            onChange={e => setNewPass1(e.target.value)}
                                        />
                                        {errors.newPass1 && <span className='text-red-500'>{errors.newPass1}</span>}
                                    </div>
                                    <div className='my-2'>
                                        <input
                                            className={`w-full border-2 rounded-lg p-2 bg-white ${errors.newPass2 && 'border border-red-500'}`}
                                            type='password'
                                            placeholder='ຢືນຢັນ ລະຫັດໃໝ່'
                                            value={newPass2}
                                            onChange={e => setNewPass2(e.target.value)}
                                        />
                                        {errors.newPass2 && <span className='text-red-500'>{errors.newPass2}</span>}
                                    </div>
                                    <div className='my-2'>
                                        <button
                                            onClick={handleChangPass}
                                            className='w-full py-2 bg-blue-500 text-white rounded-lg'>ປ່ຽນລະຫັດ</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                    }

                </div>
            </div>
        </>
    )
}

const AnimationLoading = () => {
    return (
        <PulseLoader
            color="#36d7b7"
            size={10}
            aria-label="Loading Spinner"
            data-testid="loader" />
    )
}
export default Profile