const HongfahSidebarFeather = () => {
  return (
    <div>
      <div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="100"
          height="450"
          viewBox="0 0 140 531"
          fill="none"
        >
          <path
            d="M-177.883 530.856C-136.628 455.827 -42.7149 410.36 -42.7149 410.36C188.424 295.74 133.842 0 133.842 0C128.459 94.2924 78.7681 253.037 -53.0287 360.751C-184.825 468.464 -177.883 530.856 -177.883 530.856Z"
            fill="url(#paint0_linear_109_18086)"
          />
          <defs>
            <linearGradient
              id="paint0_linear_109_18086"
              x1="-19.244"
              y1="0"
              x2="-19.244"
              y2="530.856"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#00AEEF" />
              <stop offset="1" stopColor="#00AEEF" stopOpacity="0" />
            </linearGradient>
          </defs>
        </svg>
      </div>
      <div className=" absolute mt-[-20.5rem] ">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="130"
          height="350"
          viewBox="0 0 188 454"
          fill="none"
        >
          <path
            d="M-48.303 286.094C-164.868 339.233 -189 453.959 -189 453.959C-120.672 372.423 -96.9121 408.013 46.3002 338.488C189.513 268.957 187.999 0.93689 187.999 0.93689C129.402 141.701 68.262 232.955 -48.303 286.094Z"
            fill="url(#paint0_linear_109_18087)"
          />
          <defs>
            <linearGradient
              id="paint0_linear_109_18087"
              x1="-0.5"
              y1="0.93689"
              x2="-0.5"
              y2="453.959"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#00AEEF" />
              <stop offset="1" stopColor="#00AEEF" stopOpacity="0" />
            </linearGradient>
          </defs>
        </svg>
      </div>
      <div className=" mt-[-9.5rem] absolute ">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="100"
          height="190"
          viewBox="0 0 156 233"
          fill="none"
        >
          <path
            d="M-170.708 232.106C-141.499 202.725 -68.267 198.67 -68.267 198.67C117.933 195.22 155.764 0.121155 155.764 0.121155C103.365 87.8327 33.9422 114.382 -68.4993 147.818C-170.941 181.254 -170.708 232.106 -170.708 232.106Z"
            fill="url(#paint0_linear_109_18088)"
          />
          <defs>
            <linearGradient
              id="paint0_linear_109_18088"
              x1="-7.47243"
              y1="0.121155"
              x2="-7.47243"
              y2="232.106"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#00AEEF" />
              <stop offset="1" stopColor="#00AEEF" stopOpacity="0" />
            </linearGradient>
          </defs>
        </svg>
      </div>
    </div>
  );
};

export default HongfahSidebarFeather;
