import React, { useEffect, useState } from 'react';
import travel3 from '../../assets/travel3.png';
import { NavLink, useParams, useNavigate } from 'react-router-dom';
import { IoSearch } from 'react-icons/io5';
import Avata from '../../assets/Avatar.png';
import ReactPaginate from 'react-paginate';
import { getOne } from '../../api/trip';
import moment from 'moment';
import Swal from 'sweetalert2';
import { IoIosSettings } from "react-icons/io";
// import { Slide } from 'react-slideshow-image';
// import 'react-slideshow-image/dist/styles.css'
function TravelDetail() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [trip, setTrip] = useState([]);
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    fetchData();
  }, [])
  async function fetchData() {
    setLoading(true);
    const data = await getOne(id);
    setTrip(data);
    setLoading(false);
    console.log(data);
  }
  const handlePageClick = (selectedPage) => {
    console.log(`Selected page: ${selectedPage}`);
  }
  console.log(trip)
  function dateFormat(dateString) {
    const dateObject = moment(dateString);
    return dateObject.format("DD-MM-YYYY");
  }
  return (
    <div className='w-full'>
      <div className="w-full mt-20 py-5 shadow-lg border p-2 bg-white rounded-xl  lg:mt-16 md:py-8 lg:py-10 lg:px-10">
        <div className='flex items-center gap-4'>
          <button onClick={() => navigate("/travel")} className='px-2 flex justify-center items-center gap-2 font-bold bg-[#F6F4F4] rounded-xl'>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              className='my-3'
            >
              <g clip-path="url(#clip0_1432_9467)">
                <path
                  d="M16.5283 -1.85948e-07L7.02127 9.52C6.36714 10.1772 5.99991 11.0667 5.99991 11.994C5.99991 12.9213 6.36714 13.8108 7.02127 14.468L16.5393 24L18.6553 21.879L9.13727 12.348C9.04354 12.2542 8.99088 12.1271 8.99088 11.9945C8.99088 11.8619 9.04354 11.7348 9.13727 11.641L18.6453 2.121L16.5283 -1.85948e-07Z"
                  fill="black"
                />
              </g>
              <defs>
                <clipPath id="clip0_1432_9467">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <span>ກັບໄປໜ້າກ່ອນ</span>
          </button>
          <div>
            {trip.isSuccess && <p className='text-blue-500' >ທົວທີ່ສຳເລັດແລ້ວ</p>}
          </div>

        </div>
        <div className='mt-5 w-full'>
          <h1 className='text-2xl lg:text-4xl text-blue-light font-bold text-center'>ລາຍລະອຽດ ແລະ ຜູ້ໂຊກດີ</h1>
        </div>
        <div className='mt-5 w-full'>
          <div className='w-full grid grid-cols-1 lg:grid-cols-2 gap-4'>
            <div className='flex justify-center flex-col items-center pb-5 lg:pb-0 border-b-2 lg:border-none'>
              <span className='text-2xl lg:text-3xl font-bold'>
                {trip.placeName}
              </span>
              <div className='flex justify-center items-center'>
                <img src={process.env.REACT_APP_IMGURL + trip.cover} alt="" className='w-full rounded-xl' />
              </div>
              <div className='w-[60%]'>
                <div className=' flex justify-between w-full mt-4  '>
                  <span className=' text-lg font-medium text-gray-500 '> ມື້ເດີນທາງ </span>
                  <span className=' text-xl font-bold '>{dateFormat(trip.departureDate)} </span>
                </div>
                <div className=' flex justify-between w-full mt-4  '>
                  <span className=' text-lg font-medium text-gray-500 '> ລະດັບ  </span>
                  <span className=' text-xl font-bold '> {trip.level} </span>
                </div>
                <div className=' flex justify-between w-full mt-3  '>
                  <span className=' text-lg font-medium text-gray-500 '> ໄລຍະເວລາ</span>
                  <span className=' text-xl font-bold '> {trip.period}</span>
                </div>
              </div>
            </div>
            <div className='w-full mt-3 h-[400px] overflow-auto rounded-lg'>
              <table className="w-full lg:w-full table-auto ">
                <thead className="w-full rounded-t-xl bg-gradient-to-t from-blue-dark to-blue-light text-white font-normal sticky top-0">
                  <tr className="">
                    <th className="p-3 border">
                      ລະຫັດສະມາຊິກ
                    </th>
                    <th className=" border">
                      ຊື່ສະມາຊິກ
                    </th>
                    <th className=" border">
                      ເບີໂທ
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {trip && trip.members &&
                    trip.members.length > 0 &&
                    trip.members.map((member) => {
                      return (
                        <tr className="border-b-2">
                          <td className="flex justify-center items-center py-2">
                            {member?.userCode}
                          </td>
                          <td className="">
                            {member?.firstName}{" "}{member?.lastName}
                          </td>
                          <td className="text-center">
                            {member.phoneNumber}
                          </td>
                        </tr>
                      )
                    })
                  }
                </tbody>
              </table>
            </div>
          </div>
          {!trip.isSuccess &&
            <div className=' w-full text-center text-lg text-blue-light font-medium pt-5'>
              <span>ເມື່ອໃກ້ຮອດທິບທ່ອງທຽ່ວ ທາງທີມງານຈະຕິດຕໍ່ຄອນເຟີມກັບສະມາຊິກຜູ້ໂຊກດີອີກຄັ້ງ</span>
            </div>
          }

        </div>
      </div>
      {trip.isSuccess &&
        <div className="w-full mt-10 py-5 shadow-lg border p-2 bg-white rounded-xl  lg:mt-16 md:py-8 lg:py-10 lg:px-10">
          <div className='mt-5 w-full'>
            <h1 className='text-2xl lg:text-4xl text-blue-light font-bold text-center'>ຮູບພາບ ທິບທ່ອງທຽ່ວ</h1>
          </div>
          <div className='pt-4 grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-5'>
            {trip.images &&
              trip.images.map((imglink => {
                const handleIMG = () => {
                  Swal.fire({
                    imageUrl: imglink,
                    imageAlt: "Picture"
                  });
                }
                return (
                  <div onClick={handleIMG} className='flex justify-center items-center'>
                    <img className='w-full  rounded-lg' alt='' src={process.env.REACT_APP_IMGURL + imglink} />
                  </div>
                )
              }))
            }
          </div>
        </div>
      }
    </div>
  );
}

export default TravelDetail;
