import { apiUrl } from './apiconfig';
import axios from "axios";

export const getTrips = async () => {
    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: apiUrl + '/trip/getall',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token()
        }
    };
    try {
        const response = await axios.request(config);
        if (response.data.status) {
            //console.log(response.data.data)
            return response.data.data
        } else {
            return false;
        }
    } catch (error) {
        console.error(error);
        return false;
    }
}
export const getOne = async (id) => {
    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: apiUrl + '/trip/getone/'+id,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token()
        }
    };
    try {
        const response = await axios.request(config);
        if (response.data.status) {
            //console.log(response.data.data)
            return response.data.data
        } else {
            return false;
        }
    } catch (error) {
        console.error(error);
        return false;
    }
}
function token() {
    return localStorage.getItem("token")
};