import { useEffect, useState } from "react"
import { getAllPosition } from "../../api/position"
import { PulseLoader } from 'react-spinners'
import packagesmall from '../../assets/packagesmall.svg'
import packagebig from '../../assets/packagebig.svg'
import packagevactor from '../../assets/packagevactor.svg'
const AnimationLoading = () => {
    return (
        <PulseLoader
            color="#36d7b7"
            size={10}
            aria-label="Loading Spinner"
            data-testid="loader" />
    )
}

export const Position = () => {
    const [positions, setPositions] = useState([])
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchdata();
    }, [])

    async function fetchdata() {
        setLoading(true);
        const data = await getAllPosition();
        setPositions(data);
        setLoading(false);
    }
    return (
        <>
            <div className="w-full">
                <div className="w-full mt-20 lg:mt-16 py-5 shadow-lg border p-2 bg-white rounded-xl md:py-8 lg:py-10 lg:px-10">
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
                        {loading ? <div className="flex"><AnimationLoading /></div> :
                            positions.map((item) => {
                                return (
                                    <div className="flex justify-center px-10 md:px-2 lg:px-0">
                                        <div className='w-full border border-blue-light rounded-lg overflow-hidden '>
                                            <div className=' relative flex justify-center w-full h-20 bg-gradient-to-r from-blue-light to-blue-dark '>
                                                <img src={packagesmall}
                                                    alt=""
                                                    className=' absolute left-0' />
                                                <div className='w-full flex justify-center  items-center gap-3'>
                                                    <div className="overflow-hidden">
                                                        <img src={item.icon} alt="" width={40} />
                                                    </div>
                                                    <div className="">
                                                        <span className='w-full text-xl font-medium text-white '>ຕຳແໜ່ງ {item.title}</span>
                                                    </div>
                                                </div>
                                                <img src={packagebig}
                                                    alt=""
                                                    className=' absolute right-0 bottom-0' />
                                            </div>
                                            <div className=' flex w-full justify-center '>
                                                <div className=' w-[95%]'>
                                                    <div className=' font-bold flex flex-col items-center   '>
                                                        <div className=' flex gap-2 items-center self-start my-2'>
                                                            <img src={packagevactor}
                                                                alt="" />
                                                            <span className=' text-blue-light '>ເງື່ອນໄຂ</span>
                                                        </div>
                                                        <div className=' w-[90%] flex justify-between '>
                                                            <span>ມີລູກທີມ:</span>
                                                            <span><span className="text-green-500">{item.conditionChildren} </span>ຄົນຂຶ້ນໄປ</span>
                                                        </div>
                                                        <div className=' w-[90%] flex justify-between '>
                                                            <span>ມີຍອດຂາຍ:</span>
                                                            <span><span className="text-green-500">{item.conditionPosChildrenNumber}</span> ສິນຄ້າຂຶ້ນໄປ</span>
                                                        </div>
                                                        <div className=' w-[90%] flex justify-between '>
                                                            <span>ມີຄະແນນ:</span>
                                                            <span><span className="text-green-500">{item.conditionPv}</span> PV ຂຶ້ນໄປ</span>
                                                        </div>
                                                        <div className=' w-[90%] flex justify-between '>
                                                            <span>ຕ້ອງຢູ່ຕຳແໜ່ງ:</span>
                                                            <span className="">{item.conditionPosition ? item.conditionPosition.title : '...'}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className=' font-bold flex flex-col items-center   '>
                                                        <div className=' flex gap-2 items-center self-start my-2'>
                                                            <img src={packagevactor}
                                                                alt="" />
                                                            <span className=' text-blue-light '>ເພີ່ມເຕີມ</span>
                                                        </div>
                                                        <div className=' w-[90%] flex justify-between '>
                                                            <span className="text-gray-400">{item.details}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>

                <div className="flex justify-start mb-10">
                    <div className='w-full md:flex lg:flex rounded-b-lg shadow-lg border items-center bg-white mt-10 gap-3'>
                        {loading ? <div className="flex"><AnimationLoading /></div> :
                            positions.map((item) => {
                                return (
                                    <div className='flex items-center gap-3 px-6 '>
                                        <img src={item.icon}
                                            alt=""
                                            className="w-[60px]" />
                                        <div>
                                            <h1 className=' font-bold'>
                                                {item.title}</h1>
                                            <div className=' flex items-center gap-2 text-gray-500'>
                                                <p>{item.numberUserThisPosition}</p>
                                                <p>ຄົນ</p>
                                            </div>

                                        </div>
                                    </div>
                                )
                            })}
                    </div>
                </div>
            </div>

        </>
    )
}