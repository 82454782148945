import { NavLink, useNavigate, useParams } from "react-router-dom"
import { useState, useEffect } from "react";
import { IoCloudUploadOutline } from "react-icons/io5";
import S from '../../assets/Frame (1).svg'
import M from '../../assets/Frame (2).svg'
import L from '../../assets/Frame (3).svg'
import packagevactor from '../../assets/packagevactor.svg'
import packagesmall from '../../assets/packagesmall.svg'
import packagebig from '../../assets/packagebig.svg'
import paybankaccount from "../../assets/paybankaccount.png";
import paywallet from "../../assets/paywallet.png";
import billqr from "../../assets/billqr.png";
import bankgray from "../../assets/bankgray.png";
import ewalletwhite from "../../assets/ewalletwhite.png";
import cash from "../../assets/cash.png";
import walleticon from "../../assets/wallet.png";
import Swal from "sweetalert2";
import { getMyWallet } from "../../api/ewallet";
import { getOnePackage, createOr, createOrderPackageWithBCELONE, createOrderPackageWithEwallet } from "../../api/package";
import verify from '../../assets/verify.png';
import { PulseLoader } from "react-spinners";
const AnimationLoading = () => {
    return (
        <PulseLoader
            color="#36d7b7"
            size={10}
            aria-label="Loading Spinner"
            data-testid="loader" />
    )
}
export default function BuyPackage() {
    const { id } = useParams()
    const navigate = useNavigate();
    const [pk, setPK] = useState([])
    const [payChoice, setPayChoice] = useState('');
    const [wallet, setWallet] = useState([]);
    const [selectedImage, setSelectedImage] = useState(null);
    const [passwordWallet, setPasswordWallet] = useState('')
    const [errors, setErrors] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadPay, setLoadPay] = useState(false)

    useEffect(() => {
        fetchdata();
    }, [])

    async function fetchdata() {
        setLoading(true);
        const data1 = await getOnePackage(id)
        const data2 = await getMyWallet();
        if (data2) {
            setPayChoice('ewallet');
        } else {
            setPayChoice('bcelone');
        }
        setPK(data1);
        setWallet(data2);
        setLoading(false);
    }
    const handleImage = (e) => {
        setSelectedImage(e.target.files[0])
    }
    const handleSubmit = async () => {
        const error = {};
        if (payChoice === 'bcelone') {
            if (!selectedImage) {
                error.slip = "ອັບໂຫລດໃບບີນ"
            }
        } else {
            if (!passwordWallet) {
                error.password = "ລະຫັດກະເປົ໋າ"
            }
        }
        setErrors(error);
        if (Object.keys(error).length === 0) {
            if (payChoice === 'bcelone') {
                setLoading(true);
                const x = await createOrderPackageWithBCELONE(id, selectedImage);
                setLoading(false);
                if (x.status) {
                    Swal.fire({
                        title: "ສຳເລັດ!",
                        text: "ທ່ານໄດ້ໂອນເງິນເພື່ອຊື້ແພັກເກັດ (ລໍຖ້າແອັດມິນກວດສອບ): ",
                        icon: "success"
                    }).then(() => {
                        navigate("/package")
                    });
                } else {
                    Swal.fire({
                        title: "ຊື້ແພັກເກັດ ບໍ່ສຳເລັດ!",
                        text: x.message,
                        icon: "error"
                    });
                }
            } else {
                Swal.fire({
                    title: 'ຊື້ແພັກເກັດ',
                    text: 'ລະບົບຈະຫັກເງິນໃນວໍເລັດທ່ານອັດຕະໂນມັດ',
                    icon: 'question',
                    showCancelButton: true,
                    confirmButtonColor: "#00AEEF",
                    confirmButtonText: "ຢືນຢັນ",
                    cancelButtonText: "ຍົກເລີກ",
                    cancelButtonColor: "#ffffff",
                    cancelButtonText: '<span style="color: blue;">ພາຍຫຼັງ</span>',
                    reverseButtons: true,
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        setLoading(true);
                        const x = await createOrderPackageWithEwallet(id, passwordWallet);
                        setLoading(false);

                        if (x.status) {
                            Swal.fire({
                                title: "ສຳເລັດ!",
                                html: "ທ່ານໄດ້ຊື້ແພັກເກັດ (ລໍຖ້າແອັດມິນກວດສອບ)",
                                icon: "success"
                            }).then(() => {
                                navigate("/package")
                            });
                        } else {
                            Swal.fire({
                                title: "ຊື້ແພັກເກັດ ບໍ່ສຳເລັດ!",
                                text: x.message,
                                icon: "error"
                            });
                        }
                    } else {

                    }
                });
            }
        }



    }
    const handlePayChoice = async (x) => {
        if (x === 'bcelone') {
            setPayChoice('bcelone')
        } else {
            setPayChoice('ewallet')
            const wallet = await getMyWallet()
            if (wallet) {
                setWallet(wallet)
            } else {
                Swal.fire({
                    html: '<div class="flex flex-col items-center">' +
                        '<img src="' + verify + '" alt="verify" class="w-16 h-16 mb-4" />' +
                        '<h2 class="text-lg font-semibold mb-2">ທ່ານຍັງບໍ່ທັນຢືນຢັນຕົວຕົນ</h2>' +
                        '<p>ທ່ານຕ້ອງຢືນຢັນຕົວຕົນກ່ອນ ຈຶ່ງຈະສາມາດນຳໃຊ້ຟັງຊັ້ນຕ່າງໆຂອງພວກເຮົາໄດ້</p>' +
                        '</div>',
                    showCancelButton: true,
                    confirmButtonColor: "#00AEEF",
                    confirmButtonText: "ຢືນຢັນ",
                    cancelButtonText: "ພາຍຫຼັງ",
                    cancelButtonColor: "#ffffff",
                    cancelButtonText: '<span style="color: blue;">ພາຍຫຼັງ</span>',
                    reverseButtons: true,
                }).then((result) => {
                    if (result.isConfirmed) {
                        navigate("/verifyswall")
                    } else {
                        setPayChoice('bcelone')
                    }
                });
            }
        }
    }
    var icon;
    if (pk.packageName) {
        let leftPart = pk.packageName.substring(0, 1);
        icon = leftPart;
    }
    return (
        <div className='mt-24 w-full'>
            {loading ?
                <div className=" fixed p-96 inset-0 :bg-black bg-opacity-25 backdrop-blur-sm z-50">
                    <PulseLoader
                        className=" text-center"
                        color="#36d7b7"
                        size={30}
                        aria-label="Loading Spinner"
                        data-testid="loader" />
                </div>
                : ''}
            <div className='w-[96%] shadow-lg border my-6 bg-white rounded-xl'>
                <div className="m-5">
                    <NavLink
                        to="/package"
                        className='flex items-center justify-center w-40 gap-4 font-bold bg-[#F6F4F4] rounded-xl'
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            className='my-3'
                        >
                            <g clip-path="url(#clip0_1432_9467)">
                                <path
                                    d="M16.5283 -1.85948e-07L7.02127 9.52C6.36714 10.1772 5.99991 11.0667 5.99991 11.994C5.99991 12.9213 6.36714 13.8108 7.02127 14.468L16.5393 24L18.6553 21.879L9.13727 12.348C9.04354 12.2542 8.99088 12.1271 8.99088 11.9945C8.99088 11.8619 9.04354 11.7348 9.13727 11.641L18.6453 2.121L16.5283 -1.85948e-07Z"
                                    fill="black"
                                />
                            </g>
                            <defs>
                                <clipPath id="clip0_1432_9467">
                                    <rect width="24" height="24" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                        <span>ກັບໄປໜ້າກ່ອນ</span>
                    </NavLink>
                </div>
                <div>
                    <p className="text-center text-[35px] text-green-500 font-bold">ລາຍລະອຽດເເພັກເກັດ</p>

                    {loading ?
                        <AnimationLoading />
                        :
                        <div><div className=' flex justify-center w-full h-20 bg-gradient-to-r from-blue-light to-blue-dark '>
                            <img src={packagesmall}
                                alt=""
                                className='' />
                            <div className=' w-[100%] flex justify-center items-center '>
                                <img src={
                                    pk.packageName ?
                                        icon ?
                                            icon === 'S' ?
                                                S
                                                : icon === 'M' ?
                                                    M
                                                    : icon === 'L' ?
                                                        L : ''
                                            : ''
                                        : ''}
                                    alt=""
                                    className=' px-3 ' />
                                <span className=' text-2xl font-medium text-white '>ແພັກເກດ {pk.packageName}
                                </span>
                            </div>
                            <img src={packagebig}
                                alt=""
                                className='mt-8  flex ' />
                        </div>
                            <div className=' flex w-full justify-center '>
                                <div className=' w-[30%]'>
                                    <div className=' font-bold flex flex-col items-center   '>
                                        <div className=' flex gap-2 items-center self-start my-2'>
                                            <img src={packagevactor}
                                                alt="" />
                                            <span className=' text-blue-light '>title</span>
                                        </div>
                                        <div className=' w-[90%] flex justify-between '>
                                            <span>title:</span>
                                            <span>{pk.title ? pk.title : ''} {pk.other ? pk.other : ''}
                                            </span>
                                        </div>
                                    </div>
                                    <div className=' font-bold flex flex-col items-center   '>
                                        <div className=' flex gap-2 items-center self-start my-2'>
                                            <img src={packagevactor}
                                                alt="" />
                                            <span className=' text-blue-light '>ຄະແນນ</span>
                                        </div>
                                        <div className=' w-[90%] flex justify-between '>
                                            <span>ຄະແນນ:</span>
                                            <span>{pk.PV} PV
                                            </span>
                                        </div>
                                    </div>
                                    <div className=' font-bold flex flex-col items-center   '>
                                        <div className=' flex gap-2 items-center self-start my-2'>
                                            <img src={packagevactor}
                                                alt="" />
                                            <span className=' text-blue-light '>ຄ່າແນະນຳ</span>
                                        </div>
                                        <div className=' w-[90%] flex justify-between '>
                                            <span>ຄ່າແນະນຳ:</span>
                                            <span>{pk.recommendedFee ? pk.recommendedFee.toLocaleString() : ''} k
                                            </span>
                                        </div>
                                    </div>
                                    <div className=' font-bold flex flex-col items-center   '>
                                        <div className=' flex gap-2 items-center self-start my-2'>
                                            <img src={packagevactor}
                                                alt="" />
                                            <span className=' text-blue-light '>percentShare</span>
                                        </div>
                                        <div className=' w-[90%] flex justify-between '>
                                            <span>percentShare:</span>
                                            <span>{pk.percentShare ? pk.percentShare + "%" : ''}
                                            </span>
                                        </div>
                                    </div>
                                    <div className=' flex w-[95%] my-3 justify-between '>
                                        <span>
                                            ລາຄາການຊື້:
                                        </span>
                                        <span className=' text-lg font-bold text-blue-light '>
                                            {pk.price ? pk.price.toLocaleString() : ''} k
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
            <div className='w-[96%] shadow-lg border mt-16 bg-white rounded-xl'>
                <div className="">
                    <div className=" w-full my-6 sm:flex sm:justify-around ">
                        <div
                            onClick={() => { handlePayChoice('bcelone') }}
                            className={`w-[35%] border bg-gradient-to-r  flex items-center text-3xl gap-6 justify-center h-24 rounded-xl font-bold ${payChoice === 'bcelone' ? 'from-blue-dark to-blue-light text-white' : 'from-gray-400 to-gray-200 text-gray-500'}`}>
                            <img src={payChoice === 'bcelone' ? paybankaccount : bankgray} alt="" /> ຊຳລະຜ່ານບັນຊີທະນາຄານ
                        </div>
                        <div
                            onClick={() => { handlePayChoice('ewallet') }}
                            className={`w-[35%] border bg-gradient-to-t  flex items-center text-3xl gap-6 justify-center h-24 rounded-xl font-bold ${payChoice === 'ewallet' ? 'from-blue-dark to-blue-light text-white' : 'from-gray-400 to-gray-200 text-gray-500'}`}>
                            <img src={payChoice === 'ewallet' ? ewalletwhite : paywallet} alt="" /> ຊຳລະຜ່ານກະເປົ່າ Ewallet
                        </div>
                    </div>
                </div>
                {payChoice === 'bcelone' ?
                    <>
                        <div className=" w-full flex justify-center  flex-col items-center">
                            <div className=" my-12 text-blue-light text-4xl font-bold ">
                                <span>ຊຳລະເງິນຜ່ານ BCEL ONE</span>
                            </div>
                            <div className=" sm:flex sm:gap-12 ">
                                <div>
                                    <img src={billqr} alt="" />
                                </div>
                                <div>
                                    <span className=" text-xl font-medium ">ອັບໂຫລດໃບບີນ</span>
                                    <div>
                                        <div className=" border-2 overflow-hidden rounded-xl h-auto w-[30rem]">
                                            {selectedImage ? (
                                                <img
                                                    src={URL.createObjectURL(selectedImage)}
                                                    className=" h-full w-[100px] mx-auto object-cover"
                                                    alt=""
                                                />
                                            ) : ''}
                                            <div className="relative  border-gray-300 p-6 mx-auto mt-3 h-[rem] w-[20rem] rounded-full">
                                                <div className="text-center flex flex-col items-center gap-3">
                                                    <IoCloudUploadOutline className=" text-gray-400 w-16 h-20" />
                                                    <h2 className="mt-3 text-gray-400 font-medium font-Noto text-2xl ">
                                                        Drag & Drop files here
                                                    </h2>
                                                    <h3 className=" text-gray-400">or</h3>
                                                    <button className=" border-2 border-blue-light text-blue-light font-bold text-lg w-40 h-12 rounded-lg">
                                                        {" "}
                                                        Browse Files{" "}
                                                    </button>
                                                </div>
                                                <input
                                                    type="file"
                                                    accept=".jpg"
                                                    className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                                                    onChange={handleImage}
                                                />
                                            </div>
                                            {errors.slip && <span className='pt-1 text-red-500'>{errors.slip}</span>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className=" flex w-full my-4 flex-col text-2xl font-medium ">
                                <div className=" flex self-end items-center gap-12 mr-20 ">
                                    <span> ຈຳນວນເງິນທີ່ຕ້ອງໂອນ </span>
                                    <div className=" flex  items-center text-3xl font-bold gap-2 ">
                                        <h1 className=" "> {pk.price ? pk.price.toLocaleString() : ''} </h1>
                                        <h1>ກີບ</h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                    :
                    <>

                        <div className=" w-full">
                            <div className=" my-12 text-blue-light text-4xl font-bold text-center">
                                <span>ຊຳລະເງິນຜ່ານ Ewallet</span>
                            </div>
                            <div className=" w-[95%] flex justify-center ">
                                <div className="w-[95%]  ">
                                    <div className=" my-8  ">
                                        <label htmlFor="branch" className=" font-medium ">
                                            {" "}
                                            ເງີນໃນ Wallet ປັດຈຸບັນ
                                        </label>
                                        <div className="flex items-center border border-blue-dark rounded-lg font-medium ">
                                            <img src={cash} alt="" className=" my-4 px-4 " />
                                            <h1> {wallet ? wallet.balance ? wallet.balance.toLocaleString() + " ກີບ" : '...' : '...'} </h1>
                                        </div>
                                    </div>
                                    <div className=" my-8 relative">
                                        <label htmlFor="branch" className=" font-medium ">
                                            {" "}
                                            ລະຫັດກະເປົ໋າ
                                        </label>
                                        <img src={walleticon} alt="" className="absolute left-4 top-10" />
                                        <input type="password"
                                            value={passwordWallet}
                                            onChange={(e) => setPasswordWallet(e.target.value)}
                                            className="w-full py-4 px-14 flex items-center border border-blue-dark rounded-lg font-medium " />
                                        {errors.password && <span className='pt-1 text-red-500'>{errors.password}</span>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className=" flex w-full my-4 flex-col text-2xl font-medium ">
                            <div className=" flex self-end items-center gap-12 mr-20 ">
                                <span> ລະບົບຈະຫັກເງີນໃນກະເປົາຂອງທ່ານ </span>
                                <div className=" flex  items-center text-3xl font-bold gap-2 ">
                                    <h1 className=" ">{pk.price ? pk.price.toLocaleString() : ''} </h1>
                                    <h1>ກີບ</h1>
                                </div>
                            </div>
                        </div>
                    </>}
                <div className="w-full flex justify-end pe-20 pb-20">
                    <button onClick={handleSubmit} className=" flex justify-center items-center border rounded-lg h-[80px] w-[30%]  bg-gradient-to-r from-blue-light to-blue-dark text-white text-xl font-bold ">
                        {" "}
                        ໂອນ E-Wallet{" "}
                    </button>
                </div>

            </div>

        </div>
    )

}