import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PulseLoader from "react-spinners/PulseLoader";
import Swal from "sweetalert2";
import verify from '../../assets/verify.png';
// import Table from "./BonuscomponentTable";
import { getMyBounus } from "../../api/bonus";
import { FaKipSign } from "react-icons/fa6";
import moment from "moment";
import { ExportToXLSX } from "../../serviec/exportFile";
const AnimationLoading = () => {
  return (
    <PulseLoader
      color="#36d7b7"
      size={10}
      aria-label="Loading Spinner"
      data-testid="loader" />
  )
}
function dateFormat(dateString) {
  const dateObject = moment(dateString);
  return dateObject.format("DD/MM/YYYY");
}

function Bonus() {
  const { navigate } = useNavigate();
  const currentDate = new Date();
  const today = currentDate.toISOString().split('T')[0];
  const oneYearAgo = new Date(currentDate);
  oneYearAgo.setFullYear(currentDate.getFullYear() - 1);
  const oneYearAgoString = oneYearAgo.toISOString().split('T')[0];

  const [bonus, setBonus] = useState([]);
  const [bonusCurrent, setBonusCurrent] = useState([]);
  const [dateEnd, setDateEnd] = useState("");
  const [dateStart, setDateStart] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchdata();
  }, [])
  async function fetchdata() {
    setLoading(true);
    const data = await getMyBounus();

    if (data) {
      setBonus(data);
      setBonusCurrent(data);
    } else {
      Alert();
    }
    setDateStart(oneYearAgoString);
    setDateEnd(today);
    setLoading(false);
  }
  const handleExport = () => {
    //console.log(bonusCurrent);
    if (bonusCurrent.length !== 0) {
      Swal.fire({
        title: "ພິມລາຍງານ ໂບນັດ",
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: "ພິມລາຍງານ",
        cancelButtonText: "ຍົກເລີກ",
        width:"340px"

      }).then((result) => {
        if (result.isConfirmed) {
          const data = []
          for (const i of bonusCurrent.slice().reverse()) {
            const x = {
              'ຕຳແໜ່ງ': i.positionName,
              'ວັນທີ່	': new Date(i.createdAt).toISOString().split('T')[0],
              'ລູກທີມ	': i.children_count,
              'ຄະແນນສ່ວນຕົວ	': i.PV,
              'ຄະແນນທີມ	': i.teamePV,
              'ໂບນັດແນະນຳ': i.recommended.toLocaleString(),
              'ໂບນັດບໍລິຫານທີມ': i.bonusTeamePV.toLocaleString(),
              'ໂບນັດຂື້ນຕ່ຳແຫນ່ງ': i.bonus_postition.toLocaleString(),
              'ເງິນລວມ': i.totalBonus.toLocaleString(),

            }
            data.push(x);
          }
          ExportToXLSX(data, `ລາຍການໂບນັດ${dateStart}-${dateEnd}`);
        }
      });
    } else {
      Swal.fire({
        title: 'ບໍ່ມີລາຍການສິນຄ້າ',
        icon: 'info',
        width:"340px"
      })

    }
  }
  const handleDateStart = async (e) => {
    setDateStart(e.target.value)
    const tempOrder = [];
    for (const i of bonus) {
      if ((new Date(i.createdAt) >= new Date(e.target.value)) && (new Date(i.createdAt) <= new Date(dateEnd))) {
        tempOrder.push(i);
      }
    }
    setBonusCurrent(tempOrder);
  }
  const handleDateEnd = async (e) => {
    setDateEnd(e.target.value);
    const tempOrder = [];
    for (const i of bonus) {
      if ((new Date(i.createdAt) >= new Date(dateStart)) && (new Date(i.createdAt) <= new Date(e.target.value))) {
        tempOrder.push(i);
      }
    }
    setBonusCurrent(tempOrder);
  }
  const Alert = () => {
    return (
      Swal.fire({
        html: '<div class="flex flex-col items-center">' +
          '<img src="' + verify + '" alt="verify" class="w-16 h-16 mb-4" />' +
          '<h2 class="text-lg font-semibold mb-2">ທ່ານຍັງບໍ່ທັນຢືນຢັນຕົວຕົນ</h2>' +
          '<p>ທ່ານຕ້ອງຢືນຢັນຕົວຕົນກ່ອນ ຈຶ່ງຈະສາມາດນຳໃຊ້ຟັງຊັ້ນຕ່າງໆຂອງພວກເຮົາໄດ້</p>' +
          '</div>',
        showCancelButton: true,
        confirmButtonColor: "#00AEEF",
        confirmButtonText: "ຢືນຢັນ",
        cancelButtonText: "ພາຍຫຼັງ",
        cancelButtonColor: "#ffffff",
        cancelButtonText: '<span style="color: blue;">ພາຍຫຼັງ</span>',
        reverseButtons: true,
        width:"340px"
      }).then((result) => {
        if (result.isConfirmed) {
          navigate("/verifyswall")
        } else {
          navigate("/")
        }
      })
    )
  }
  const ShowData = (item) => {
    Swal.fire({
      html: `
      <div class='px-4 w-full flex flex-col items-start text-[13px]'> 
        <div class='w-full flex justify-between items-center border rounded-lg mb-2'>
          <div class='w-full text-center'>
            <p>ລະຫັດຜູ້ຮັບ: ${item.userCode}</p>
            <p>ຜູ້ຮັບ: ${item.firstName + ' ' + item.lastName}</p>
            <p>ຕຳແໜ່ງ: ${item.positionName}</p>
          </div>
          <div class='w-full flex justify-start items-center'>
            <img alt='' src=${item.profile} width="50"/>
          </div>
        </div>
        <div class="ms-6 lg:ms-0 text-start">
          <p>ລູກທີມ :${item.children_count} ຄົນ</p>
          <p>ຄະແນນສ່ວນຕົວ: ${item.bonus_postition} ຄະແນນ</p> 
          <p>ຄະແນນທີມ: ${item.teamePV}</p>
          <p class=" font-bold">ໂບນັດແນະນຳ: ${item.recommended.toLocaleString()} ກີບ</p>
          <p class=" font-bold">ໂບນັດບໍລິຫານທີມ: ${item.bonusTeamePV.toLocaleString()} ກີບ</p>
          <p class=" font-bold">ໂບນັດຂື້ນຕ່ຳແຫນ່ງ: ${item.bonus_postition.toLocaleString()} ກີບ</p>
          <p class=" font-bold">ເງິນລວມ: ${item.totalBonus.toLocaleString()} ກີບ</p>
          <p>ສະຖານະ:${item.isActive ? '<span class="ms-4 text-green-500">ຮັກສາຍຍອດໄດ້</span>' : '<span class="ms-4 text-red-500">ຕ້ອງຮັກສາຍຍອດ</span>'}</p>
        </div>
      </div>
    `,
    width:"340px"
    });
  }
  return (
    <div className="w-full ">
      <div className="w-full mt-20 lg:mt-16 py-5 shadow-lg border p-2 bg-white rounded-xl md:py-8 lg:py-10 lg:px-10">
        <div className="mb-3 lg:mb-0 lg:flex justify-between items-center">
          <h1 className="text-xl md:text-2xl lg:text-2xl font-bold ">ລາຍກາຍຈ່າຍໂບນັດ</h1>
          <div className="flex justify-end items-center gap-5 text-sm">
            <div className="flex justify-end items-center gap-3">
              <div class='w-full'>
                <input
                  type='date'
                  class='border-2 p-2 rounded-lg bg-white'
                  value={dateStart}
                  max={dateEnd}
                  onChange={handleDateStart}
                />
              </div>
              <span>ຫາ</span>
              <div className="w-full">
                <input
                  type='date'
                  class='border-2 p-2 rounded-lg bg-white'
                  min={dateStart}
                  max={today}
                  value={dateEnd}
                  onChange={handleDateEnd}
                />
              </div>
            </div>
            <button
              onClick={handleExport}
              className="lg:p-3  bg-gradient-to-t from-blue-dark to-blue-light font-bold text-white rounded-lg ">
              Export to Excel
            </button>
          </div>
        </div>
        <div className="mt-5 h-[550px] lg:h-[400px] overflow-auto">
          <table className=" text-xs w-[1000px] lg:w-full table-auto">
            <thead className="bg-[#beebff] text-blue-dark font-bold sticky top-0">
              <tr className="">
                <th className="p-3">
                  ຕຳແໜ່ງ
                </th>
                <th className=" ">
                  ວັນທີ່
                </th>
                <th className=" ">
                  ລູກທີມ
                </th>
                <th className="">
                  ຄະແນນສ່ວນຕົວ
                </th>
                <th className=" ">
                  ຄະແນນທີມ
                </th>
                <th className="">
                  ໂບນັດແນະນຳ
                </th>
                <th className=" ">
                  ໂບນັດບໍລິຫານທີມ
                </th>
                <th className=" ">
                  ໂບນັດຂື້ນຕ່ຳແຫນ່ງ
                </th>
                <th className=" ">
                  ເງິນລວມ
                </th>
                <th className=" ">
                  ສະຖານະ
                </th>
              </tr>
            </thead>
            <tbody>
              {loading ? <AnimationLoading /> :
                bonusCurrent.slice().reverse().map((item, index) => {
                  return (
                    <tr key={index} onClick={() => ShowData(item)} className="border border-slate-300">
                      <td className="p-2 text-center">
                        {item.positionName ? item.positionName : 'ບໍ່ມີຕຳແໜ່ງ'}
                      </td>
                      <td className="text-center">
                        <h1 className=" ">
                          {new Date(item.createdAt).toISOString().split('T')[0]}
                        </h1>
                      </td>
                      <td className="text-center">
                        {item.children_count}
                      </td>
                      <td className="text-center">
                        {item.bonus_postition} PV
                      </td>
                      <td className="text-center">
                        {item.teamePV.toLocaleString()} PV
                      </td>
                      <td className="text-center">
                        {item.recommended.toLocaleString()} ກີບ
                      </td>
                      <td className="text-center">
                        {item.bonusTeamePV.toLocaleString()} ກີບ
                      </td>
                      <td className="text-center">
                        {item.bonus_postition.toLocaleString()} ກີບ
                      </td>
                      <td className="text-center">
                        {item.totalBonus.toLocaleString()} ກີບ
                      </td>

                      <td className="text-center">
                        {item.isActive ? <span className="text-green-500">ຮັກສາຍຍອດໄດ້</span> : <span className="text-red-500">ຕ້ອງຮັກສາຍຍອດ</span>}
                      </td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default Bonus;
