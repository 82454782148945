import { Routes, Route, BrowserRouter as Router } from "react-router-dom";
// import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import PrivateRoute from "./PrivateRoute"
import {
  Login,
  Dashboard,
  Product,
  Order,
  SaleHistory,
  BuySale,
  Member,
  Productdetail,
  AddProduct,
  Error,
  Register,
  RegisterPhonenumber,
  RegisterSuccess,
  ProductOrder,
  ProductPay,
  PayChoice,
  Ewallet,
  Paysuccess,
  MemberTable,
  Bonus,
  EwalletWithdraw,
  EwalletMoneyWithdraw,
  EwalletMoneyDeposit,
  Travel,
  TravelDetail,
  VerifySwall,
  VerifyRegister1,
  VerifyRegister3,
  VerifyRegister2,
  Package,
  BuyPackage,
  Position,
  Profile,
  AddTeam
} from "../pages";
const AllRoute = () => {
  ;
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/register/otp" element={<RegisterPhonenumber />} />
        <Route path="/register/success" element={<RegisterSuccess />} />
        <Route path="/verifyswall" element={<VerifySwall />} />
        <Route path="/verifyregister1" element={<VerifyRegister1 />} />
        <Route path="/verifyregister2" element={<VerifyRegister2 />} />
        <Route path="/verifyregister3" element={<VerifyRegister3 />} />
        ------------------Private Route-----------------
        {/* <Route
          path="/login"
          element={
            <PublicRoute>
              <Login />
            </PublicRoute>
          }
        />
        <Route
          path="/register"
          element={
            <PublicRoute>
              <Register />
            </PublicRoute>
          }
        />
        <Route path="/register/otp" element={<PublicRoute>
          <RegisterPhonenumber/>
        </PublicRoute>} /> */}

        <Route
          path="/"
          element={
            <PrivateRoute>
              <Dashboard />
            </PrivateRoute>
          }
        />


        <Route
          path="*"
          element={
            <PrivateRoute>
              <Error />
            </PrivateRoute>
          }
        />
        <Route
          path="/addteam"
          element={
            <PrivateRoute>
              <AddTeam/>
            </PrivateRoute>
          }
        />
        <Route
          path="/profile"
          element={
            <PrivateRoute>
              <Profile/>
            </PrivateRoute>
          }
        />
        {/* products */}
        <Route
          path="/product"
          element={
            <PrivateRoute>
              <Product />
            </PrivateRoute>
          }
        />
        <Route
          path="/addproduct"
          element={
            <PrivateRoute>
              <AddProduct />
            </PrivateRoute>
          }
        />
        <Route
          path="/productdetail/:id"
          element={
            <PrivateRoute>
              <Productdetail />
            </PrivateRoute>
          }
        />
        <Route
          path="/productorder"
          element={
            <PrivateRoute>
              <ProductOrder />
            </PrivateRoute>
          }
        />
        <Route
          path="/productpay"
          element={
            <PrivateRoute>
              <ProductPay />
            </PrivateRoute>
          }
        />
        <Route
          path="/paychoice"
          element={
            <PrivateRoute>
              <PayChoice />
            </PrivateRoute>
          }
        />
        <Route
          path="/ewallet"
          element={
            <PrivateRoute>
              <Ewallet />
            </PrivateRoute>
          }
        />
        <Route
          path="/paysuccess/:id"
          element={
            <PrivateRoute>
              <Paysuccess />
            </PrivateRoute>
          }
        />
        {/*-------------------------------------------------------------------- Order */}
        <Route
          path="/order"
          element={
            <PrivateRoute>
              <Order />
            </PrivateRoute>
          }
        />
        <Route
          path="/buy&sale"
          element={
            <PrivateRoute>
              <BuySale />
            </PrivateRoute>
          }
        />
        <Route
          path="/salehistory"
          element={
            <PrivateRoute>
              <SaleHistory />
            </PrivateRoute>
          }
        />
        {/* ------------------------------ member ------------------------------ */}
        <Route
          path="/member"
          element={
            <PrivateRoute>
              <Member />
            </PrivateRoute>
          }
        />
        <Route
          path="/membertable"
          element={
            <PrivateRoute>
              <MemberTable />
            </PrivateRoute>
          }
        />
        {/* ========================================= bonus ======================================== */}
        <Route
          path="/bonus"
          element={
            <PrivateRoute>
              <Bonus />
            </PrivateRoute>
          }
        />

        {/* ============================================================ ewallet ======================================= */}
        <Route
          path="/ewalletwithdraw"
          element={
            <PrivateRoute>
              <EwalletWithdraw />
            </PrivateRoute>
          }
        />
        <Route
          path="/ewalletmoneywithdraw"
          element={
            <PrivateRoute>
              <EwalletMoneyWithdraw />
            </PrivateRoute>
          }
        />
        <Route
          path="/ewalletmoneydeposit"
          element={
            <PrivateRoute>
              <EwalletMoneyDeposit />
            </PrivateRoute>
          }
        />
        {/* ================================ travel ==================================== */}
        <Route
          path="/travel"
          element={
            <PrivateRoute>
              <Travel />
            </PrivateRoute>
          }
        />
        <Route
          path="/traveldetail/:id"
          element={
            <PrivateRoute>
              <TravelDetail />
            </PrivateRoute>
          }
        />
        <Route
          path="/package"
          element={
            <PrivateRoute>
              <Package />
            </PrivateRoute>
          }
        />
        <Route
          path="/buypackage/:id"
          element={
            <PrivateRoute>
              <BuyPackage />
            </PrivateRoute>
          }
        />
        {/* ================================ position ==================================== */}
        <Route
          path="/position"
          element={
            <PrivateRoute>
              <Position />
            </PrivateRoute>
          }
        />

      </Routes>
    </Router>
  );
};

export default AllRoute;
