import { PulseLoader } from "react-spinners";
import moment from "moment";
import { useState, useEffect } from "react";
import { getMyHistorySale } from "../../api/order";
import Swal from "sweetalert2";
import { ExportToXLSX } from "../../serviec/exportFile";
const AnimationLoading = () => {
  return (
    <PulseLoader
      color="#36d7b7"
      size={10}
      aria-label="Loading Spinner"
      data-testid="loader" />
  )
}
function dateFormat(dateString) {
  const dateObject = moment(dateString);
  return dateObject.format("DD/MM/YYYY");
}

function SaleHistory() {
  const currentDate = new Date();
  const today = currentDate.toISOString().split('T')[0];
  const oneYearAgo = new Date(currentDate);
  oneYearAgo.setFullYear(currentDate.getFullYear() - 1);
  const oneYearAgoString = oneYearAgo.toISOString().split('T')[0];

  const [orders, setOrders] = useState([]);
  const [orderCurrent, setOrderCurrent] = useState([]);
  const [dateEnd, setDateEnd] = useState("");
  const [dateStart, setDateStart] = useState("");
  const [loading, setLoading] = useState(false);
  const myUserCode = JSON.parse(localStorage.getItem("user")).userCode
  useEffect(() => {
    fetchData();
  }, [])

  const fetchData = async () => {
    setLoading(true);
    const data = await getMyHistorySale();
    setDateEnd(today);
    setDateStart(oneYearAgoString)
    if (data || data.length > 0) {
      setOrders(data);
      setOrderCurrent(data)
    }
    setLoading(false)
  }

  const handleDateStart = async (e) => {
    const tempOrder = [];
    for (const i of orders) {
      if ((new Date(i.createdAt) >= new Date(e.target.value)) && (new Date(i.createdAt) <= new Date(dateEnd))) {
        tempOrder.push(i);
      }
    }
    setOrderCurrent(tempOrder);
  }
  const handleDateEnd = async (e) => {
    setDateEnd(e.target.value);
    const tempOrder = [];
    for (const i of orders) {
      if ((new Date(i.createdAt) >= new Date(dateStart)) && (new Date(i.createdAt) <= new Date(e.target.value))) {
        tempOrder.push(i);
      }
    }
    setOrderCurrent(tempOrder);
  }
  const handleExport = () => {
    if (orderCurrent.length !== 0) {
      Swal.fire({
        title: "ພິມລາຍງານ ປະຫວັດການຂາຍ",
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: "ພິມລາຍງານ",
        cancelButtonText: "ຍົກເລີກ",

      }).then((result) => {
        if (result.isConfirmed) {
          const data = [];
          for (const i of orderCurrent) {
            for (const j of i.products) {
              const x = {
                "ເລກທີບິນ": i.orderCode,
                "ວັນທີ": i.createdAt,
                "ລະຫັດສິນຄ້າ": j.productCode,
                "ຊື່ສິນຄ້າ": j.name,
                "ລາຄາ": j.price,
                "ຈຳນວນ": j.qty,
                "ລວມເປັນເງິນ": j.totalPrice,
                "ລວມຄະແນນ": j.totalPoint,
                "ລວມເງິນຄືນ": j.totalCashback,
                "ຈ່າຍຜ່ານ": i.paymentType,
              }
              data.push(x);
            }
          }
          ExportToXLSX(data, `ປະຫວັດການຂາຍ${dateStart}-${dateEnd}`);

        }
      });
    } else {
      Swal.fire({
        title: 'ບໍ່ມີລາຍການສິນຄ້າ',
        icon: 'info',
      })

    }
  }
  console.log(orders);
  return (
    <div className='w-full'>
      <div className="mt-20 lg:mt-16 py-5 w-full shadow-lg border p-2 bg-white rounded-xl lg:py-10 lg:px-10">
        <div className="mb-3 lg:mb-0 lg:flex justify-between items-center">
          <h1 className="text-xl md:text-2xl lg:text-2xl font-bold ">ປະຫວັດການຂາຍ</h1>
          <div className="flex justify-end items-center gap-5 text-sm">
            <div className="flex justify-end items-center gap-3">
              <div class='w-full'>
                <input
                  type='date'
                  class='border-2 p-2 rounded-lg'
                  value={dateStart}
                  max={dateEnd}
                  onChange={handleDateStart}
                />
              </div>
              <span>ຫາ</span>
              <div className="w-full">
                <input
                  type='date'
                  class='border-2 p-2 rounded-lg'
                  min={dateStart}
                  max={today}
                  value={dateEnd}
                  onChange={handleDateEnd}
                />
              </div>
            </div>
            <button
              onClick={handleExport}
              className="lg:p-3  bg-gradient-to-t from-blue-dark to-blue-light font-bold text-white rounded-lg ">
              Export to Excel
            </button>
          </div>
        </div>
        {!loading && orderCurrent.length < 1 && <div className='text-center text-red-500'>ບໍ່ມີລາຍການຂາຍ</div>}
        <div className="w-full mt-3 h-[500px] overflow-auto rounded-lg">
          <table class="w-[800px] lg:w-full table-auto">
            <thead className="w-full rounded-t-xl bg-gradient-to-t from-blue-dark to-blue-light text-white sticky top-0">
              <tr >
                <th className="py-2">
                  ເລກທີບິນ
                </th>
                <th>
                  ວັນທີ່ສັ່ງຊື້
                </th>
                <th >
                  ຈຳນວນສີນຄ້າ
                </th>
                <th>
                  ລາຄາລວມ
                </th>
                <th >
                  ຄະແນນລວມ
                </th>
                <th >
                  ເງິນຄືນ
                </th>
                <th >
                  ສັ່ງໃຫ້
                </th>
              </tr>
            </thead>
            <tbody>
              {loading ?
                <AnimationLoading />
                :
                orderCurrent.length > 0 &&
                orderCurrent.map((order) => {
                  const Alert = () => {
                    Swal.fire({
                      html: `
                        <div class="w-full px-10 lg:px-0">
                          <table class="w-full">
                            <tr>
                              <th>ເລກທີບິນ</th>
                              <td>${order.orderCode}</td>
                            </tr>
                            <tr>
                              <th>orderType</th>
                              <td>${order.delivery.type}</td>
                            </tr>
                            <tr>
                              <th>ສະຖານທີ່ຮັບເຄື່ອງ</th>
                              <td>${order.delivery.express}<br/>${order.delivery.province ? order.delivery.province : '...'}</td>
                            </tr>
                            <tr>
                              <th>ຊື່ຜູ້ຮັບ</th>
                              <td>${order.orderFor.firstName + " "+order.orderFor.lastName}</td>
                            </tr>
                            <tr>
                              <th>ໂທ</th>
                              <td>${order.delivery.phoneNumber ? order.delivery.phoneNumber : '...'}</td>
                            </tr>
                            <tr>
                              <th>ທີ່ຢູ່</th>
                              <td>${order.delivery.address ? order.delivery.address : '...'}</td>
                            </tr>
                          </table>
                          <hr><hr/>
                            <p style="font-weight: bold;margin-top:.5rem">ລາຍລະອຽດສິນຄ້າ </p>
                            <table style="width:100%">
                            <tr>
                              <th>ລະຫັດ</th>
                              <th>ຊື່</th>
                              <th>ຈຳນວນ</th>
                              <th>ລາຄາ</th>
                            </tr>
                            ${order.products.map((product) => {
                        return (
                          `<tr>
                                  <td>${product.product_id._id.slice(-4)}</td>
                                  <td>${product.product_id.name}</td>
                                  <td>${product.qty}</td>
                                  <td>${product.product_id.price.toLocaleString()}</td>
                                </tr>`
                        )
                      })}
                            <tr>
                            <td colspan="3" style="text-align: right;">ລວມ</td>
                            <td >${order.totalPrice.toLocaleString()}</td>
                            </tr>
                            </table>
                           <hr/>
                           <p>ຈ່າຍຜ່ານ: ${order.paymentType === 'transfer' ? 'ໂອນ': 'wallet'}</p>
                            <div class=" flex justify-center items-center">
                              <img
                              src=${order.slip}
                              alt=""
                              width="150px"
                            />
                            </div>
                        </div>`
                    })
                  }
                  return (
                    <tr onClick={Alert} className="text-center border border-slate-300">
                      <td className="py-2">{order.orderCode}</td>
                      <td className="">{new Date(order.createdAt).toISOString().split('T')[0]}</td>
                      <td className="">{order.products.length}</td>
                      <td className="text-end">{order.totalPrice.toLocaleString()} ກີບ</td>
                      <td className="text-end">{order.totalPoint.toLocaleString()} PV</td>
                      <td className="text-end">{order.totalCashback.toLocaleString()} ກີບ</td>
                      <td className="text-green-500">{order.orderFor.userCode === myUserCode ? 'ຕົວເອງ' : order.orderFor.userCode}</td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
          {/* <table class="w-[800px] lg:w-full table-auto">
            <thead className="w-full rounded-t-xl bg-gradient-to-t from-blue-dark to-blue-light text-white sticky top-0">
              <tr >
                <th>
                  ເລກທີບິນ
                </th>
                <th className="p-3" >
                  ຮູບສີນຄ້າ
                </th>
                <th>
                  ວັນທີ່ສັ່ງຊື້
                </th>
                <th >
                  ຊື່ສີນຄ້າ
                </th>
                <th >
                  ຈຳນວນ
                </th>
                <th>
                  ລາຄາ
                </th>
                <th >
                  ຄະແນນ Point
                </th>
              </tr>
            </thead>
            <tbody>
              {loading ?
                <AnimationLoading />
                :
                orderCurrent.length > 0 ?
                  orderCurrent.map((order) =>
                  (
                    order.products.map((product) => {
                      const Alert = () => {
                        console.log(order)
                        Swal.fire({
                          html: `
                            <div class="w-full px-3 lg:px-0">
                              <table class="w-full">
                                <tr>
                                  <th>ເລກທີບິນ</th>
                                  <td>${order.orderCode}</td>
                                </tr>
                                <tr>
                                  <th>orderType</th>
                                  <td>${order.delivery.type}</td>
                                </tr>
                                <tr>
                                  <th>ສະຖານທີ່ຮັບເຄື່ອງ</th>
                                  <td>${order.delivery.express}<br/>${order.delivery.province ? order.delivery.province : '...'}</td>
                                </tr>
                                <tr>
                                  <th>ໂທ</th>
                                  <td>${order.delivery.phoneNumber ? order.delivery.phoneNumber : '...'}</td>
                                </tr>
                                <tr>
                                  <th>ທີ່ຢູ່</th>
                                  <td>${order.delivery.address ? order.delivery.address : '...'}</td>
                                </tr>
                              </table>
                              <hr><hr/>
                                <p style="font-weight: bold;margin-top:.5rem">ລາຍລະອຽດສິນຄ້າ </p>
                                <table style="width:100%">
                                <tr>
                                  <th>ລະຫັດ</th>
                                  <th>ຊື່</th>
                                  <th>ຈຳນວນ</th>
                                  <th>ລາຄາ</th>
                                </tr>
                                ${order.products.map((product) => {
                            return (
                              `<tr>
                                    <td>${product.product_id._id.slice(-4)}</td>
                                    <td>${product.product_id.name}</td>
                                    <td>${product.qty}</td>
                                    <td>${product.product_id.price.toLocaleString()}</td>
                                  </tr>`
                            )
                          })}
                                <tr>
                                <td colspan="3" style="text-align: right;">ລວມ</td>
                                <td >${order.totalPrice.toLocaleString()}</td>
                                </tr>
                                </table>
                               <hr/>
                                <div style="margin-top:1rem">
                                  <img
                                  src=${order.slip}
                                  alt=""
                                />
                                </div>
                            </div>`
                        })
                      }
                      return (
                        <tr onClick={Alert} className="text-center border border-slate-300">
                          <td>{order.orderCode}</td>
                          <td className="flex justify-center p-3 h-[90px] overflow-hidden rounded-lg ">
                            <img alt="" className="overflow-hidden rounded-lg" src={product.image} width={70} />
                          </td>
                          <td className="">{new Date(product.createdAt).toISOString().split('T')[0]}</td>
                          <td className="">{product.name}</td>
                          <td className="">{product.qty} {product.product_id.unit}</td>
                          <td className="">{product.price.toLocaleString() + " ກີບ"}</td>
                          <td className="">{product.point}</td>
                        </tr>
                      )
                    })))
                  : ''
              }
            </tbody>
          </table> */}
        </div>
      </div>
    </div>

  );
}

export default SaleHistory;
