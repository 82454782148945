import React from 'react'
import Swal from 'sweetalert2';
import verify from '../assets/verify.png';
import { useNavigate } from 'react-router-dom';
const AlertVerify = () => {
    const navigate = useNavigate();
    const Alert = () => {
        Swal.fire({
            html: '<div class="flex flex-col items-center">' +
                '<img src="' + verify + '" alt="verify" class="w-16 h-16 mb-4" />' +
                '<h2 class="text-lg font-semibold mb-2">ທ່ານຍັງບໍ່ທັນຢືນຢັນຕົວຕົນ</h2>' +
                '<p>ທ່ານຕ້ອງຢືນຢັນຕົວຕົນກ່ອນ ຈຶ່ງຈະສາມາດນຳໃຊ້ຟັງຊັ້ນຕ່າງໆຂອງພວກເຮົາໄດ້</p>' +
                '</div>',
            showCancelButton: true,
            confirmButtonColor: "#00AEEF",
            confirmButtonText: "ຢືນຢັນ",
            cancelButtonText: "ພາຍຫຼັງ",
            cancelButtonColor: "#ffffff",
            cancelButtonText: '<span style="color: blue;">ພາຍຫຼັງ</span>',
            reverseButtons: true,
        }).then((result) => {
            if (result.isConfirmed) {
                navigate("/verifyswall")
                // if (data.firstName !== null) {
                //     navigate("/verifyregister3");
                // }
                // else if (data.firstName === null) {
                //     navigate("/verifyswall");
                // } else {
                //     navigate("/verifyswall");
                // }
            } else {
                navigate(-1)
            }
        })
    }
    return (
        <div>
            <Alert />
        </div>
    )
}

export default AlertVerify