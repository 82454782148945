import React, { useEffect, useState } from 'react';
import { getMyOrder, getMyPackageOrder } from '../../api/order';
import Swal from 'sweetalert2';
import { PulseLoader } from 'react-spinners';
import { ExportToXLSX } from '../../serviec/exportFile';
function Orders() {
  const currentDate = new Date();
  const today = currentDate.toISOString().split('T')[0];
  const oneYearAgo = new Date(currentDate);
  oneYearAgo.setFullYear(currentDate.getFullYear() - 1);
  const oneYearAgoString = oneYearAgo.toISOString().split('T')[0];

  const [myOrders, setMyOrders] = useState([]);
  const [myOrdersCurrent, setMyOrdersCurrent] = useState([]);
  const [orderAwait, setOrderAwait] = useState(0)
  const [orderSuccess, setOrderSucess] = useState(0);
  const [orderCancel, setOrderCancel] = useState(0);
  //const [toTal, setTotal] = useState(0);
  const [dateEnd, setDateEnd] = useState("");
  const [dateStart, setDateStart] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [activebtn1, setAccbtn1] = useState(false)
  const [activebtn2, setAccbtn2] = useState(false)
  const [activebtn3, setAccbtn3] = useState(false)
  const [activebtn4, setAccbtn4] = useState(false)

  useEffect(() => {
    fetchdata();
  }, []);
  async function fetchdata() {
    setIsLoading(true);
    const data = await getMyOrder();
    if (data) {
      var countSuccess = 0;
      var countCancel = 0;
      var countAwait = 0;
      //var countTotal = 0;
      for (const i of data) {
        if (i.status != '') {
          if (i.status === 'await') {
            countAwait += 1;
          } else if (i.status === 'cancel') {
            countCancel += 1;
          } else if (i.status === 'success') {
            countSuccess += 1;
          }
        }
        //countTotal += i.totalPrice;
      }
      setMyOrders(data);
      setMyOrdersCurrent(data);
      setOrderAwait(countAwait);
      setOrderCancel(countCancel);
      setOrderSucess(countSuccess);
      //setTotal(countTotal);
    }
    setAccbtn4(true);
    setDateStart(oneYearAgoString);
    setDateEnd(today);
    setIsLoading(false);
  }
  const handleDateStart = async (e) => {
    setDateStart(e.target.value)
    const tempOrder = [];
    for (const i of myOrders) {
      if ((new Date(i.createdAt) >= new Date(e.target.value)) && (new Date(i.createdAt) <= new Date(dateEnd))) {
        tempOrder.push(i);
      }
    }
    setMyOrdersCurrent(tempOrder);
  }
  const handleDateEnd = async (e) => {
    setDateEnd(e.target.value);
    const tempOrder = [];
    for (const i of myOrders) {
      if ((new Date(i.createdAt) >= new Date(dateStart)) && (new Date(i.createdAt) <= new Date(e.target.value))) {
        tempOrder.push(i);
      }
    }
    setMyOrdersCurrent(tempOrder);
  }
  const hanhdleCheckSuccess = () => {
    const temp = [];
    console.log(myOrders)
    for (const i of myOrders) {
      if (i.status === 'success') {
        temp.push(i);
      }
    }
    setAccbtn1(true);
    setAccbtn2(false);
    setAccbtn3(false);
    setAccbtn4(false);
    setMyOrdersCurrent(temp);
  }
  const hanhdleCheckAwait = () => {
    const temp = [];
    console.log(myOrders)
    for (const i of myOrders) {
      if (i.status === 'await') {
        temp.push(i);
      }
    }
    setAccbtn1(false);
    setAccbtn2(true);
    setAccbtn3(false);
    setAccbtn4(false);
    setMyOrdersCurrent(temp);
  }
  const hanhdleCheckCancle = () => {
    const temp = [];
    for (const i of myOrders) {

      if (i.status === 'cancel') {
        console.log(i)
        temp.push(i);
      }
    }
    setAccbtn1(false);
    setAccbtn2(false);
    setAccbtn3(true);
    setAccbtn4(false);
    setMyOrdersCurrent(temp);

  }
  const handleExport = () => {
    if (myOrdersCurrent.length !== 0) {
      Swal.fire({
        title: "ພິມລາຍງານ ປະຫວັດການຂາຍ",
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: "ພິມລາຍງານ",
        cancelButtonText: "ຍົກເລີກ",

      }).then((result) => {
        if (result.isConfirmed) {
          const data = [];
          for (const i of myOrdersCurrent) {
            const x = {
              "ວັນທີ່ສັ່ງ": new Date(i.createdAt).toISOString().split('T')[0],
              "ເລກທີບິນ": i.orderCode,
              "ຈ່າຍຜ່ານ": i.paymentType,
              "ຜູ້ຮັບ": i.orderFor.firstName + " " + i.orderFor.lastName,
              "ຈຳນວນສິນຄ້າ": i.products.length,
              "ລາຄາລວມ": i.totalPrice.toLocaleString(),
              "ສະຖານະ": i.status,
            }
            data.push(x);
          }
          ExportToXLSX(data, `ອໍເດິ້ສັ່ງຊື້ ${dateStart}-${dateEnd}`);

        }
      });
    } else {
      Swal.fire({
        title: 'ບໍ່ມີລາຍການສິນຄ້າ',
        icon: 'info',
      })

    }
  }
  const AnimationLoading = () => {
    return (
      <PulseLoader
        color="#36d7b7"
        size={10}
        aria-label="Loading Spinner"
        data-testid="loader" />
    )
  }
  const ShowOrder = (props) => {
    const handleDetailOrder = () => {
      Swal.fire({
        html: `
          <div class="w-full text-[13px] lg:px-0">
            <table class="w-full">
              <tr>
                <th>ເລກທີບິນ</th>
                <td>${props._id}</td>
              </tr>
              <tr>
                <th>orderCode</th>
                <td>${props.orderCode}</td>
              </tr>
              <tr>
                <th>orderType</th>
                <td>${props.delivery.type}</td>
              </tr>
              <tr>
                <th>ສະຖານທີ່ຮັບເຄື່ອງ</th>
                <td>
                ${props.delivery.express}
                <br/>
                ${props.delivery.address},
                ${props.delivery.district},
                ${props.delivery.province}
                </td>
              </tr>
              <tr>
                <th>ຊື່ຜູ້ຮັບ</th>
                <td>${props.orderFor.firstName + " " + props.orderFor.lastName}</td>
              </tr>
              <tr>
                <th>ໂທ</th>
                <td>${props.delivery.phoneNumber ? props.delivery.phoneNumber : '...'}</td>
              </tr>
              <tr>
                <th>ທີ່ຢູ່</th>
                <td>${props.delivery.address ? props.delivery.address : '...'}</td>
              </tr>
            </table>
            <hr><hr/>
              <p style="font-weight: bold;margin-top:.5rem">ລາຍລະອຽດສິນຄ້າ </p>
              <table style="width:100%">
              <tr>
                <th>ລະຫັດ</th>
                <th>ຊື່</th>
                <th>ຈຳນວນ</th>
                <th>ລາຄາ</th>
              </tr>
              ${props.products.map((product) => {
          return (
            `<tr>
                  <td>${product.product_id._id.slice(-4)}</td>
                  <td>${product.product_id.name}</td>
                  <td>${product.qty}</td>
                  <td>${product.product_id.price.toLocaleString()}</td>
                </tr>`
          )
        })}
              <tr>
              <td colspan="3" style="text-align: right;">ລວມ</td>
              <td >${props.totalPrice.toLocaleString()}</td>
              </tr>
              </table>
             <hr/>
             <p>ຈ່າຍຜ່ານ: ${props.paymentType === 'transfer' ? 'ໂອນ' : 'wallet'}</p>
              <div class=" flex justify-center items-center">
                <img
                width="150px"
                src=${process.env.REACT_APP_IMGURL + props.slip}
                alt=""
              />
              </div>
          </div>`
        ,
        width:"340px"
      })
    }
    return (
      <>
        <tr onClick={handleDetailOrder} className="border border-slate-300">
          <td className="text-center p-2">
            {new Date(props.createdAt).toISOString().split('T')[0]}
          </td>
          <td className="text-center p-2">
            {props.orderCode}
          </td>
          <td className="">
            <p className='text-center p-2'>{props.paymentType === 'transfer' ? 'ໂອນ' : 'E-Wallet'} </p>
          </td>
          <td className="text-center">
            <p>{props.orderFor.firstName + " " + props.orderFor.lastName} </p>
          </td>
          <td className="text-center">
            {props.products.length}
          </td>
          <td className="text-right">
            {props.totalPrice.toLocaleString()} ກີບ
          </td>
          <td className=" text-center">
            {props.status === 'await' ? <span className='text-orange-500'>ລໍຖ້າກວດສອບ ແລະ ຈັດສົ່ງ</span>
              : props.status === 'success' ? <span className='text-green-500'>ຮັບແລ້ວ</span> : <span className='text-red-500'>ຍົກເລີກ</span>
            }
          </td>
        </tr>
      </>
    )
  }
  // console.log(myOrders)
  return (
    <div className='w-full'>
      <div className="w-full mt-20  py-5 shadow-lg border p-2 bg-white rounded-xl md:mt-24 lg:mt-24 md:py-8 lg:py-10 lg:px-10">
        <div className="flex justify-between items-center">
          <h1 className="text-xl md:text-2xl lg:text-2xl font-bold ">ລາຍລະອຽດໂດຍລວມ</h1>
        </div>
        <div className='mt-5 grid grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-4'>
          <div className='p-2 shadow-lg bg-white border rounded-lg '>
            <span className=''> ກຳລັງຈັດສົ່ງ </span>
            <div className='flex items-center justify-center w-full  text-xl lg:text-3xl font-bold text-blue-light '><h1>{isLoading ? <AnimationLoading /> : orderAwait + " ອໍເດີ"}</h1></div>
          </div>
          <div className='p-2 shadow-lg bg-white border rounded-lg '>
            <span className=''> ຈັດສົ່ງສຳເລັດ </span>
            <div className='flex items-center justify-center w-full text-xl lg:text-3xl font-bold text-green-500'><h1>{isLoading ? <AnimationLoading /> : orderSuccess + " ອໍເດີ"}</h1></div>
          </div>
          <div className='p-2 shadow-lg bg-white border rounded-lg '>
            <span className=''> ຍົກເລີກ</span>
            <div className='flex items-center justify-center w-full text-xl lg:text-3xl font-bold text-orange-700 '><h1>{isLoading ? <AnimationLoading /> : orderCancel + " ອໍເດີ"}</h1></div>
          </div>
          {/* <div className='p-2 shadow-lg bg-white border rounded-lg '>
            <span className=''>ມູນຄ່າທັງໝົດ </span>
            <div className='flex items-center justify-center w-full text-xl lg:text-3xl font-bold  '><h1>{isLoading ? <AnimationLoading /> : toTal.toLocaleString() + " ກີບ"}</h1></div>
          </div> */}
        </div>
      </div>
      <div className="w-full mt-5 py-5 shadow-lg border p-2 bg-white rounded-xl md:mt-5 lg:mt-5 md:py-8 lg:py-10 lg:px-10">
        <div className="mt-3 grid grid-cols-1 lg:grid-cols-2 gap-3">
          <div className='w-full flex justify-center lg:justify-start '>
            <button onClick={hanhdleCheckSuccess} className={`border-2 border-green-500 p-2 rounded-lg me-3 ${activebtn1 ? 'text-white bg-green-500' : 'text-green-500'}`}>ຮັບແລ້ວ</button>
            <button onClick={hanhdleCheckAwait} className={`border-2 border-yellow-500 p-2 rounded-lg me-3 ${activebtn2 ? 'text-white bg-yellow-500' : 'text-yellow-500'}`}>ລໍຖ້າກວດສອບ</button>
            <button onClick={hanhdleCheckCancle} className={`border-2 border-red-500 p-2 rounded-lg me-3 ${activebtn3 ? 'text-white bg-red-500' : 'text-red-500'}`}>ຍົກເລີກ</button>
            <button onClick={() => {
              setMyOrdersCurrent(myOrders);
              setAccbtn1(false);
              setAccbtn2(false);
              setAccbtn3(false);
              setAccbtn4(true);
            }
            } className={`border-2 border-slate-500 p-2 rounded-lg ${activebtn4 ? 'text-white bg-slate-500' : 'text-slate-500'}`}>ທັງໝົດ</button>

          </div>
          <div className='w-full flex justify-center md:justify-end lg:justify-end items-center gap-3'>
            <input
              type="date"
              name=""
              id=""
              className="border p-2 rounded-lg bg-white text-black"
              value={dateStart}
              max={dateEnd}
              onChange={handleDateStart}
            />
            <span>ຫາ</span>
            <input
              type="date"
              name="" id=""
              className="border p-2 rounded-lg bg-white"
              value={dateEnd}
              min={dateStart}
              max={today}
              onChange={handleDateEnd}
            />
            <button
              onClick={handleExport}
              className="lg:p-3  bg-gradient-to-t from-blue-dark to-blue-light font-bold text-white rounded-lg hidden md:block lg:block">
              Export to Excel
            </button>
          </div>
        </div>
        {isLoading ? '' : myOrders.length < 1 && <div className='text-center text-red-500'>ບໍ່ມີລາຍການສັ່ງຊື້</div>}
        <div className='w-full mt-3 h-[500px] overflow-auto rounded-lg'>
          <table className="w-[800px] lg:w-full table-auto ">
            <thead className="w-full rounded-t-xl bg-gradient-to-t from-blue-dark to-blue-light text-white sticky top-0">
              <tr className="">
                <th className="">
                  ວັນທີ່ສັ່ງຊື້
                </th>
                <th className="">
                  ເລກທີ່ການສັ່ງຊື້
                </th>
                <th className=" p-3 ">
                  ຈ່າຍຜ່ານ
                </th>
                <th className="">
                  ຜູ້ຮັບ
                </th>
                <th className="">
                  ຈຳນວນລາຍການສິນຄ້າ
                </th>
                <th className="">
                  ລາຄາລວມ
                </th>
                <th className="">
                  ສະຖານະ
                </th>
              </tr>
            </thead>
            <tbody>
              {isLoading ? <AnimationLoading /> :
                myOrdersCurrent.length > 0 ?
                  myOrdersCurrent.map((order) => {
                    return (
                      <>
                        <ShowOrder {...order} />
                      </>
                    )
                  })
                  : ''
              }
            </tbody>
          </table>
        </div>
      </div>
    </div>

  );
}

export default Orders;
