import React, { useState, useEffect } from 'react';
import ewallet from "../../assets/ewallet.png";
import bonus from "../../assets/bonus.png";
import salehistory from "../../assets/history.png";
import order from "../../assets/order.png";
import addmember from "../../assets/addmember.png";
import product from "../../assets/product.png";
import traval from "../../assets/traval.png";
import waitforclose from "../../assets/waittoclose.png";
import team from "../../assets/team.png";
import transaction from "../../assets/transaction.png";
import Swal from 'sweetalert2';
import verify from '../../assets/verify.png';
import { useNavigate } from 'react-router-dom';
import { fetchDataMyuser } from '../../api/verify';
import { token } from '../../api/apiconfig';
// ---------------
import Member_icon from '../../assets/icon/MEMBER.svg'
import users_icon from '../../assets/icon/users_icon.svg'
import linework_icon from '../../assets/icon/linwork_icon.svg'
import dollar_icon from '../../assets/icon/dollar_icon.svg'
import B_icon from '../../assets/icon/B_icon.svg'
import ewallet_icon from '../../assets/icon/wallet_icon.svg'
import card_icon from '../../assets/icon/shopping-cart.svg'
import plane_icon from '../../assets/icon/plane_icon.svg'
import product_icon from '../../assets/icon/mdi_shopping-outline.svg'
function DashboardCardcomponent() {
  const navigate = useNavigate();
  const [user, setUser] = useState([]);
  const [isVerify, setIsVerify] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    const getuser = JSON.parse(localStorage.getItem("user"));
    if (getuser) {
      setUser(getuser);
      if (getuser.isVerify === true) {
        setIsVerify(true);
      } else {
        setIsVerify(false);
      }
    }
    // fetchDataMyuser(token)
    //   .then(res => setData(res.data.data))
    //   .catch(err => console.log(err))
  }, [])

  const handleCardClick = () => {
    Swal.fire({
      html: '<div class="flex flex-col items-center">' +
        '<img src="' + verify + '" alt="verify" class="w-16 h-16 mb-4" />' +
        '<h2 class="text-lg font-semibold mb-2">ທ່ານຍັງບໍ່ທັນຢືນຢັນຕົວຕົນ</h2>' +
        '<p>ທ່ານຕ້ອງຢືນຢັນຕົວຕົນກ່ອນ ຈຶ່ງຈະສາມາດນຳໃຊ້ຟັງຊັ້ນຕ່າງໆຂອງພວກເຮົາໄດ້</p>' +
        '</div>',
      showCancelButton: true,
      confirmButtonColor: "#00AEEF",
      confirmButtonText: "ຢືນຢັນ",
      cancelButtonText: "ພາຍຫຼັງ",
      cancelButtonColor: "#ffffff",
      cancelButtonText: '<span style="color: blue;">ພາຍຫຼັງ</span>',
      reverseButtons: true,
      width:"340px"
    }).then((result) => {
      if (result.isConfirmed) {
        navigate("/verifyswall");
      } else {
        
      }
    });
  };
  // console.log(data)
  return (
    <div className="mt-20 w-full p-2 py-5">
      <div className="w-full grid grid-cols-2 gap-3 md:grid-cols-4 lg:grid-cols-4  lg:gap-5">
        <DashboardCard title="ສະມາຊິກ" image={Member_icon} onClick={() => isVerify ? navigate("/member?x=table") :handleCardClick()} />
        <DashboardCard title="ກະຕ່າສີນຄ້າ" image={card_icon} onClick={() => {  navigate("/productorder") }} />
        <DashboardCard title="Ewallet" image={ewallet_icon} onClick={() => isVerify ? navigate("/ewalletwithdraw"):handleCardClick() } />
        <DashboardCard title="ປະຫວັດໂບນັດ" image={B_icon} onClick={() => isVerify ? navigate("/bonus"):handleCardClick() } />
        <DashboardCard title="ທ່ອງທ່ຽວ" image={plane_icon} onClick={() => navigate("/travel")} />
        <DashboardCard title="ສິນຄ້າ" image={product_icon} onClick={() => navigate("/product")} />
        <DashboardCard title="ປະຫັວດທຸລະກຳ" image={dollar_icon} onClick={() => isVerify ? navigate("/order"):handleCardClick() } />
        <DashboardCard title="ແຜນພັງສາຍງງານ" image={linework_icon} onClick={() => isVerify ? navigate("/member?x=binary"):handleCardClick()} />
      </div>
    </div>
  );
}

function DashboardCard({ title, image, onClick }) {
  return (
    <div onClick={onClick} className={`w-full font-medium bg-white shadow-lg rounded-3xl`}>
      <div className='p-2'>
        <div className='h-[80px] flex justify-center items-center'>
          <img src={image} alt="" className="" width={"60px"} />
        </div>
        <div>
          <h1 className="text-center font-bold">{title}</h1>
        </div>
      </div>
    </div>
  );
}

export default DashboardCardcomponent;